import React, { useState, useEffect } from 'react'
import { Form, Col, Row, Card, Button } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next';
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'

function EditServiceForm({ service , removeService, onEdit, t }) {

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    let iconNames = [
        "plus",
        "cog",
        "cogs",
        "trash",
        "toolbox",
        "wrench",
        "weight",
        "wallet",
        "warehouse",
        "user-times",
        "concierge-bell",
        "truck-pickup",
        "truck-moving",
        "truck-monster",
        "truck-loading",
        "truck",
        "tractor",
        "trailer",
        "tools",
        "toilet-paper",
        "thumbtack",
        "tape",
        "tag",
        "tags",
        "tablet-alt",
        "sync",
        "store",
        "file",
        "file-alt",
        "book",
        "star",
        "briefcase",
        "snowplow",
        "snowflake",
        "shuttle-van",
        "shopping-cart",
        "shipping-fast",
        "screwdriver",
        "ruler",
        "shower",
        "broom",
        "soap",
        "ring",
        "road",
        "rocket",
        "recycle",
        "record-vinyl",
        "receipt",
        "random",
        "print",
        "poo",
        "plug",
        "phone",
        "pencil-alt",
        "oil-can",
        "motorcycle",
        "mouse",
        "minus",
        "map-marked",
        "magnet",
        "laptop",
        "key",
        "industry",
        "image",
        "hourglass",
        "home",
        "hockey-puck",
        "hard-hat",
        "hammer",
        "folder",
        "fill-drip",
        "faucet",
        "dumpster",
        "drum-steelpan",
        "dolly",
        "door-closed",
        "database",
        "cut",
        "comment",
        "clipboard",
        "circle",
        "circle-notch",
        "check",
        "check-double",
        "caravan",
        "car-side",
        "car",
        "calendar-alt",
        "bullseye",
        "box",
        "box-open",
        "archive",
        "ambulance",
    ]

    iconNames = iconNames.sort()

    function distilIcons(nameArray) {
        return nameArray.map(name => {
            let upperCaseName = ''
            if (name.split('-').length > 0) {
                name.split('-').forEach(segment => {
                    upperCaseName += capitalizeFirstLetter(segment)
                })
            } else {
                upperCaseName = capitalizeFirstLetter(name)
            }
            return { value: `fa${upperCaseName}`, label: <><FontAwesomeIcon icon={fa[`fa${upperCaseName}`]}/> {upperCaseName}</> }
        })
    }

    const icons = [
        { value: undefined, label : t('None') },
        ...distilIcons(iconNames)
    ]

    const [serviceData,setServiceData] = useState(service)
    const handleChange = (value,fieldName) => {

        if (value && typeof(value) === "string") {
            if (value === "true") { value = true }
            if (value === "false") { value = false }
        }
        setServiceData({...serviceData, [fieldName] : value })
    }

    const [collapse,setCollapse] = useState(serviceData.newlyAdded ? false : true)

    useEffect(() => {
        onEdit(serviceData)
    }, [serviceData])

    async function handleRemove() {
        if (!serviceData.newlyAdded) {
            const confirmation = await window.confirmation(t('Remove service?'))
            if (confirmation) {
                removeService()
            }
        } else {
            removeService()
        }

    }


    let iconColor = undefined 
    let defaultIcon = undefined

    if (service.operation_type === 'to_customer') {
        iconColor = "text-success"
        defaultIcon = fa.faUser
    } else if (service.operation_type === 'to_stock') {
        iconColor = "text-warning"
        defaultIcon = fa.faWarehouse
    } else if (service.operation_type === 'to_waste') {
        iconColor = "text-danger"
        defaultIcon = fa.faTrash
    }

    const icon = serviceData.icon ? <FontAwesomeIcon className={iconColor} icon={fa[serviceData.icon]}/> : <FontAwesomeIcon className={iconColor} icon={defaultIcon}/>

    return (
        <Card className="mt-2" >
            <Card.Header className="bg-light pointer" onClick={() => setCollapse(!collapse)}>
                <b className="d-inline-block mt-1">{icon} {serviceData.name}</b> {service.operation_type ? `(${service.operation_type})` : ''}
                <span className="mt-1 float-end " >
                    <Button size="sm" variant="outline-primary">
                        {collapse ? t('Expand') : t('Collapse')} <FontAwesomeIcon style={{cursor : "pointer"}} icon={collapse ? fa.faChevronCircleDown : fa.faChevronCircleUp}/>
                    </Button>
                    &nbsp;
                    <Button size="sm" variant="outline-danger" onClick={() => handleRemove()}>
                        <FontAwesomeIcon icon={fa.faTrash}/>
                    </Button>
                </span>

            </Card.Header>
            <Card.Body className={collapse ? 'collapse' : ''}>
                <Row>
                <Form.Group as={Col} className="mt-2">
                        <Form.Label>{t('Icon')}</Form.Label>
                        <Select value={icons.filter(icon => icon.value == serviceData.icon)[0]} onChange={(e) => handleChange(e.value,'icon')}  options={icons}/>
                    </Form.Group>
                    <Form.Group as={Col} className="mt-2">
                        <Form.Label>{t('Service name')}</Form.Label>
                        <Form.Control autoComplete="off" type="text" name="name" value={serviceData.name} onChange={e => handleChange(e.target.value, e.target.name)} required />
                    </Form.Group>  
                    <Form.Group as={Col} className="mt-2">
                        <Form.Label>{t('Operation type')}</Form.Label>
                        <Form.Select value={serviceData.operation_type} required={true} onChange={e => handleChange(e.target.value, e.target.name)} name="operation_type" className="form-control">
                            <option></option>
                            <option value='to_stock'>{t('To stock')}</option>
                            <option value='to_customer'>{t('To customer')}</option>
                            <option value='to_waste'>{t('To waste')}</option>
                        </Form.Select>
                    </Form.Group> 
                    <Form.Group as={Col} className="mt-2">
                        <Form.Label>{t('Article number')}</Form.Label>
                        <Form.Control autoComplete="off" required={serviceData.article_insertion_method !== 'text_line'} type="number" name="article_number" value={serviceData.article_number} onChange={e => handleChange(e.target.value, e.target.name)} />
                    </Form.Group>   
                    <Form.Group as={Col} className="mt-2">
                        <Form.Label>{t('Insertion method')}</Form.Label>
                        <Form.Select value={serviceData.article_insertion_method} defaultValue='per_tyre' onChange={e => handleChange(e.target.value, e.target.name)} name="article_insertion_method" className="form-control">
                            <option value='per_tyre'>{t('Per tyre')}</option>
                            <option value='global'>{t('Global')}</option>
                            <option value='text_line'>{t('Text line')}</option>
                        </Form.Select>
                    </Form.Group>   
                    <Form.Group as={Col} className="mt-2">
                        <Form.Label>{t('Preselected')}</Form.Label>
                        <Form.Select value={serviceData.preselected} defaultValue={false} onChange={e => handleChange(e.target.value, e.target.name)} name="preselected" className="form-control">
                            <option value={true}>{t('Yes')}</option>
                            <option value={false}>{t('No')}</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} className="mt-2">
                        <Form.Label>{t('Blocked')}</Form.Label>
                        <Form.Select value={serviceData.blocked} defaultValue={false} onChange={e => handleChange(e.target.value, e.target.name)} name="blocked" className="form-control">
                            <option value={true}>{t('Yes')}</option>
                            <option value={false}>{t('No')}</option>
                        </Form.Select>
                    </Form.Group>    
                </Row>                            
                <Row>

                    <Form.Group as={Col} className="mt-2">
                        <Form.Label>{t('Description Dutch')}</Form.Label>
                        <Form.Control autoComplete="off" type="text" name="description_nl" value={serviceData.description_nl} onChange={e => handleChange(e.target.value, e.target.name)} required />
                    </Form.Group>
                    <Form.Group as={Col} className="mt-2">
                        <Form.Label>{t('Description French')}</Form.Label>
                        <Form.Control autoComplete="off" type="text" name="description_fr" value={serviceData.description_fr} onChange={e => handleChange(e.target.value, e.target.name)} required />
                    </Form.Group>
                    <Form.Group as={Col} className="mt-2">
                        <Form.Label>{t('Description English')}</Form.Label>
                        <Form.Control autoComplete="off" type="text" name="description_en" value={serviceData.description_en} onChange={e => handleChange(e.target.value, e.target.name)} required />
                    </Form.Group>
                    <Form.Group as={Col} className="mt-2">
                        <Form.Label>{t('Description German')}</Form.Label>
                        <Form.Control autoComplete="off" type="text" name="description_de" value={serviceData.description_de} onChange={e => handleChange(e.target.value, e.target.name)} required />
                    </Form.Group>
                </Row>
                <Row className="mt-2">

                </Row>

            </Card.Body>
        </Card>
    )
}

export default withNamespaces()(EditServiceForm)