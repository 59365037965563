import * as admin_api from '../api/hoteladmin'
import * as fleet_api from '../api/fleet'
import i18next from 'i18next';

export const setCustomer = (customer) => async(dispatch) => {
    try {
        await dispatch({ type: 'ADMIN_CUSTOMER_SET', payload : {
            name : customer.name[0], 
            seq_id: customer.seq_id[0]
        }})
    } catch (e) {
        console.log(e)
    }

}

export const loadFleet = (seq_id) => async(dispatch) => {
    try {
        console.log('loading fleet:' + seq_id)
        const fleetData = await admin_api.getCustomerFleet(seq_id)
        console.log(fleetData)
        const actionFleet = { type: 'ADMIN_FLEET_LOAD', payload : fleetData.data }
        dispatch(actionFleet)
    } catch(error) {
        console.log(error)
        window.handleError(error)
    }
}

export const addVehicle = (vehicle,seq_id) => async(dispatch) => {
    const toast = window.loadingShow(i18next.t('Creating vehicle'))
    try {       
        const newVehicle = await fleet_api.newVehicle(vehicle,seq_id)
        dispatch({ type: 'ADMIN_FLEET_ADD', payload : newVehicle.data})
        window.loadingResolve(toast,'success',i18next.t('Vehicle created'))
        return newVehicle.data
    } catch (error) {
        console.log(error)
        window.loadingResolve(toast,'error',error)
        return false
    }
}

export const updateVehicle = (vehicleObject,hideMessage) => async(dispatch) => {
    let toast
    if (!hideMessage) {
        toast =  window.loadingShow(i18next.t('Updating vehicle...'))
    }
    
    try {
        const response = await fleet_api.updateVehicle(vehicleObject)
        const updatedVehicle = response?.data?.updatedVehicle
        if (updatedVehicle) {
            dispatch({type: 'ADMIN_FLEET_UPDATE', payload : updatedVehicle})
            //if vehicle has tyres dispatch tyre update
            if (toast) { window.loadingResolve(toast,'success',i18next.t('Vehicle updated')) }
        }
        return updatedVehicle
    } catch (e) {
        if (toast) { window.loadingResolve(toast,'error',e) }
        return false
    }
}

export const removeVehicle = (flt_id) => async (dispatch) => {
    const confirmation = await window.confirmation(i18next.t('Remove vehicle?'))
    if (confirmation) {
        const toast = window.loadingShow(i18next.t('Removing vehicle'))
        try {
            await fleet_api.inactivateVehicle(flt_id);
            dispatch({ type: 'ADMIN_FLEET_REMOVE', payload: {flt_id : flt_id}})
            window.loadingResolve(toast,'success',i18next.t('Vehicle removed'))
            return true;
        } catch(e) {
            window.loadingResolve(toast,'error',e)
            return false;
        }
    } else {
        return false
    }
}