import axios from 'axios';

axios.defaults.withCredentials = true;

export const getConfigs = () => axios.post( '/hotelconfig/getall')

export const newConfig = (config) => {

    //for multipart form transform to formdata object 
    const formData = new FormData()
    Object.keys(config).forEach(key => {
        const value = config[key]
        if (value.name && value.size) {
            //file
            formData.append(key, value, value.name)
        } else {
            formData.append(key,value)
        }
    })

    return axios.post( '/hotelconfig/new', formData)

}

export const deleteConfig = (configId) => axios.post( '/hotelconfig/delete', { _id : configId})

export const getTranslations = () => axios.get('/locale/all')
export const updateTranslation = (id,value) => axios.post('/locale/update', { id : id, value : value})
export const deleteTranslation = (id) => axios.post('/locale/delete', { id : id})
