import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux';
import { Button, Form, Card, Row, Col } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next';
import { updateOperation, deleteOperation } from '../../actions/operations';
import VehicleIcon from '../common/vehicleIcon'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import TyreTable from '../tyres/tyreTable';

function NewDisposal({operation,operationSubmitted, operationDeleted, tyresExpanded, hideDeleteAlert, t}) {
    const fleetState = useSelector((store) => store.Fleet)
    const vehicles = fleetState.vehicles
    const dispatch = useDispatch()
    const vehicle = vehicles.filter(v => v.flt_id[0] == operation.flt_id)[0]
    const userState = useSelector((store) => store.User)   
    const hotelConfig = useSelector((store) => store.Siteconfig) 
    const [toggleDetails, setToggleDetails] = useState(tyresExpanded)
    const [remarks,setRemarks] = useState(operation.remarks)
    async function changeOperationValue(fieldName, value) {
        const copiedOperation = {...operation}
        copiedOperation[fieldName] = value
        dispatch(updateOperation(copiedOperation,true))
    }
    
    const [loading, setLoading] = useState(false)

    async function submitOperation(e) {
        e.preventDefault()
        setLoading(true)
        const copiedOperation = {...operation}
        copiedOperation["status"] = "submitted"
        await dispatch(updateOperation(copiedOperation))
        if (operationSubmitted) { operationSubmitted() }
        setLoading(false)
    }

    /*async function undoSubmit() {
        setLoading(true)
        const copiedOperation = {...operation}
        copiedOperation["status"] = "pending"
        await dispatch(updateOperation(copiedOperation))
        if (operationSubmitted) { operationSubmitted() }
        setLoading(false)
    }*/

    async function handleDelete() {
        setLoading(true)
        await dispatch(deleteOperation(operation, hideDeleteAlert))
        if (operationDeleted) { operationDeleted() }
        setLoading(false)
    } 

    const navigate = useNavigate()
    function goToVehicle(id) {
        navigate('/fleet/vehicle', {state: { id: id}})
    }

        //SERVICE MANAGEMENT SEGMENT

        const [operationServices,setOperationServices] = useState(
            operation.services.map(serviceId => hotelConfig.services.filter(service => service._id === serviceId)[0])
        )
    
        const [availableServices, setAvailableServices] = useState(
            hotelConfig.services.filter(service => service.operation_type === operation.type && !operation.services.includes(service._id))
        )
        
        useEffect(() => {
            //on operation change --> reset operation linked services
            setOperationServices(operation.services.map(serviceId => hotelConfig.services.filter(service => service._id === serviceId)[0]))
            //on operation change --< reset operation available services
            setAvailableServices(hotelConfig.services.filter(service => service.operation_type === operation.type && !operation.services.includes(service._id)))
        },[operation])
    
        async function removeService(service) {
            const copiedOperation = {...operation}
            const indexToRemove = copiedOperation.services.indexOf(service._id)
            if (indexToRemove > -1) {
                copiedOperation.services.splice(indexToRemove,1)
            }
            setLoading(true)
            await dispatch(updateOperation(copiedOperation,true))
            setLoading(false)
        }
    
        async function addService(serviceToAdd) {
            if (serviceToAdd) {
                const copiedOperation = {...operation}
                copiedOperation.services?.push(serviceToAdd)
                setLoading(true)
                await dispatch(updateOperation(copiedOperation,true))
                setLoading(false)
                setShowAddServiceSelect(false)
            } else {
                setShowAddServiceSelect(false)
            }
        }
    
        function addServiceForm() {

            const options = availableServices.map(service => { return { value : service._id, label : <><FontAwesomeIcon icon={fa[service.icon]}></FontAwesomeIcon> {service[`description_${userState.lang_iso}`]}</>} })

            return  <Row className="gx-2">
            <Col xs={8}>
                <Select onChange={e => addService(e.value)} options={options}/>    
            </Col>
            <Col>
                <Button size="sm" disabled={loading} variant="primary" onClick={() => addService()} className="mt-1"><FontAwesomeIcon icon={fa.faCheck}/></Button>&nbsp;
                <Button size="sm" disabled={loading} variant="outline-danger" onClick={() => setShowAddServiceSelect(false)} className="mt-1"><FontAwesomeIcon icon={fa.faTimes}/></Button>
            </Col>
        </Row>
        }
    
        const [showAddServiceSelect,setShowAddServiceSelect] = useState(false)

    return (<>
            <Form onSubmit={(e) => submitOperation(e)}>
                <Card className="mt-1 pt-2">
                    <Card.Body as={Row} className="pt-2 pb-2">
                        <Col lg={1}>
                            <small className="opacity-50">{t('License plate')}</small>
                            { vehicle ? 
                                <p className="mt-1 text-primary pointer" onClick={() => goToVehicle(vehicle.flt_id[0])}>
                                    <VehicleIcon type={vehicle.type[0]}/> {vehicle?.license_plate[0]}
                                </p>
                                : <p className="mt-1 opacity-50">
                                    <VehicleIcon type={operation.vehicle?.type[0]}/>  {operation.vehicle?.license_plate}
                                </p>
                            }
                        </Col>
                        <Col lg={1}>
                            <small className="opacity-50">{t('Tyres')}</small>
                            <p className="mt-1 pointer text-primary" onClick={() => setToggleDetails(toggleDetails ? false : true)}>{operation.tyres.length} {t('tyres')}</p>
                        </Col>
                        <Col lg={5}>
                            <small><span className="opacity-50">{t('Services')}</span>
                            {operation.status === 'pending' && availableServices.length > 0 ? <span className="text-primary pointer opacity-100" onClick={() => setShowAddServiceSelect(true)}> <FontAwesomeIcon icon={fa.faPlus}/> {t('Add service')}...</span> : '' }</small><br></br>
                            
                            <ul className="mt-1">
                            {operation.status === 'pending' ?
                                <>{operationServices.map((service,i) => service ? <li key={i}>
                                        <span>{service?.icon ? <><FontAwesomeIcon size="sm" className="opacity-50" icon={fa[service.icon]}/> </> : ''}{service[`description_${userState.lang_iso}`]} </span>
                                        {!service.blocked ?  
                                                    <span><FontAwesomeIcon size="xs" onClick={() => removeService(service)} icon={fa.faTimes} className="text-danger pointer"/></span> : 
                                                    <span><FontAwesomeIcon size="xs" icon={fa.faLock} className="opacity-30"/></span>
                                                }
                                        
                                    </li> : <li key={i} className="opacity-50">{t('Unknown')}</li>)}
                                    {showAddServiceSelect ? <li>
                                        {addServiceForm()}
                                    </li> : ''}
                                    </>
                            : <>{operationServices.map((service,i) => <li key={i}>
                                {service ? 
                                <span>{service.icon ? <FontAwesomeIcon icon={fa[service.icon]} className="opacity-50" size="sm"/> : ''} {service[`description_${userState.lang_iso}`]}</span> : 
                                <span className="opacity-50">{t('Unknown')}</span>}
                            </li>)}</>}
                            </ul>
                        </Col>
                        <Col lg={3}>
                            <small className="opacity-50">{t('Remarks')}</small>
                            {operation.status === 'pending' ? <input className="form-control" value={remarks} onBlur={() => {changeOperationValue("remarks", remarks )}} onChange={(e) => {setRemarks(e.target.value)}}></input>
                            : <p className="mt-1">{operation.remarks}</p> }
                        </Col>
                        <Col lg={2}>
                            <small className="opacity-50">&nbsp;</small>
                            <div className="text-right">
                                {operation.status === 'pending' ? 
                                <>
                                    <Button type="submit" variant="success" size="sm" disabled={loading} className="mt-1"><FontAwesomeIcon icon={fa.faCheck}/> {t('Process')}</Button> &nbsp;
                                    <Button onClick={() => handleDelete()} disabled={loading} variant="outline-danger" className="mt-1" size="sm"><FontAwesomeIcon icon={fa.faTrash}/></Button>
                                </> : '' }
                            </div>
                        </Col>
                        <Col md={12} className={toggleDetails ? '' : 'collapse'}>
                            <TyreTable showDepth={hotelConfig.allowProfileDepth} tyres={operation.tyres}/>
                        </Col>
                    </Card.Body>
                </Card>
            </Form>
        </>
    )
}

export default withNamespaces()(NewDisposal)