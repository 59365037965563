import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BoltIcon, HubcapIcon, RimAluminum, RimSteel } from '../../resources/images/customIcons'
import { withNamespaces } from 'react-i18next'

function TyreDetailIcons({tyre, t}) {

  const drawBolts = tyre?.stock_bolts ? tyre.stock_bolts[0] === 'Y' : false
  const drawHubcaps = tyre?.stock_hubcaps ? tyre.stock_hubcaps[0] === 'Y' : false
  const drawAluRims = tyre?.stock_rims ? tyre.stock_rims[0] === 'aluminum' : false
  const drawSteelRims = tyre?.stock_rims ? tyre.stock_rims[0] === 'steel' : false
    
  return (
    <span className="opacity-50">
      {drawAluRims ? <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="button-tooltip-2">{t('Aluminum rim present')}</Tooltip>}>
          {({ ref, ...triggerHandler }) => (
          <span ref={ref}><FontAwesomeIcon {...triggerHandler} size="sm" icon={RimAluminum}/>&nbsp;</span>
          )}
        </OverlayTrigger>
      : ''}
      {drawSteelRims ? <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="button-tooltip-2">{t('Steel rim present')}</Tooltip>}>
          {({ ref, ...triggerHandler }) => (
          <span ref={ref}><FontAwesomeIcon {...triggerHandler} size="sm" icon={RimSteel}/>&nbsp;</span>
          )}
        </OverlayTrigger> : ''}
      {drawHubcaps ? <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="button-tooltip-2">{t('Hubcaps present')}</Tooltip>}>
          {({ ref, ...triggerHandler }) => (
          <span ref={ref}><FontAwesomeIcon {...triggerHandler} size="sm" icon={HubcapIcon}/>&nbsp;</span>
          )}
        </OverlayTrigger> : ''}
      {drawBolts ? <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="button-tooltip-2">{t('Bolts present')}</Tooltip>}>
          {({ ref, ...triggerHandler }) => (
          <span className="mr-1" ref={ref}><FontAwesomeIcon {...triggerHandler} size="sm" icon={BoltIcon}/>&nbsp;</span>
          )}
        </OverlayTrigger> : ''}
    </span>
  )
}

export default withNamespaces()(TyreDetailIcons)