import React from 'react'
import { withNamespaces } from 'react-i18next'
import {Row, Col, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'

function fetchingPlaceholder({t}) {
  return (
    <Row className="mt-3">
        <Col>
            <Card>
                <Card.Body>
                    <p className="text-muted text-center mb-0"><FontAwesomeIcon icon={fa.faSpinner} className="spinner"/> {t('Fetching')}... </p>
                </Card.Body>
            </Card>
        </Col>
    </Row>
  )
}

export default withNamespaces()(fetchingPlaceholder)