import React, {useState, useEffect} from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next';
import { updateVehicle } from '../../actions/vehicles';
import { useDispatch } from 'react-redux'

function SetNameModal({show, vehicle, closeHook , tyres, t}) {

    const setNames = vehicle.tyres ? vehicle.tyres[0].tyre?.map(tyre => tyre.tyre_set ? tyre.tyre_set[0] : undefined).filter(setName => setName !== undefined) : []
    const uniqueSetNames = [...new Set(setNames)]

    const handleClose = (keepSelected) => closeHook(keepSelected);
    const [tyresetName, setTyresetName] = useState()
    const dispatch = useDispatch()
    async function submitChanges(e) {
        e.preventDefault()
        setButtonDisabled(true)
        let copiedVehicle = {...vehicle}

        let copiedTyres = JSON.parse(JSON.stringify(tyres))
        copiedTyres.forEach(copiedTyre => {
            copiedTyre["tyre_set"] = [tyresetName]
        })

        //check for duplicate set+position combinations
        if (tyresAlreadyInSet.length > 0) {
            let duplicatePositions = []
            const takenTyrePositions = tyresAlreadyInSet.map(tyre => tyre.position[0])
            tyres.forEach(tyre => { 
                if (!tyre.tyre_set) {
                    tyre.tyre_set = []
                    tyre.tyre_set[0] = 'setless'
                }
                if (tyre.tyre_set[0] !== tyresetName && takenTyrePositions.includes(tyre.position[0])) {
                    duplicatePositions.push(tyre.position[0])
                }
            })   
            
            if (duplicatePositions.length > 0) {
                window.notify('warning',t('The positions {{positions}} are already taken in this tyre set.', {positions : duplicatePositions}))
                setButtonDisabled(false)
                return 
            }
        }

        copiedVehicle["tyres"] = [{ tyre : copiedTyres }]

        await dispatch(updateVehicle(copiedVehicle))
        handleClose(false)
        setButtonDisabled(false)
    }

    const [buttonDisabled, setButtonDisabled] = useState(false)

    const vehicleTyres = vehicle?.tyres ? vehicle.tyres[0]?.tyre : []

    const tyresAlreadyInSet = vehicleTyres?.filter(tyre => { if (tyre.tyre_set) { return tyre.tyre_set[0] === tyresetName } })

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                document.getElementById('set-focus').focus()
            }, 100)
        }
    },[show])

    return (
        <>
            <Modal show={show} onHide={() => handleClose(true)}>
                <Modal.Header closeButton>
                <Modal.Title>{t('Set name change')}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={(e) => submitChanges(e)}>
                    <Modal.Body>
                        <label>{t('New set name')}</label>
                        <Form.Control placeholder={t('Set name')} value={tyresetName} required id="set-focus" onChange={(e) => setTyresetName(e.target.value)}></Form.Control>
                        {uniqueSetNames.length > 0 ? <small>{t('Suggestions: ')}
                            {uniqueSetNames.map((setName,i) => <>
                                    <span key={setName} onClick={() => setTyresetName(setName)} className="pointer text-primary">{setName}</span>
                                    {i !== (uniqueSetNames.length - 1) ? <span className="opacity-50"> | </span> : ''}
                                </>)}
                            </small> : '' }
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('Close')}
                    </Button>
                    <Button disabled={buttonDisabled} type="submit" variant="primary">
                        {t('Apply set name')}
                    </Button>
                    </Modal.Footer>
                </Form>

            </Modal>
        </>
    )
}

export default withNamespaces()(SetNameModal)
