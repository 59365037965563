import React from 'react'
import { Doughnut } from 'react-chartjs-2';
import { withNamespaces } from 'react-i18next'
import 'chart.js/auto';
import 'chartjs-adapter-moment';

function HomepagePieChart({t, tyres}) {
    
    const stockTyres = tyres.filter(tyre => tyre.tyre_status[0] == 'in_stock')
    const customerTyres = tyres.filter(tyre => tyre.tyre_status[0] == 'at_customer')
    const vehicleTyres = tyres.filter(tyre => tyre.tyre_status[0] == 'on_vehicle')

    const labels = [
        ' ' + t('In stock'),
        ' ' + t('At customer'),
        ' ' + t('On vehicle')
    ]

    const colors =  ['#ffc107','#198754','#17a2b8']

    const chartOptions = {
        layout: {
            padding: 25
        },
        type: "doughnut",
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            }
        }
    };

    const chartData = {
        labels: labels,
        datasets: [{
            label: 'My First Dataset',
            data: [stockTyres.length, customerTyres.length, vehicleTyres.length],
            backgroundColor: colors,
            hoverOffset: 4
        }]
    };

  return (
    <>
        <h5>{t('Tyre counts')}</h5>
        <Doughnut options={chartOptions} data={chartData} />
    </>
  )
}

export default withNamespaces()(HomepagePieChart)