import React, {useState} from 'react'
import VehicleIcon from '../common/vehicleIcon'
import { useSelector, useDispatch } from 'react-redux';
import { updateVehicle } from '../../actions/vehicles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons'
import { Card, Button, Form, Row, Col } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next';

function VehicleEditForm({t,vehicleData,loading,setLoading,col}) {

    const dispatch = useDispatch();

    const [vehicle,setVehicle] = useState(vehicleData)

    function changeValue(e) {

        const copiedVehicle = {...vehicle}
        const propertyName = e.target.name
        
        //dig down into properties to update value
        if (propertyName.includes('.')) {
            const propertyTree = propertyName.split('.')
            let destinationNode = copiedVehicle
            propertyTree.forEach((property,i) => {
                if (i === propertyTree.length - 1) {
                    destinationNode[property][0] = e.target.value
                } else {
                    destinationNode = destinationNode[property][0]
                }
            })
        } else {
            copiedVehicle[e.target.name][0] = e.target.value
        }
        setVehicle(copiedVehicle)
    }

    async function handleVehicleEdit(e) {
        e.preventDefault()
        const vehicleObject = {...vehicle}

        //if vehicle config is empty remove it from object to update
        if (vehicleObject.configuration && vehicleObject.configuration[0] === '') {
            delete vehicleObject.configuration
        }

        setLoading(true)
        await dispatch(updateVehicle(vehicleObject))
        setLoading(false)
    }

    const vehicleTypes = useSelector((store) => store.Siteconfig).vehicleTypes
    

  return (
    <Card as={Form} onSubmit={(e) => {handleVehicleEdit(e)}}>
        <Card.Body as={Row} >
            <Col md={col || 6} className="mt-2">
            <h5><VehicleIcon type={vehicle.type[0]}/> {t('Vehicle data')}</h5>
                <Form.Group as={Row}>
                    <Form.Label column sm="6">
                        <b>{t('License plate')}</b>
                    </Form.Label>
                    <Col sm="6">
                        <Form.Control plaintext="true" readOnly value={vehicle.license_plate[0]} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="6">
                        <b>{t('Number')}</b>
                    </Form.Label>
                    <Col sm="6">
                        <Form.Control plaintext="true" onChange={(e) => changeValue(e)} id="number" name="number"  value={vehicle.number[0]} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label htmlFor="chassis_number" column sm="6">
                        <b>{t('Chassis number')}</b>
                    </Form.Label>
                    <Col sm="6">
                        <Form.Control plaintext="true" maxLength="17" onChange={(e) => changeValue(e)} id="chassis_number" name="chassis_number" value={vehicle.chassis_number[0]} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label htmlFor="brand" column sm="6">
                        <b>{t('Vehicle brand')}</b>
                    </Form.Label>
                    <Col sm="6">
                        <Form.Control maxLength="30" className="pointer" onChange={(e) => changeValue(e)} id="brand" name="brand" plaintext="true" value={vehicle.brand[0]} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label htmlFor="description" column sm="6">
                        <b>{t('Vehicle description')}</b>
                    </Form.Label>
                    <Col sm="6">
                        <Form.Control maxLength="50" className="pointer" onChange={(e) => changeValue(e)} id="description" name="description" plaintext="true" value={vehicle.description[0]} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label htmlFor="type" column sm="6">
                        <b>{t('Vehicle type')}</b>
                    </Form.Label>
                    <Col sm="6">
                        <Form.Select className="pointer borderlessSelect" onChange={(e) => changeValue(e)} id="type" name="type" plaintext="true" value={vehicle.type[0]}>
                            { vehicleTypes?.map(type => <option key={type.vet_description} value={type.vet_description}>{type.vet_description}</option>)}
                        </Form.Select>
                    </Col>
                </Form.Group>
            </Col>
            <Col md={col || 6} className="mt-2">
                <h5><FontAwesomeIcon icon={fa.faUser} /> {t('Driver data')}</h5>
                <Form.Group as={Row}>
                    <Form.Label htmlFor="driver_name" column sm="6">
                        <b>{t('Driver name')}</b>
                    </Form.Label>
                    <Col sm="6">
                        <Form.Control maxLength="42" className="pointer" onChange={(e) => changeValue(e)} id="driver_name" name="driver.name" plaintext="true" value={vehicle.driver_name || vehicle.driver[0].name[0]} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label htmlFor="driver_mobile_no" column sm="6">
                        <b>{t('Mobile number')}</b>
                    </Form.Label>
                    <Col sm="6">
                        <Form.Control maxLength="25" className="pointer" onChange={(e) => changeValue(e)} id="driver_mobile_no" name="driver.mobile_no" plaintext="true" value={vehicle.driver_mob_no || vehicle.driver[0].mobile_no[0]} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label htmlFor="driver_telephone_no" column sm="6">
                        <b>{t('Telephone number')}</b>
                    </Form.Label>
                    <Col sm="6">
                        <Form.Control maxLength="25" className="pointer" onChange={(e) => changeValue(e)} id="driver_telephone_no" name="driver.telephone_no" plaintext="true" value={vehicle.driver_tel_no || vehicle.driver[0].telephone_no[0]} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label htmlFor="driver_email" column sm="6">
                        <b>{t('E-mail')}</b>
                    </Form.Label>
                    <Col sm="6">
                        <Form.Control maxLength="100" className="pointer" onChange={(e) => changeValue(e)} id="driver_email" name="driver.email" type="email" plaintext="true" value={vehicle.driver_email || vehicle.driver[0].email[0]} />
                    </Col>
                </Form.Group>
                <br/>
                <Button type="submit" disabled={loading} className="float-md-end mt-1"><FontAwesomeIcon icon={fa.faCheck} /> {t('Submit changes')}</Button>
            </Col>
            </Card.Body>
        </Card>
  )
}

export default withNamespaces()(VehicleEditForm)