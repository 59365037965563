import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Row, Col, Button } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons'

function FinalStep({t}) {
    const navigate = useNavigate()
    function goToHome() {
        navigate('/')
    }

    const location = useLocation();
    const status = location?.state?.status

    function goToStep1() {
        navigate('/wizard/step1')
    }

  return (<>
        <Row className="justify-content-center">
            <h5 className="text-center">{t('Step 4')}</h5>
            <Col className="text-center" md={6}>
                <small className="mb-1 d-block">{t(`Operation ${status}, what would you like to do next?`)}</small>
                <Row className="g-2 justify-content-center">
                    <Col md="auto">
                        <Button onClick={() => goToHome()} size="lg"><FontAwesomeIcon icon={fa.faHome}/> {t('Home')}</Button>
                    </Col>
                    <Col md="auto">
                        <Button onClick={() => goToStep1()} variant="success" size="lg"><FontAwesomeIcon icon={fa.faPlus}/> {t('New operation')}</Button>
                    </Col>
                   
                   
                </Row>
            </Col> 
        </Row>
    </>
  )
}

export default withNamespaces()(FinalStep)