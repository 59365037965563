import React from 'react'
import { withNamespaces } from 'react-i18next';
import { Doughnut } from 'react-chartjs-2';

function OperationCountPieChart({colors, operationCountsPerCustomer}) {

    const labels = operationCountsPerCustomer.map(operationCountObject => ' ' + operationCountObject.customer)
    const data = operationCountsPerCustomer.map(operationCountObject => operationCountObject.count)
    const chartOptions = {
        layout: {
            padding: 25
        },
        type: "doughnut",
        responsive: true,
        plugins: {
            legend: {
                display: false,
            }
        },
    };

    const chartData = {
        labels: labels,
        datasets: [{
            label: '',
            data: data,
            backgroundColor: colors,
            hoverOffset: 4
        }]
    };

   
  return (
    <>
        <Doughnut options={chartOptions} data={chartData} />
    </>
  )
}

export default withNamespaces()(OperationCountPieChart)