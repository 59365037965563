import React, { useState } from 'react'
import { OverlayTrigger, Tooltip, Button, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import TyreStatusLabel from '../common/tyreStatusLabel'
import { withNamespaces } from 'react-i18next'
import DataTable from 'react-data-table-component';
import TyreStockDetailButton from '../common/TyreStockDetailButton'
import { useSelector } from 'react-redux'
import TyreDetailIcons from '../common/tyreDetailIcons';

function TyreSet({vehicle, selectedSet, selectedTyres, clearRows, tyreSet, stockDetailTyre, t}) {

    const hotelConfig = useSelector((store) => store.Siteconfig)

    function highlightSquare(event) {
        const selectedPosition = event.target.dataset.position
        const selectedStatus = event.target.dataset.status
        const tyreSquare = document.getElementById('tyre-' + selectedPosition)  
        const row = event.target.parentElement.parentElement.parentElement
        row.classList.add("bg-" + window.tyreLabelGenerator(selectedStatus).color);
        row.classList.add("text-white");
        if (tyreSquare) {
            tyreSquare.classList.add("bg-" + window.tyreLabelGenerator(selectedStatus).color);
            tyreSquare.nextSibling.classList.add("text-white");
        }
    }
    const selectableRowsDisabled = (row) => {
        let pendingCheck = pendingTyres.map(pt => pt.db_id[0]).includes(row.id)
        let setCheck = false
        if (selectedSet) {
            const rowSet = row.tyre_set ? row.tyre_set : 'setless'; 
            if (selectedSet === rowSet) {
                setCheck = false
            } else {
                setCheck = true
            }
        }
        let wasteCheck = row.tyre_status === 'waste'
        // CHECK IF OTHER SET NAMES ARE ALREADY SELECTED
        return pendingCheck || setCheck || wasteCheck
    }

    function unHighlightSquare(event) {
        if (event.target.tagName === 'SPAN') {
            event.target.classList.remove('bg-secondary')
            event.target.classList.remove('text-white')
            const selectedPosition = event.target.dataset.position
            const selectedStatus = event.target.dataset.status
            const tyreSquare = document.getElementById('tyre-' + selectedPosition)
            const row = event.target.parentElement.parentElement.parentElement
            row.classList.remove("bg-" + window.tyreLabelGenerator(selectedStatus).color);
            row.classList.remove("text-white");
            if (tyreSquare) {
                tyreSquare.classList.remove("bg-" + window.tyreLabelGenerator(selectedStatus).color);
                tyreSquare.nextSibling.classList.remove("text-white");
            }
        }
    }

   
    const [filteredTyres] = useState(tyreSet.tyres ? tyreSet.tyres.filter(tyre => tyre.tyre_status[0] != 'waste') : [])

    async function showStockModal(tyre) {
        stockDetailTyre(undefined)
        stockDetailTyre(tyre)
    }

    const conditionalRowStyles = [
        {
            when: () => true,
            style: {cursor : 'pointer'}
        }
    ]

    function onRowClicked(row,e) {
        const checkbox = e.currentTarget?.children[0]?.firstChild
        checkbox.click()
    }

    const operationStore = useSelector((store) => store.Operations)
    const pendingOperations = operationStore?.operations?.filter(operation => operation.flt_id == vehicle.flt_id[0] && (operation.status === 'submitted' || operation.status === 'pending'))
    const pendingTyres = [].concat.apply([],pendingOperations.map(po => po.tyres))

    const generateSetRows = (tyres) => {
        if (tyres) {
            return tyres.map(tyre => {
                const pendingLabel = pendingTyres.map(pt => pt.db_id[0]).includes(tyre.db_id[0]) ? 
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">{t('This tyre is in a pending operation')}</Tooltip>}>
                        {({ ref, ...triggerHandler }) => (
                            <span ref={ref} className="text-warning">
                        <FontAwesomeIcon icon={fa.faExclamationCircle} {...triggerHandler} className="d-inline-flex align-items-center"
                        />
                        </span>
                        )}
                    </OverlayTrigger>: ''
                return  { 
                    tyre_set : tyre.tyre_set ? tyre.tyre_set[0] : undefined,
                    className: `tyre-row-${tyre.position[0]}`,
                    id : tyre.db_id[0],
                    art_id : tyre.art_id[0],
                    flt_id : vehicle.flt_id[0],
                    description: tyre.tyre_memo ? <><TyreDetailIcons tyre={tyre}/> {tyre.tyre_memo[0]}</> : <><TyreDetailIcons tyre={tyre}/> {tyre.description[0]}</>,
                    profile_depth : tyre.profile_depth[0],
                    profile_date : tyre.profile_date[0],
                    status_date : tyre.status_date[0],
                    position: tyre.position[0],
                    position_label: <><span data-tag="allowRowEvents" style={{cursor : "pointer"}} className={`tyre-row-${tyre.position[0]}`} 
                                            data-position={tyre.position[0]} data-status={tyre.tyre_status[0]} 
                                            onMouseEnter ={(e) => {highlightSquare(e)}} 
                                            onMouseOut={(e) => {unHighlightSquare(e)}}>{tyre.position[0]}</span> {pendingLabel}</>,
                    tyre_status : tyre.tyre_status[0],
                    tyre_status_label : (tyre.tyre_status[0] === 'in_stock' && hotelConfig.allowDetailedStockReport) ? 
                                    <><TyreStatusLabel data-tag="allowRowEvents" status={tyre.tyre_status[0]}/> <TyreStockDetailButton onClick={() => showStockModal(tyre)}/></> 
                                    : <TyreStatusLabel data-tag="allowRowEvents" status={tyre.tyre_status[0]}/>
                }
            })
        } else {
            return null
        }
    }

    const handleRowSelected = () => {
        //due to lack of "unselected" tyre from datatable API --> rely on query selection of checkbox inputs
        //form a list of all tyre rows
        const rows = document.getElementsByClassName('rdt_TableRow')
        let selectedTyreIds = []

        //populate array with selected tyre IDs
        for (let i = 0; i < rows.length ; i++) {
            const row = rows[i]
            const rowTyreId = row.id.split('-')[1]
            //select checkbox
            const checkbox = row.children[0].children[0]
            if (checkbox.checked) {
                selectedTyreIds.push(rowTyreId)
            }
        }

        //filter out selected tyre IDs from tyre list to get the objects
        const selectedTyreObjects = filteredTyres.filter(tyre => selectedTyreIds.includes(tyre.db_id[0]))
        if (selectedTyres) {selectedTyres(selectedTyreObjects)}
      };

      const tableStyles = {
        head : {
            style: {
                fontSize: '16px', // override the row height
            }
        },
        rows : {
            style: {
                fontSize: '16px', // override the row height
            }
        }
    }

    const tableColumns = [
        { name: t('Pos.'), selector: row => row.position_label, maxWidth: '40px' },
        { name: t('Description'), selector: row => row.description },
        { name: t('Status'), selector: row => row.tyre_status_label, maxWidth: '135px' },
        { name: t('Status date'), sortable: true, maxWidth: '150px', selector: row => row.profile_date, format: row => moment(row.status_date,'YYYYMMDD').format('DD/MM/YYYY'), hide: 'md' },
        { name: t('Depth'), selector: row => <>{row.profile_depth}mm {row.profile_date ? <small>({moment(row.profile_date,'YYYYMMDD').format('DD/MM/YYYY')})</small>: ''}</>, maxWidth: '175px;', omit: !hotelConfig.allowProfileDepth, hide: 'md' },
    ];

    const [collapse,setCollapse] = useState(false)

  return (
    <Card key={tyreSet.name} className="mb-2">
                <Card.Header>
                    <h5 className="mt-1 pointer" onClick={() => setCollapse(!collapse)} >
                        {tyreSet.name === 'setless' ? t('Setless') : tyreSet.name} 
                        <Button 
                            className="float-end btn-sm" 
                            onClick={() => setCollapse(!collapse)} 
                            variant="outline-primary">
                            {collapse ? <><FontAwesomeIcon icon={fa.faChevronDown} /> {t('Expand')}</> : <><FontAwesomeIcon icon={fa.faChevronUp} /> {t('Collapse')}</> }
                        </Button>
                    </h5>
                </Card.Header>
                <Card.Body className={collapse ? 'collapse' : ''}>
                    <DataTable
                        conditionalRowStyles={conditionalRowStyles}
                        //only allow selectable rows in hotel mode
                        selectableRows={['hotelonly','both'].includes(hotelConfig.mode)}
                        onRowClicked={onRowClicked}
                        onSelectedRowsChange={handleRowSelected}
                        selectableRowDisabled={selectableRowsDisabled}
                        clearSelectedRows={clearRows}
                        responsive={true}
                        customStyles={tableStyles}
                        columns={tableColumns}
                        data={generateSetRows(tyreSet.tyres)}
                        highlightOnHover={true}
                    />
            </Card.Body>
            </Card>
  )
}

export default withNamespaces()(TyreSet)