import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import { Badge } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import VehicleIcon from '../common/vehicleIcon'
import moment from 'moment'

import "react-datepicker/dist/react-datepicker.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import TyreTable from '../tyres/tyreTable';
import OperationTypeLabel from '../common/operationTypeLabel';

function CompletedOperationRow({operation,t}) {
    const fleetState = useSelector((store) => store.Fleet)
    const vehicles = fleetState.vehicles
    const vehicle = vehicles.filter(v => v.flt_id[0] == operation.flt_id)[0]
    const hotelConfig = useSelector((store) => store.Siteconfig)

    const [toggleDetails, setToggleDetails] = useState(false)

    function toggleTyres() {
        toggleDetails ? setToggleDetails(false) : setToggleDetails(true)
    }

    const navigate = useNavigate()
    function goToVehicle(id) {
        navigate('/fleet/vehicle', {state: { id: id}})
    }

    return (
        <>
            <tr className="single-line" style={{borderBottom: "1px solid rgb(222, 226, 230)", paddingBottom: '1rem'}}>
                <td>{ vehicle ?
                    <span className="d-inline-block mt-1 text-primary pointer" onClick={() => goToVehicle(vehicle.flt_id[0])}>
                        <VehicleIcon type={vehicle.type[0]}/> {vehicle?.license_plate[0]}
                    </span> : 
                    <span className="opacity-50 mt-1 d-inline-block">
                        <VehicleIcon type={operation.vehicle?.type[0]}/>  {operation.vehicle?.license_plate}
                    </span>}
                </td>
                <td><span className="d-block mt-1"><OperationTypeLabel type={operation.type}/></span></td>
                <td><span className="d-block mt-1 text-primary pointer" onClick={() => toggleTyres()}>{operation.tyres.length} {t('tyres')}</span></td>
                <td><span className="d-block mt-1">{operation.status === 'confirmed' ? <Badge bg="success"><FontAwesomeIcon icon={fa.faCheck}/> {t('Confirmed')}</Badge> : <Badge bg="danger"><FontAwesomeIcon icon={fa.faTimes}/> {t('Cancelled')}</Badge>}</span></td>
                <td><small className="opacity-50 mt-1 d-block">{moment(operation.handledAt).format('DD/MM/YYYY HH:mm:ss')}</small></td>
            </tr>
            <tr className={toggleDetails ? '' : 'collapse'}>
                <td colSpan="100"><TyreTable showDepth={hotelConfig.allowProfileDepth} tyres={operation.tyres}/></td>
            </tr>
        </>
    )
}

export default withNamespaces()(CompletedOperationRow)