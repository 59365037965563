export const TyreIcon = {
    prefix: "cus",
    iconName: "tsTyre",
    icon: [
      24,
      24,
      [],
      "e001",
      `M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12c6.6,0,12-5.4,12-12C24,5.4,18.6,0,12,0z M12,20.6c-4.7,0-8.6-3.9-8.6-8.6
      c0-4.7,3.9-8.5,8.6-8.5c4.7,0,8.6,3.8,8.6,8.6C20.6,16.7,16.7,20.6,12,20.6z M13.7,15c0.7,0.9,1.4,1.8,2.1,2.7
      c-1.4,1.5-5.8,1.5-7.4,0.1c0.1-0.2,0.3-0.4,0.4-0.5c0.5-0.7,1.1-1.3,1.6-2c0.1-0.1,0.1-0.2,0.3-0.1c1,0.4,1.9,0.4,2.8-0.1
      C13.6,15,13.6,15,13.7,15z M9.2,14.1C8.5,15,7.8,15.8,7,16.7c-1.6-1.6-2.3-4.3-1.6-6.5c0.9,0.4,1.7,0.7,2.5,1.1
      c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0,0.1,0.1,0.1,0.2c-0.1,0.8,0.1,1.6,0.6,2.3C9.2,14,9.2,14,9.2,14.1z M18.8,12.8
      c-0.2,1.4-0.8,2.7-1.7,3.8c-0.4-0.5-0.8-1-1.2-1.5c-0.3-0.4-0.6-0.8-0.9-1.1c-0.1-0.1-0.1-0.2,0-0.3c0.4-0.6,0.5-1.3,0.4-2
      c0-0.1,0-0.2,0.1-0.3c1-0.4,2-0.9,3.1-1.3C18.8,11,18.9,11.9,18.8,12.8z M12.9,8.6c0-1.1,0-2.2,0-3.3c0,0,0,0,0,0
      c1.5-0.1,4.4,1.8,5,3.4c-1,0.4-2.1,0.9-3.1,1.3c-0.1,0-0.2,0-0.2-0.1c-0.4-0.5-1-0.9-1.6-1.1C12.9,8.7,12.9,8.6,12.9,8.6z M9.2,9.9
      C8.2,9.5,7.1,9,6.1,8.6C6.7,7,9.7,5.1,11.1,5.2c0,0.1,0,0.2,0,0.3c0,1,0,2,0,2.9c0,0.2-0.1,0.3-0.2,0.3c-0.6,0.2-1.1,0.5-1.5,1
      C9.4,9.8,9.2,9.9,9.2,9.9z M12,10.3c1,0,1.7,0.8,1.7,1.7c0,1-0.8,1.7-1.7,1.7c-1,0-1.7-0.8-1.7-1.7C10.3,11,11.1,10.3,12,10.3z`
    ]
  };

  export const HubcapIcon = {
    prefix: "cus",
    iconName: "Hubcap",
    icon: [
      24,
      24,
      [],
      "e001",
      `M14.1,0c3.98,.83,6.85,3,8.78,6.61-.92,.21-1.77,.42-2.62,.59-1.35,.27-2.72,.33-4.1,.27-.35-.02-.56-.13-.71-.48-.92-2.21-1.3-4.53-1.35-6.99Zm1.23,2.01c.06,.39,.08,.73,.16,1.06,.03,.14,.15,.31,.27,.37,1.33,.61,2.46,1.47,3.4,2.59,.08,.09,.24,.18,.35,.17,.35-.03,.69-.11,1.1-.19-1.35-1.92-3.08-3.22-5.29-4ZM10.14,.03c-.12,1.11-.16,2.19-.38,3.24-.25,1.21-.65,2.38-.98,3.57-.07,.24-.21,.31-.45,.34-2.39,.24-4.7-.12-6.99-.78C1.76,3.96,7.01,.12,10.14,.03ZM3.52,5.81c.42,.07,.76,.14,1.1,.17,.12,0,.29-.05,.37-.14,.97-1.09,2.13-1.93,3.48-2.5,.08-.04,.19-.12,.2-.2,.07-.37,.12-.75,.19-1.19-2.21,.73-3.94,1.99-5.34,3.86Zm20.5,4.67c.38,3.92-.81,7.25-3.59,10.12-.49-.85-1.03-1.62-1.41-2.46-.52-1.18-.93-2.4-1.38-3.61-.1-.27-.02-.45,.2-.64,1.59-1.39,3.41-2.39,5.36-3.16,.21-.08,.42-.16,.63-.23,.04-.01,.08,0,.19-.02Zm-1.56,1.77c-.39,.2-.72,.35-1.03,.54-.1,.06-.2,.21-.21,.34-.18,1.33-.62,2.56-1.32,3.69-.06,.1-.14,.24-.11,.32,.15,.37,.34,.72,.55,1.12,1.34-1.81,2.03-3.76,2.13-6.01ZM3.35,20.31C.62,17.69-.53,13.48,.08,10.18c.88,.39,1.75,.74,2.6,1.16,1.2,.58,2.32,1.31,3.33,2.2,.1,.09,.2,.27,.18,.38-.48,2.34-1.51,4.43-2.83,6.4ZM1.54,12.01c.06,2.22,.68,4.17,1.94,5.96,.19-.38,.36-.69,.5-1.01,.05-.11,.04-.29-.02-.39-.64-1.14-1.05-2.35-1.19-3.65-.01-.12-.06-.29-.15-.34-.33-.2-.69-.36-1.09-.57Zm5,10.65c.87-.9,1.67-1.83,2.57-2.65,.73-.66,1.56-1.21,2.36-1.78,.11-.08,.34-.11,.45-.05,2.07,1.16,3.72,2.79,5.18,4.64-2.74,1.54-7.34,1.66-10.57-.16Zm8.38-.7c-.29-.28-.52-.54-.78-.75-.13-.1-.33-.18-.48-.15-1.23,.21-2.44,.18-3.66-.08-.12-.02-.28-.02-.36,.05-.29,.24-.55,.52-.87,.83,2.08,.66,4.08,.69,6.16,.1Zm-2.91-13.66c-1.98,0-3.62,1.63-3.62,3.62,0,1.98,1.63,3.62,3.61,3.62,1.97,0,3.62-1.65,3.62-3.62,0-1.98-1.64-3.62-3.62-3.62Z`
    ]
  };

  export const BoltIcon = {
    prefix: "cus",
    iconName: "Bolts",
    icon: [
      24,
      24,
      [],
      "e001",
      `M20.43,3.49C18.28,1.34,15.29,0,12,0,8.71,0,5.72,1.34,3.57,3.49,1.41,5.65,.07,8.63,.07,11.93c0,3.29,1.34,6.28,3.49,8.43,2.16,2.16,5.14,3.49,8.43,3.49,3.29,0,6.28-1.34,8.43-3.49,2.16-2.16,3.49-5.14,3.49-8.43,0-3.29-1.34-6.28-3.49-8.43Zm-1.3,15.56c-1.83,1.83-4.34,2.95-7.13,2.95-2.79,0-5.3-1.13-7.13-2.95-1.83-1.83-2.95-4.34-2.95-7.13,0-2.79,1.13-5.3,2.95-7.13,1.83-1.83,4.34-2.95,7.13-2.95,2.79,0,5.3,1.13,7.13,2.95,1.83,1.83,2.95,4.34,2.95,7.13,0,2.79-1.13,5.3-2.95,7.13ZM12,5.59l5.49,3.17v6.34l-5.49,3.17-5.49-3.17v-6.34l5.49-3.17Z`
    ]
  };

  export const RimAluminum = {
    prefix: "cus",
    iconName: "RimAluminum",
    icon: [
      24,
      24,
      [],
      "e001",
      `M12.08,2.04c5.5,0,9.97,4.47,9.97,9.97s-4.47,9.97-9.97,9.97S2.11,17.52,2.11,12.02,6.58,2.04,12.08,2.04M12.08,.04C5.47,.04,.11,5.4,.11,12.02s5.36,11.97,11.97,11.97,11.97-5.36,11.97-11.97S18.69,.04,12.08,.04Zm2.13,4.09h-4.31l-4.44,14.51h3.4l1.04-3.72h4.15l1.12,3.72h3.54L14.21,4.14Zm-3.85,8.32l.88-3.07c.22-.83,.45-1.93,.67-2.81h.04c.2,.88,.47,1.95,.73,2.81l.9,3.07h-3.22Z`
    ]
  };

  export const RimSteel = {
    prefix: "cus",
    iconName: "RimSteel",
    icon: [
      24,
      24,
      [],
      "e001",
      `M12,2.04c5.5,0,9.97,4.47,9.97,9.97s-4.47,9.97-9.97,9.97S2.03,17.52,2.03,12.02,6.5,2.04,12,2.04M12,.04C5.39,.04,.03,5.4,.03,12.02s5.36,11.97,11.97,11.97,11.97-5.36,11.97-11.97S18.61,.04,12,.04ZM7.97,4.69h8.93v2.71h-5.62v3.34h5.25v2.69h-5.25v5.88h-3.32V4.69Z`
    ]
  };