import React, { useEffect, useState } from 'react'
import moment from 'moment';
import TyreStatusLabel from '../common/tyreStatusLabel'
import { withNamespaces } from 'react-i18next'
import DataTable from 'react-data-table-component';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import TyreStockDetailButton from '../common/TyreStockDetailButton'
import TyreStockDetailsModal from '../common/TyreStockDetailsModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import VehicleIcon from '../common/vehicleIcon';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import TyreDetailIcons from '../common/tyreDetailIcons';

function TyreTable({tyres,t, showScans, customStyle, hideBorder,hideStatus, hideStatusDate, showStockRack, showVehicle, allowDepthInput, onDepthChange, showDepth }) {
    const [stockDetailTyre, setStockDetailTyre] = useState(undefined)

    async function showStockModal(tyre) {
        await setStockDetailTyre(undefined)
        setStockDetailTyre(tyre)
    } 

    const navigate = useNavigate()

    function goToVehicle(id) {
        navigate('/fleet/vehicle', {state: { id: id}})
    }

    const fleetState = useSelector((store) => store.Fleet)

    const siteConfigState = useSelector((store) => store.Siteconfig)
    
    const tableStyles = customStyle ? customStyle : {
        head : {
            style: {
                fontSize: '16px', // override the row height
            }
        },
        rows : {
            style: {
                margin: 0,
                fontSize: '16px', // override the row height
            }
        },
        cells: {
            style: {
                paddingLeft: '16px',
                paddingRight: '16px',
            }
        },
        headCells: {
            style: {
                width: '20px'
            }
        },
    }

    function vehicleData(flt_id) {
        const vehicleObj = fleetState?.vehicles.filter(vehicle => vehicle.flt_id[0] === flt_id)[0]
        return <span className="text-primary pointer" onClick={() => goToVehicle(vehicleObj?.flt_id[0])}><VehicleIcon type={vehicleObj?.type[0]}/> {vehicleObj?.license_plate[0]}</span>
    }

    const [depths,setDepths] = useState({})

    const minDepth = 0
    const maxDepth = 10

    function setDepthObj(position,depth) {
        const copiedDepths = {...depths}
        
        if (depth < minDepth) { depth = minDepth }
        if (depth > maxDepth) { depth = maxDepth }

        copiedDepths[position] = depth
        
        setDepths(copiedDepths)
    }

    

    //report changed depths to hook
    useEffect(() => {
        if (onDepthChange) {
            onDepthChange(depths)
        }
    },[depths])

    const tableColumns = [
        {
            name: t('Vehicle'),
            selector: row => row.vehicle,
            maxWidth: '125px;',
            omit: showVehicle ? false : true
        },
        {
            name: t('Depth'),
            selector: row => <Form.Control 
                                autoComplete={false}
                                min={0} step=".01" max={10}
                                type="number" 
                                onChange={e => setDepthObj(row.id,e.target.value)}/>,
            maxWidth:'150px;',
            omit : allowDepthInput ? false : true
        },
        {
            name: t('Rack'),
            selector: row => row.stock_rack,
            maxWidth: '100px;',
            omit: showStockRack ? false : true
        },
        {   
            name: t('Pos.'), 
            selector: row => row.position_label, 
            maxWidth: '40px' },
        {   
            name: t('Description'), 
            selector: row => row.description},
        {   
            name: t('Status'), 
            selector: row => row.tyre_status_label, 
            maxWidth: '135px', 
            omit: hideStatus ? true : false},
        {   
            name: t('Status date'), 
            sortable: true, 
            maxWidth: '150px', 
            selector: row => row.status_date, 
            format: row => moment(row.status_date,'YYYYMMDD').format('DD/MM/YYYY'), 
            hide: 'md', 
            omit: hideStatusDate ? true : false
        },
        { 
            name: t('Depth'), 
            selector: row => <>{row.profile_depth}mm {row.profile_date ? <small>({moment(row.profile_date,'YYYYMMDD').format('DD/MM/YYYY')})</small>: ''}</>, 
            maxWidth: '200px;', 
            hide: 'md', 
            omit: showDepth ? false : true
        },
    ];

    if (showScans) {
        tableColumns.push({ name: t('Scanned'), maxWidth: '150px;', selector: row => row.scanned})
    }
    function generateTablerows(tyres) {
        if (tyres) {
            return tyres.map(tyre => {
                const scanTooltip = (props) => (
                    <Tooltip {...props}>
                      {moment(tyre.scanRegisteredAt).format('DD/MM/YY HH:mm:ss')}
                    </Tooltip>
                  );
                return  { 
                    className: `tyre-row-${tyre.position[0]}`,
                    id : tyre.db_id[0],
                    vehicle : tyre.flt_id ? vehicleData(tyre.flt_id[0]) : undefined,
                    art_id : tyre.art_id[0],
                    description: tyre.tyre_memo ? <><TyreDetailIcons tyre={tyre}/> {tyre.tyre_memo[0]}</> : <><TyreDetailIcons tyre={tyre}/> {tyre.description[0]}</>,
                    profile_depth : tyre.profile_depth[0],
                    profile_date : tyre.profile_date[0],
                    stock_rack : tyre.stock_rack ? tyre.stock_rack[0] : '',
                    status_date : tyre.status_date[0],
                    position: tyre.position[0],
                    scanned : tyre.scanned ? <OverlayTrigger placement="bottom" overlay={scanTooltip}>
                       <span><FontAwesomeIcon icon={fa.faCheck} className="text-success"/></span>
                    </OverlayTrigger> : 
                    <FontAwesomeIcon icon={fa.faTimes} className="text-danger"/>,
                    position_label: <span 
                        data-tag="allowRowEvents" 
                        style={{cursor : "pointer"}} className={`tyre-row-${tyre.position[0]}`} 
                        data-position={tyre.position[0]} data-status={tyre.tyre_status[0]}>{tyre.position[0]}</span>,
                    tyre_status : tyre.tyre_status[0],
                    tyre_status_label : (tyre.tyre_status[0] === 'in_stock' && siteConfigState.allowDetailedStockReport) ? 
                                    <><TyreStatusLabel data-tag="allowRowEvents" status={tyre.tyre_status[0]}/> <TyreStockDetailButton onClick={() => showStockModal(tyre)}/></> 
                                    : <TyreStatusLabel data-tag="allowRowEvents" status={tyre.tyre_status[0]}/>
                }
            })
        } else {
            return null
        }

    }

    const containerStyle = hideBorder ? undefined : {border: '1px solid rgba(0,0,0,.125)', borderRadius: '0.25rem'}
    return (
        <>
        { tyres ? 
        <div style={containerStyle}>  
            <TyreStockDetailsModal tyre={stockDetailTyre}/>
            <DataTable
                responsive={true}
                customStyles={tableStyles}
                columns={tableColumns}
                data={generateTablerows(tyres)}
                highlightOnHover={true}
            />
        </div> : ''}
        </>
    )
}

export default withNamespaces()(TyreTable)
