import React from 'react'
import { Badge } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next'
import * as fa from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const tyreLabelGenerator = function(status) {
    if (status === 'on_vehicle') {
        return { color : 'info', label :'On vehicle' }
    } else if (status === 'in_stock') {
        return { color : 'warning', label : 'In stock' }
    } else if (status === 'on_spare') {
        return { color : 'info', label : 'Spare' }
    } else if (status === 'waste') {
        return { color : 'danger', label : 'Waste' }
    } else if (status === 'at_customer') {
        return { color : 'success', label : 'At customer' }
    } else {
        return { color : 'secondary', label : 'Unknown' }
    }
}

const iconPicker = function(status) {
    if (status === 'on_vehicle') {
        return <FontAwesomeIcon icon={fa.faCar}/>
    } else if (status === 'in_stock') {
        return <FontAwesomeIcon icon={fa.faWarehouse}/>
    } else if (status === 'on_spare') {
        return <FontAwesomeIcon icon={fa.faWrench}/>
    } else if (status === 'waste') {
        return <FontAwesomeIcon icon={fa.faTrash}/>
    } else if (status === 'at_customer') {
        return <FontAwesomeIcon icon={fa.faUser}/>
    } else {
        return <FontAwesomeIcon icon={fa.faQuestionCircle}/>
    }
}

window.tyreLabelGenerator = tyreLabelGenerator

function tyreStatusLabel({t,status}) {
    return (
        <Badge data-tag="allowRowEvents" bg={tyreLabelGenerator(status).color}>{iconPicker(status)} {t(tyreLabelGenerator(status).label)}</Badge>
    )
}

export default withNamespaces()(tyreStatusLabel)
