import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { bookedTransportDays } from '../../api/operations'
import Recall from '../operations/recall'
import Storage from '../operations/storage'
import Disposal from '../operations/disposal';
function SubmitOperation({t}) {
    const location = useLocation();
    const navigate = useNavigate()
    const operationId = location?.state?.operationId

    const [bookedDays,setBookedDays] = useState([])

    useEffect(async () => {
        try {
            //fetch booked days on initial load
            setBookedDays((await bookedTransportDays()).data)
        } catch (e) {
            window.handleError(e)
        }
        
    },[])

    const operationStore = useSelector((store) => store.Operations)
    const operation = operationStore.operations.filter(operation => operation._id == operationId)[0]

    function finishWizard(status) {
        navigate('/wizard/step4', {state : {status : status}})
    }

  return (<>
        <Row className="justify-content-center">
            <h5 className="text-center">{t('Step 3')}</h5>
            <Col className="text-center" md={6}>
                <small className="mb-2 d-block">{t('Add final information and confirm')}</small>
                <Row className="g-2">
                   
                </Row>
            </Col> 
        </Row>
        {operation?.type == 'to_stock' ? <Storage tyresExpanded printLabels operation={operation} operationDeleted={() => finishWizard('cancelled')} hideDeleteAlert={true} operationSubmitted={() => finishWizard('submitted')}/> : ''}
        {operation?.type == 'to_customer' ? <Recall tyresExpanded bookedDays={bookedDays} operation={operation} operationDeleted={() => finishWizard('cancelled')} hideDeleteAlert={true} operationSubmitted={() => finishWizard('submitted')}/> : ''}
        {operation?.type == 'to_waste' ? <Disposal tyresExpanded operation={operation} operationDeleted={() => finishWizard('cancelled')} hideDeleteAlert={true} operationSubmitted={() => finishWizard('submitted')}/> : ''}

    </>
  )
}

export default withNamespaces()(SubmitOperation)