import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { withNamespaces } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import { addVehicle } from '../../actions/vehicles'
import * as fleetAdmin from '../../actions/fleetadmin'
import VehicleDrawing from '../common/vehicleDrawing';
import { useNavigate } from 'react-router'

function NewVehicleForm({t, modalMode, closeModal, insertedVehicle, lpPrefill, fleetAdminMode}) {

    const dispatch = useDispatch()

    const navigate = useNavigate()
    function goToVehicle(id) {
        navigate('/fleet/vehicle', {state: { id: id}})
    }

    const vehicleTypes = useSelector((store) => store.Siteconfig).vehicleTypes
    const vehicleConfigs = useSelector((store) => store.Siteconfig).vehicleConfigs
    const adminFleetStore = useSelector(store => store.AdminFleet)
    const hotelConfig = useSelector(store => store.Siteconfig)

    const [vehicle,setVehicle] = useState({})

    function setPath(obj, path, value) {

        var pList = path.split('.');
        var len = pList.length;
        for(var i = 0; i < len-1; i++) {
            var elem = pList[i];
            if( !obj[elem] ) obj[elem] = {}
            obj = obj[elem];
        }
    
        obj[pList[len-1]] = value;
    }

    function changeValue(e) {
        const copiedVehicle = {...vehicle}

        if (e.target.name == 'license_plate') {
            //filter out all non alphanumeric characters
            e.target.value = e.target.value.replace(/[^0-9a-z]/gi, '') 
        }

        if (e.target.name === 'configName') {
            //extract config 
            if (e.target.value) {
                copiedVehicle['configuration'] = vehicleConfigs.filter(config => config.description[0] === e.target.value)[0].configuration[0]
            } else {
                copiedVehicle['configuration'] = undefined
            }
            
        } else {
            
            const propertyName = e.target.name
            //dig down into properties to update value
            if (propertyName.includes('.')) {
                setPath(copiedVehicle,propertyName, e.target.value)
            } else {
                copiedVehicle[e.target.name] = e.target.value
            }
        }
        setVehicle(copiedVehicle)
    }

    const [loading,setLoading] = useState(false)

    async function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)
        
        //hotel customer mode
        if (!fleetAdminMode) {
            const insertedData = await dispatch(addVehicle(vehicle))
            if (insertedData) {
    
                //if insertedVehicle hook --> return inserted vehicle
                if (insertedVehicle) { 
                    insertedVehicle(insertedData) 
                }
                //if closeModal hook --> close modal & go to vehicle detail page
                if (closeModal) { 
                    closeModal() 
                    goToVehicle(insertedData.flt_id[0])
                }
                setVehicle({})
            }
        //fleet admin mode
        } else {
            console.log('inserting as admin')
            const insertedData = await dispatch(fleetAdmin.addVehicle(vehicle, adminFleetStore.seq_id))
            if (insertedData) {
                //if insertedVehicle hook --> return inserted vehicle
                if (insertedVehicle) { 
                    insertedVehicle(insertedData) 
                }
                //if closeModal hook --> close modal & go to vehicle detail page
                if (closeModal) { 
                    closeModal() 
                }
                setVehicle({})
            }
        }


        setLoading(false)
    }

    if (lpPrefill) {
        vehicle.license_plate = lpPrefill
    }

  return (  <Form onSubmit={(e) => handleSubmit(e)} >
    <Modal.Header className={modalMode ? '' : 'd-none'} closeButton>
        <Modal.Title className={modalMode ? '' : 'd-none'}>{t('Create new vehicle')}</Modal.Title></Modal.Header>
        <Modal.Body>
            <Form.Group as={Row} className="mt-2">
                <Form.Label htmlFor="license_plate" column sm="4">
                        {t('License plate')}
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control disabled={lpPrefill} id="license_plate" required onChange={(e) => changeValue(e)} name="license_plate" value={vehicle.license_plate} />
                    </Col>
                </Form.Group>
                {hotelConfig.mode !== 'hotelonly' ? <Form.Group as={Row} className="mt-2">
                    <Form.Label htmlFor="number" column sm="4">
                        {t('Vehicle number')}
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control id="number" onChange={(e) => changeValue(e)} name="number" value={vehicle.number} />
                    </Col>
                </Form.Group> : ''}
                <Form.Group as={Row} className="mt-2">
                    <Form.Label htmlFor="chassis_number" column sm="4">
                        {t('Chassis number')}
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control maxLength="17" id="chassis_number" onChange={(e) => changeValue(e)} name="chassis_number" value={vehicle.chassis_number} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mt-2">
                    <Form.Label htmlFor="brand" column sm="4">
                        {t('Vehicle brand')}
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control maxLength="30" required onChange={(e) => changeValue(e)} id="brand" name="brand" value={vehicle.brand} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mt-2">
                    <Form.Label htmlFor="description" column sm="4">
                        {t('Vehicle description')}
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control maxLength="50" required onChange={(e) => changeValue(e)} id="description" name="description" value={vehicle.description} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mt-2">
                    <Form.Label htmlFor="type" column sm="4">
                        {t('Vehicle type')}
                    </Form.Label>
                    <Col sm="8">
                        <Form.Select required onChange={(e) => changeValue(e)} id="type" name="type" value={vehicle.type}>
                            <option></option>
                            { vehicleTypes?.map(type => <option key={type.vet_description} value={type.vet_description}>{type.vet_description}</option>)}
                        </Form.Select>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mt-2">
                    <Form.Label htmlFor="configName" column sm="4">
                        {t('Configuration')}
                    </Form.Label>
                    <Col sm="8">
                        <Form.Select required onChange={(e) => changeValue(e)} id="configName" name="configName" value={vehicle.configName}>
                            <option value={undefined}></option>
                            { vehicleConfigs?.map(config => <option key={config.description} value={config.description}>{config.source === 'local' ? t(config.description) : config.description}</option>)}
                        </Form.Select>
                    </Col>
                </Form.Group>
                <Row>
                    <Col sm="4"></Col>
                    <Col sm="6"><VehicleDrawing configuration={vehicle.configuration}/></Col>
                    <Col sm="2"></Col>
                </Row>
                <hr></hr>
                <Form.Group as={Row} className="">
                    <Form.Label htmlFor="driver_name" column sm="4">
                        {t('Driver name')}
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control onChange={(e) => changeValue(e)} maxLength="42" id="driver_name" name="driver.name" value={vehicle?.driver?.name} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mt-2">
                    <Form.Label htmlFor="driver_mobile_no" column sm="4">
                        {t('Mobile number')}
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control onChange={(e) => changeValue(e)} maxLength="25" id="driver_mobile_no" name="driver.mobile_no" value={vehicle?.driver?.mobile_no} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mt-2">
                    <Form.Label htmlFor="driver_telephone_no" column sm="4">
                        {t('Telephone number')}
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control onChange={(e) => changeValue(e)} maxLength="25" id="driver_telephone_no" name="driver.telephone_no" value={vehicle?.driver?.telephone_no} />                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mt-2">
                    <Form.Label htmlFor="driver_email" column sm="4">
                        {t('E-mail')}
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control onChange={(e) => changeValue(e)} maxLength="100" id="driver_email" name="driver.email" type="email" value={vehicle?.driver?.email} />
                    </Col>
                </Form.Group>

            </Modal.Body>
        <Modal.Footer>
            <Button className={modalMode ? '' : 'd-none'} variant="secondary" onClick={() => closeModal()}>
                {t('Close')}
            </Button>
            <Button variant="success" disabled={loading} type="submit">
                <FontAwesomeIcon icon={faCheck}/> {t('Create vehicle')}
            </Button>
        </Modal.Footer>
    </Form>
)}

export default withNamespaces()(NewVehicleForm);