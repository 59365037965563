import React, { useState, useEffect } from 'react'
import {fetchHistory,downloadDocument} from '../../api/fleet'
import { Table, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons'
import { withNamespaces } from 'react-i18next';
import moment from 'moment'

function VehicleHistoryTable({flt_id,count,t}) {

    const [historyRecords,setHistoryRecords] = useState([])
    const [buttonsDisabled,setButtonsDisabled] = useState(false)
  
    useEffect(() => {
      async function getVehicleHistory() {
        const response = await fetchHistory(flt_id)
        setHistoryRecords(response.data)
      }
      getVehicleHistory()
    },[])

    useEffect(() => {
      if (count) {
        count(historyRecords? historyRecords.length : 0)
      }
    },[historyRecords])

      async function downloadPdf(e,db_id,doctype,docNumber) {
        e.preventDefault()
        e.stopPropagation();
        setButtonsDisabled(true)
        try {
            const base64data = await downloadDocument(db_id,doctype)
            if (base64data && base64data.data !== '') {
                const linkSource = `data:application/pdf;base64,${base64data.data}`;
                const downloadLink = document.createElement("a");
                const fileName = `${docNumber}.pdf`;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();

                setButtonsDisabled(false)
            } else {
                throw new Error(t('The PDF file is invalid.'))
            }

        } catch (e) {
            window.handleError(e)
            setButtonsDisabled(false)
        }

    }

  return (
    <div className="table-responsive">
    <Table className="table table-hover align-middle">
      <tbody>
        {historyRecords ? historyRecords.map(record => 
          <tr key={record.db_id[0]}>
            <td><b>{record.document_number_text[0]}</b></td>
            <td>{moment(record.date[0],'YYYYMMDD').format('DD/MM/YYYY')}</td>
            <td>{t(record.document_type[0])}</td>
            <td>{new Intl.NumberFormat('be-BE', { style: 'currency', currency: 'EUR' }).format(record.amount_excl[0])}</td>
            <td>
              <Button 
                disabled={buttonsDisabled} size="sm" 
                onClick={e => downloadPdf(e,record.db_id[0],'sales_document',record.document_number_value[0])}>
                <FontAwesomeIcon icon={fa.faDownload}/> {t('Download')}
              </Button>
            </td>
          </tr>
        ) : <></>}
      </tbody> 
    </Table>
    </div>
  )
}

export default withNamespaces()(VehicleHistoryTable)