import React, { useState, useEffect, forwardRef } from 'react'
import { Row, Col, InputGroup, Form, Table } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next';
import SubmittedOperation from '../submittedOperation'
import DatePicker from "react-datepicker";
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import * as fa from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OperationsToClipboardButton from './operationsToClipboardButton';
import PrintOperationsButton from './printOperationsButton';
import Select from 'react-select';
import selectStyle from './selectStyle';
import { getRecentStockLocations } from '../../../../api/hoteladmin'


function RecallTab({t,recallOps,operationUpdated,theadStyle,tbodyStyle}) {

    //recallOps state with most recent stocklocation 
    const [recentRacks,setRecentRacks] = useState([])
    const [recallDateFilter,setRecallDateFilter] = useState(undefined)
    const [recallCustomer,setRecallCustomer] = useState(undefined)
    const [filteredOps,setFilteredOps] = useState(recallOps) 
    useEffect(() => { 
        const inputDelay = setTimeout(() => {
            setFilteredOps(recallOps.filter(op => { 
              const datecheck = recallDateFilter ? moment(op.deliveryDate).format('DDMMYYYY') === moment(recallDateFilter).format('DDMMYYYY') : true
              const customerCheck = recallCustomer ? (new RegExp(recallCustomer,"i")).test(op.user.companyname) : true
              op.tyres.forEach(tyre => {

                //find rack recent records and replace the original tyre location that was stored at operation insertion
                const rackRecord = recentRacks.filter(rr => rr.fsh_fleettyre[0] === tyre.db_id[0])[0]
                if (rackRecord) {
                  tyre['stock_rack'] = rackRecord.fsh_stocklocation                  
                }
                
              })
              return (datecheck && customerCheck)
            }))
          }, 200)
          return () => clearTimeout(inputDelay) 
    },[recallCustomer, recallDateFilter, recallOps,  recentRacks])

    //fetch recent stock locations of a collection of tyre ids and store data in stateful array 
    useEffect(async () => {
      try {
        const tyreIds = recallOps.flatMap(op => op.tyres).map(tyre => tyre.db_id[0])
        if (tyreIds.length > 0) {
          const recentStockLocations = (await getRecentStockLocations(tyreIds)).data
          setRecentRacks(recentStockLocations)
        }
      } catch(e) {
        window.handleError(e)
      }
    },[recallOps])

    const customerNames = recallOps.map(op => op.user.companyname).filter((value,index,self) => self.indexOf(value) === index)
    const customerSelectValues = customerNames.map(name => { return { value: name, label: name } } )

    //get array of unique customerNames
    const CustomDatePickerInput = forwardRef((props,ref)=>{
      return(
        <InputGroup ref={ref}>
          <InputGroup.Text><FontAwesomeIcon icon={fa.faCalendar}/></InputGroup.Text>
          <Form.Control onClick={props.onClick} value={props.value} placeholder={props.placeholder} onChange={props.onChange} disabled={props.disabled} />
        </InputGroup>
      );
    });

    CustomDatePickerInput.displayName = 'CustomDatepickerInput'

  return (
    <>
    { recallOps?.length > 0 ? <>
    <hr className="mt-1"/>
    <Row className="mt-2 mb-2 g-2">
        <Col lg={4} sm={12}>
           <InputGroup>
              <InputGroup.Text><FontAwesomeIcon icon={fa.faUser}/></InputGroup.Text>
              <div className="react-select form-control p-0">
                <Select styles={selectStyle} isClearable options={customerSelectValues} onChange={(e) => e ? setRecallCustomer(e.value) : setRecallCustomer('')} placeholder={t('Customer filter...')}/>
              </div>
           </InputGroup>
           
        </Col>
        <Col lg={4} sm={12}> 
          <InputGroup>
            <DatePicker  
                customInput={<CustomDatePickerInput />}
                required
                isClearable
                clearButtonClassName="date-clear-button"
                clearButtonTitle="Clear"
                placeholderText={t('Recall date filter...')}
                className="form-control"
                disabledKeyboardNavigation
                selected={recallDateFilter}
                value={ recallDateFilter ? moment(recallDateFilter).format('DD/MM/YYYY') : '' } 
                onChange={(date) => setRecallDateFilter(date)}/>
          </InputGroup>
      </Col>
      <Col lg={2} sm={6}><OperationsToClipboardButton operations={filteredOps} className="w-100"/></Col>
      <Col lg={2} sm={6}><PrintOperationsButton type="recall" operations={filteredOps} printStockLocations printRecallDate printTransport className="w-100"/></Col>
    </Row>
    <hr/>
     
      <Table responsive={true} className="bg-white table table-hover">
        <thead style={theadStyle}>
          <tr>
            <th>{t('Customer')}</th>
            <th>{t('Operation')}</th>
            <th>{t('Vehicle')}</th>
            <th>{t('Tyres')}</th>
            <th>{t('Recall date')}</th>
            <th>{t('Transport')}</th>
            <th>{t('Submission')}</th>
          </tr>
        </thead>
        <tbody style={tbodyStyle}>
          { filteredOps
            .sort((a,b) => new Date(a.deliveryDate) - new Date(b.deliveryDate)) //sort by deliveryDate
            .map(operation => <SubmittedOperation key={operation._id} hideServices={true} operation={operation} onUpdate={() => {operationUpdated(operation._id)}} />)
          }
        </tbody> 
        </Table></> : <p className="text-center opacity-50 mt-2">{t('No submitted recall operations.')}</p>}
    </>
  )
}

export default withNamespaces()(RecallTab)