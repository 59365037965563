import axios from 'axios';

axios.defaults.withCredentials = true;

export const getHotelConfig = (id) => axios.post( '/hotelconfig/adminconfig',{id : id})

export const editConfig = (config) => {
    return axios.post( '/hotelconfig/edit', config)
}

export const editConfigImage = (config, file) => {
    //for multipart form transform to formdata object 
    const formData = new FormData()

    formData.append('id', config._id)

    const fieldName = Object.keys(file)[0]
    
    formData.append(fieldName, file[fieldName], file[fieldName].name)
    return axios.post('/hotelconfig/editconfigimage', formData)
}

export const addRecallTransport = (hotelId, transportMethod) => {
    return axios.post('/hotelconfig/newrecalltransport', { _id : hotelId, transportMethod : transportMethod })
}

export const getAdminOperations = async (status) => axios.post('/operations/admin', {status : status})
export const adminOpsByDate = async (startDate,endDate) => axios.post('/operations/adminopsbydate',  { startDate: startDate, endDate: endDate});
export const adminOpsByDateLight = async (startDate,endDate) => axios.post('/operations/adminopsbydate_light',  { startDate: startDate, endDate: endDate});

export const cancelOperation = async (operation) => axios.post('/operations/cancel', operation)
export const confirmOperation = async (operation) => axios.post('/operations/confirm', operation)
export const confirmScans = async(tyres) => axios.post('/operations/markscan', tyres)

export const findCustomerbyName = async(string) => axios.post('/fleet/findcustomerbyname',{string:string})
export const getCustomerFleet = async (seq_id) => axios.post( '/fleet/fetch',{seq_id : seq_id})
export const getRecentStockLocations = async(tyreIds) => axios.post('/fleet/recentStockLocations', { tyreIds : tyreIds })

export const getInvoicingCustomers = async(days) => axios.post('/invoicing/customers', { days : days })
export const injectInvoicingDocs = async({tyres,seq_id}) => axios.post('/invoicing/inject', { tyres,seq_id})
export const markAsTyresInvoiced = async({tyres,date}) => axios.post('/invoicing/markinvoiced', { tyres,date})
export const unmarkTyreAsInvoiced = async ({tyreIds}) => axios.post('/invoicing/unmarkinvoiced', { tyreIds })


