import * as api from '../api/fleet'
import i18next from 'i18next';

export const loadFleetAndTyres = () => async(dispatch) => {
    try {
        const fleetData = (await api.getFleet()).data.vehicles      
        fleetData.forEach(vehicle => {
            vehicle.tyres[0].tyre?.map(tyre => enrichTyreObject(tyre,vehicle))
        })  
        const actionFleet = { type: 'FLEET_LOAD', payload : fleetData}
        dispatch(actionFleet)
    } catch(error) {
        window.handleError(error)
    }
}

//enrich tyre node with vehicle data
function enrichTyreObject(tyre,vehicleObject) {
    tyre['flt_id'] = vehicleObject.flt_id
    tyre['license_plate'] = vehicleObject.license_plate
    tyre['vehicle_type'] = vehicleObject.type
    return tyre
}

export const addVehicle = (vehicle) => async(dispatch) => {
    const toast = window.loadingShow(i18next.t('Creating vehicle'))
    try {       
        const newVehicle = await api.newVehicle(vehicle)
        dispatch({ type: 'FLEET_ADD', payload : newVehicle.data})
        window.loadingResolve(toast,'success',i18next.t('Vehicle created'))
        return newVehicle.data
    } catch (error) {
        window.loadingResolve(toast,'error',error)
        return false
    }
}

//update vehicle and then update the state
export const updateVehicle = (vehicleObject,hideMessage,) => async(dispatch) => {
    let toast
    if (!hideMessage) {
        toast =  window.loadingShow(i18next.t('Updating vehicle...'))
    }
    
    try {
        const response = await api.updateVehicle(vehicleObject)
        const updatedVehicle = response?.data?.updatedVehicle
        if (updatedVehicle) {

            if (updatedVehicle.tyres && updatedVehicle.tyres[0] !== '') {
                vehicleObject.tyres[0].tyre = vehicleObject.tyres[0].tyre.map(tyre => enrichTyreObject(tyre,updatedVehicle))
            }
            dispatch({type: 'FLEET_UPDATE', payload : updatedVehicle})
            if (toast) { window.loadingResolve(toast,'success',i18next.t('Vehicle updated')) }
        }
        return updatedVehicle
    } catch (e) {
        if (toast) { window.loadingResolve(toast,'error',e) }
        return false
    }
}

//update vehicle state only (after single fleet page load for example)
export const updateVehicleState = (vehicleObject) => async(dispatch) => {
    //enrich tyres on update
    if (vehicleObject.tyres && vehicleObject.tyres[0] !== '') {
        vehicleObject.tyres[0].tyre = vehicleObject.tyres[0].tyre.map(tyre => enrichTyreObject(tyre,vehicleObject))
    }
    dispatch({type: 'FLEET_UPDATE', payload : vehicleObject})
}

export const getVehicleConfigs = () => async(dispatch) => {
    try {
        const { data } = await api.fetchVehicleConfigs();
        dispatch({ type: 'SITECONFIG_ADD_ELEMENTS', payload: data})
    } catch(e) {
        window.handleError(e)
    }
}

export const removeVehicle = (flt_id) => async (dispatch) => {
    const confirmation = await window.confirmation(i18next.t('Remove vehicle?'))
    if (confirmation) {
        const toast = window.loadingShow(i18next.t('Removing vehicle'))
        try {
            await api.inactivateVehicle(flt_id);
            dispatch({ type: 'FLEET_REMOVE', payload: {flt_id : flt_id}})
            window.loadingResolve(toast,'success',i18next.t('Vehicle removed'))
            return true;
        } catch(e) {
            window.loadingResolve(toast,'error',e)
            return false;
        }
    } else {
        return false
    }
}