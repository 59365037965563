import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Button} from 'react-bootstrap'
function TyreStockDetailButton({ onClick }) {

    return (
        <Button onClick={onClick} style={{height: '20px', width: '20px', paddingLeft: '2px'}} variant="outline-secondary" size="sm">
            <FontAwesomeIcon style={{height: '10px', marginBottom: '8px'}} icon={faSearch}/>
        </Button>
    )
}

export default TyreStockDetailButton
