import { combineReducers } from "redux";

import User from './user'
import Siteconfig from './siteconfig'
import Fleet from './fleet'
import Operations from './operations'
import AdminFleet from './adminfleet'
export default combineReducers({
    User,
    Siteconfig,
    Fleet,
    Operations,
    AdminFleet
})