import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Row, Col, InputGroup, Card } from 'react-bootstrap'
import {faCheck, faPlus} from '@fortawesome/free-solid-svg-icons'
import { withNamespaces } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import { searchArticles } from '../../api/fleet'
import { updateVehicle } from '../../actions/vehicles';
import VehicleDrawing from '../common/vehicleDrawing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'

function NewTyreModal({t, className, vehicle, selectTyres}) {
    const dispatch = useDispatch()
    const [show,setShow] = useState(false) //CHANGE TO FALSE WHEN DONE DEV
    const siteConfigStore = useSelector((store) => store.Siteconfig)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const {unknownAllSeasonArticleId, unknownSnowArticleId, unknownSummerArticleId, unknownWinterArticleId } = siteConfigStore
    const knownTyres = vehicle.tyres[0]?.tyre ? vehicle.tyres[0]?.tyre.filter(tyre => tyre.tyre_status !== 'waste') : [] 
    const setLessTyres = knownTyres.filter(tyre => !tyre.tyre_set || tyre.tyre_set[0] === 'setless')
    async function handleSubmit(e) {
        e.preventDefault()
        if (selectedArticle) {
            setAddTyreLoading(true)

            //check for duplicate positions in tyre set before proceeding
            const positionsInUse = tyresInSet.map(tyre => tyre.position[0])
            const copiedVehicle = {...vehicle}
    
            let duplicatePositions = []
    
            let tyres = copiedVehicle?.tyres[0] ? [...copiedVehicle?.tyres[0].tyre] : []
            articleComposition.forEach(composition => {
                if (positionsInUse.includes(composition.position)) {
                    duplicatePositions.push(composition.position)
                } else {
                    const tyreToPush = {
                        art_id : composition.article.art_id,
                        unknownArticle : composition.article.unknownArticle ? true : false,
                        position : composition.position,
                        description : composition.article.description,
                        action : "to_customer",
                        operation : 'insert',
                        tyre_set : tyreSet
                    }
                    tyres.push(tyreToPush)
                }
    
            })
    
            if (duplicatePositions.length > 0) {
                window.notify('error', t('The positions {{positions}} are already taken in this tyre set.', {positions : duplicatePositions})  )
            } else {
                copiedVehicle.tyres = [{tyre : tyres}]
                const updatedVehicle = await dispatch(updateVehicle(copiedVehicle))
                if (updatedVehicle) {
                    if (selectTyres) { 
                        if (tyreSet) {
                            console.log(updatedVehicle?.tyres)
                            const tyreSetArticles = updatedVehicle?.tyres? updatedVehicle?.tyres[0]?.tyre?.filter(tyre => tyre.tyre_set ? tyre.tyre_set[0] === tyreSet : false): []
                            selectTyres(tyreSetArticles) 
                        } else {
                            const setlessArticles = updatedVehicle?.tyres? updatedVehicle?.tyres[0]?.tyre?.filter(tyre => !tyre.tyre_set): []
                            selectTyres(setlessArticles) 
                        }                 
                    }

                    setPickedArticles([])
                    setShow(false)   
                    setShowArticleSearch(true)
                    setShowPositionPicker(false)
                    setSearchstring('')
                    setSelectedArticle([])
                    setArticleComposition([])
                    setTyreSet(undefined)
                    setArticles([])
                }
            }
            setAddTyreLoading(false)
        }
        
    }


    const [searchstring,setSearchstring] = useState('')

    const [articlesLoading, setArticlesLoading] = useState(false)
    const [articles,setArticles] = useState([])
    const [moreAvailable,setMoreAvailable] = useState(false)
    const [noArticleMessage, setNoArticleMessage] = useState(undefined)

    async function handleSearch(e) {
        e.preventDefault()
        setMoreAvailable(false)
        setArticlesLoading(true)
        setArticles([])
        setNoArticleMessage(undefined)
        try {
            const articleResponse = (await searchArticles(searchstring)).data
            setMoreAvailable(articleResponse.more_available)
            if (articleResponse.articles?.item?.length > 0) {
                setArticles(articleResponse.articles.item)
            } else {
                setNoArticleMessage(t('No articles retrieved for this search parameter.'))
            }
            
        } catch (e) {
            window.handleError(e)
        }

        setArticlesLoading(false)

    }

    async function loadMoreArticles() {
        const articleIds = [...articles.map(article => Number(article.art_id[0]))]
        const lastArticleId = Math.max(...articleIds)
        try {
            setMoreAvailable(false)
            setArticlesLoading(true)
            const articleResponse = (await searchArticles(searchstring,lastArticleId)).data
            setMoreAvailable(articleResponse.more_available)
            if (articleResponse.articles?.item?.length > 0) {
                //merge 2 arrays
                const existingArticles = [...articles]
                const articlesToAdd = articleResponse.articles.item
                setArticles(existingArticles.concat(articlesToAdd))
            } else {
                setNoArticleMessage(t('No articles retrieved for this search parameter.'))
            }
            
        } catch (e) {
            window.handleError(e)
        }
        setArticlesLoading(false)
    } 
    
    const [addTyreLoading,setAddTyreLoading] = useState(false)

    const [selectedArticle, setSelectedArticle] = useState()

    const [pickedArticles,setPickedArticles] = useState([])

    const [showArticleSearch, setShowArticleSearch] = useState(true)
    
    const [showPostitionPicker, setShowPositionPicker] = useState(false)

    const [tyreSet, setTyreSet] = useState(undefined)

    const [articleComposition,setArticleComposition] = useState([])

    //remove article / position combo & reorder
    function addToComposition(position,article) {
        const copiedComposition = [...articleComposition] 
        if (!copiedComposition.map(composition => composition.position).includes(position)) {
            setArticleComposition([...copiedComposition, {position: position, article: article}].sort((a,b) => (a.position > b.position) ?  1 : -1))
        }
    }

    //add article / position combo & reorder
    function removeFromComposition(position) {
        const copiedComposition = [...articleComposition]
        const indexToSplice = copiedComposition.map(composition => composition.position).indexOf(position);
        if (indexToSplice > -1) { // only splice array when item is found
            copiedComposition.splice(indexToSplice, 1); // 2nd parameter means remove one item only
        }
        
        setArticleComposition(copiedComposition.sort((a,b) => (a.position > b.position) ?  1 : -1))
    }

    const [blockedPositions, setBlockedPositions] = useState([])
    const [activePositions,setActivePositions] = useState([])

    //when article composition / selected article changes --> set blocked / active positions
    useEffect(() => {
        let positionsToBlock = articleComposition.filter(composition => composition.article !== selectedArticle).map(composition => composition.position)
        let positionsToActivate = articleComposition.filter(composition => composition.article == selectedArticle).map(composition => composition.position)
        setBlockedPositions(positionsToBlock)
        setActivePositions(positionsToActivate)
    },[articleComposition,selectedArticle])


    const [unknownArticleForm, setUnknownArticleForm] = useState({
        show: false,
        art_id : undefined,
        description: undefined
    })

    function showUnknownArticleForm() {
        const copiedData = {...unknownArticleForm}
        copiedData["show"] = true
        setUnknownArticleForm(copiedData)
    }

    function hideUnknownArticleForm() {
        const copiedData = {...unknownArticleForm}
        copiedData["show"] = false
        setUnknownArticleForm(copiedData)
    }

    function setUnknownTyre() {
        if (unknownArticleForm.art_id && unknownArticleForm.description) {
            addPickedArticle({
                art_id : [unknownArticleForm.art_id], 
                description: [unknownArticleForm.description],
                unknownArticle : true
            })
        } else {
            window.notify('error',t('Unknown article type or description is missing.'))
        }
    }

    const [vehicleTyrePositions, setVehicleTyrePositions ] = useState(undefined)    

    const tyresInSet = tyreSet ? knownTyres.filter(tyre => tyre.tyre_set && tyre.tyre_set[0] == tyreSet) : knownTyres.filter(tyre => !tyre.tyre_set)
    function tyreSetRequired() {
        if (setLessTyres.length > 0) {
            //if full setless set --> true
            if (setLessTyres.length >= vehicleTyrePositions?.length) {
                return true
            } else {
            //if setless set has missing positions --> false
                return false
            }
        } else {
            return false
        }
    }

    function unpickArticle(article) {
        const copiedPickedArticles = [...pickedArticles]
        const indexToSplice = copiedPickedArticles.indexOf(article);
        if (indexToSplice > -1) { // only splice array when item is found
            copiedPickedArticles.splice(indexToSplice, 1); // 2nd parameter means remove one item only
            setPickedArticles(copiedPickedArticles)
            if (copiedPickedArticles.length === 0) {
                setShowPositionPicker(false)
                setShowArticleSearch(true)
            }
        }
    }

    function addPickedArticle(article) {
        const copiedPickedArticles = [...pickedArticles]
        if (!copiedPickedArticles.includes(article)) {
            copiedPickedArticles.push(article)
            if (copiedPickedArticles.length === 1) {
                setSelectedArticle(article)
            }
            setPickedArticles(copiedPickedArticles)
        }
        setShowPositionPicker(true)
        setShowArticleSearch(false)
    }


    useEffect(() => {
        if (pickedArticles.length > 0) {
            setSelectedArticle(pickedArticles[0])
        }
    },[pickedArticles])
    

    //console.log('selectedArticle',selectedArticle)
    //console.log('pickedArticles',pickedArticles)
    //console.log('vehicleTyrePositions',vehicleTyrePositions)
    //console.log('articleComposition',articleComposition)
    //console.log('blockedPositions',blockedPositions)

    const unknownArticleComponent = <div>
        <Button variant="outline-primary" size="sm" onClick={() => hideUnknownArticleForm()}><FontAwesomeIcon icon={fa.faChevronLeft}/> {t('Search other article')}</Button><br/>
        <Row className="mt-2">
            <Col sm={3}>
                <Form.Label>{t('Define type of tyre')}</Form.Label>
                <Form.Select required value={unknownArticleForm.art_id} onChange={(e) => setUnknownArticleForm({...unknownArticleForm, art_id : e.target.value})}>
                    <option disabled selected value={undefined}>{t('Pick article type')}</option>
                    { unknownSummerArticleId ? <option value={unknownSummerArticleId}>{t('Summer tyre')}</option>: '' }
                    { unknownAllSeasonArticleId ? <option value={unknownAllSeasonArticleId}>{t('All Season tyre')}</option> : '' }
                    { unknownWinterArticleId ? <option value={unknownWinterArticleId}>{t('Winter tyre')}</option>: '' }
                    { unknownSnowArticleId ? <option value={unknownSnowArticleId}>{t('Snow tyre')}</option>: '' }
                </Form.Select>
            </Col>
        <Col sm={6}>
            <Form.Label>{t('Enter tyre description')}</Form.Label>
            <Form.Control 
                value={unknownArticleForm.description} 
                placeholder="205/55-16 ContiPremiumContact 5 TL 91H Continental" required 
                maxLength="99"
                onChange={(e) => setUnknownArticleForm({...unknownArticleForm, description : e.target.value})}
                onKeyDown={e => e.key  === 'Enter' ? setUnknownTyre() : ''}
            />
        </Col>
        <Col sm={3} >
            <br/>
            <Button onClick={() => setUnknownTyre()} variant="outline-primary" className="mt-2" type="button">
                <FontAwesomeIcon icon={fa.faChevronRight}/> {t('Proceed')}
            </Button>
        </Col>
        
        </Row>

    </div>

    const ManualArticleSegment = () => (articles.length > 0 || noArticleMessage) && (unknownAllSeasonArticleId || unknownSnowArticleId || unknownSummerArticleId || unknownWinterArticleId) ? 
    <small onClick={() => showUnknownArticleForm()} className="pointer text-primary"> <FontAwesomeIcon icon={fa.faQuestion}/> {t(`I can't find the tyre I'm looking for`)}</small>
    : ''
    

    return (
        <>
        <Button className={className} onClick={() => {handleShow()}}><FontAwesomeIcon icon={faPlus}/> {t('Add tyres')}</Button>
        <Modal centered={true} size="lg" show={show} onHide={handleClose}>
            <Form onSubmit={(e) => {handleSubmit(e)}}>
                <Modal.Header closeButton>
                <Modal.Title>{t('Add tyres to vehicle')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showArticleSearch ? 
                    <>
                    {!unknownArticleForm.show ? <>
                        <Form.Label><b>{t('Search article')}</b><br/><small>{t('Insert tyre dimension followed by speed index. Add "*" followed by brand for more specific articles.')}</small></Form.Label>
                        <Form onSubmit={(e) => handleSearch(e)} >
                            <InputGroup>
                                <Form.Control disabled={articlesLoading} placeholder={t('2055516H*Michelin')} required value={searchstring} onChange={(e) => setSearchstring(e.target.value)} name="search_string" />
                                <Button disabled={articlesLoading} type="submit" onClick={handleSearch}>
                                    { articlesLoading ? 
                                        <><FontAwesomeIcon icon={fa.faCircleNotch} className="spinner"/> {t('Loading')}</>
                                        : <><FontAwesomeIcon icon={fa.faSearch}/> {t('Search')}</>
                                    }
                                </Button>
                            </InputGroup>
                        </Form>
                            {articles.length > 0 ? <Form.Label className="mt-2"><b>{t('Found articles')}</b>
                        </Form.Label> : ''}
                        {articles.length > 0 ? <Form.Group className={'border px-2'} style={{maxHeight: '40vh', overflowY: 'scroll'}}>
                            {articles ? articles.map((article,i) => {
                            return <div key={article.art_id} onClick={() => addPickedArticle(article)} className={`mt-2 pl-2 ${i === 0 ? '' : 'border-top'} article-select pointer`}>

                                <p style={{marginBottom: '0.5rem', marginTop: '0.5rem'}}>
                                    <span className="pointer"><FontAwesomeIcon className="text-primary" icon={fa.faCheck}/> {article.description}</span>
                                </p>
                            </div>
                            }) : ''}
                        </Form.Group> : ''}
                        
                        {moreAvailable ? <>
                            <small className="text-primary pointer font-weight-bold" onClick={() => loadMoreArticles()}><FontAwesomeIcon icon={fa.faPlus}/> {t('Load more')}</small>
                        </> : ''}
                        
                        { noArticleMessage ? 
                        <>
                            <b className="text-danger"> {noArticleMessage} </b><br/>
                        </> : ''}
                        <ManualArticleSegment/>
                    </>
                    : unknownArticleComponent }
                    </> : '' }
                    { showPostitionPicker ? <>
                            <Form.Label><b>{t('Selected article')}</b></Form.Label>
                            <p className="mb-0">
                                {pickedArticles?.map(article => <p key={article.art_id[0]} onClick={() => setSelectedArticle(article)} className={`mb-1 pointer`}>
                                        <span className={`py-1 px-2 ${selectedArticle?.art_id[0] === article.art_id[0] ? 'rounded bg-primary text-white' : 'text-muted'}`}>
                                            <FontAwesomeIcon icon={fa.faRing}/>&nbsp;
                                            <span >{article.description}</span>
                                        </span>&nbsp;
                                        <span onClick={() => {unpickArticle(article); setArticleComposition([]);}} className="text-danger pointer"><FontAwesomeIcon icon={fa.faTimes}/></span>
                                    </p>
                                )}
                            </p>
                            <small className="text-primary pointer" onClick={() => {setShowArticleSearch(true); setShowPositionPicker(false)}}><FontAwesomeIcon icon={fa.faPlus}/> {t('Add another article')}...</small>
                            <hr/>
                            <Form.Group as={Row} className="mt-3">
                            <Col sm={4}>
                                <VehicleDrawing 
                                    tyrePositions={(positions) => !vehicleTyrePositions ? setVehicleTyrePositions(positions) : ''} 
                                    onPositionSelect={(e) => addToComposition(e,selectedArticle)} 
                                    onPositionDeselect={(e) => removeFromComposition(e,selectedArticle)}
                                    blockedPositions={blockedPositions} 
                                    activePositions={activePositions}
                                    configuration={vehicle.configuration[0]}
                                />
                            </Col>
                            <Col sm={8}>
                                <Form.Label><b>{t('Selected positions')}</b></Form.Label>
                                { articleComposition.length > 0 ?
                                <Card><Card.Body>
                                    {articleComposition.map(composition => {
                                        const truncatedDesc = composition.article.description[0].length > 60 ? `${composition.article.description[0].substring(0,60)} ...` : composition.article.description[0]
                                        return <p className="mb-1" key={composition.position[0]}><b>{composition.position}: </b><small>{truncatedDesc}</small><hr className="m-0"/></p>
                                    })}
                                </Card.Body></Card>
                                 : <p className="opacity-50">{t('No positions selected')}</p> }

                                <Form.Label><b>{t('Tyre set name')} {tyreSetRequired() ? <></> : <>({t('optional')})</>}</b></Form.Label>
                                <Form.Control value={tyreSet} required={tyreSetRequired()} onChange={(e) => {setTyreSet(e.target.value)}} placeholder={t('Tyre set name')}></Form.Control>
                            </Col>
                        </Form.Group>

                    </> : ''}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('Close')}
                </Button>
                <Button variant="success" className={selectedArticle && articleComposition.length > 0 && !addTyreLoading ? '' : 'disabled'} type="submit">
                    <FontAwesomeIcon icon={faCheck}/> {t('Add tyres')}
                </Button>
                </Modal.Footer>
            </Form>
        </Modal>
        </>
        
    )
}

export default withNamespaces()(NewTyreModal)