import React, { useEffect, useState } from 'react'
import { getConfigs, deleteConfig } from '../../api/superuser'
import NewConfigModal from './NewConfigModal'
import {Table, Button} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';

function SuperUserPage() {
    
    const [configs, setConfigs] = useState([])
    const navigate = useNavigate();
    async function fetchConfigs() {
        try {
            const fetchedConfigs = await getConfigs()
            setConfigs(fetchedConfigs.data.configs) 
        } catch (e) {
            window.handleError(e)
        }
    }

    useEffect(() => {
        fetchConfigs()
    }, [])

    async function handleDelete(id) {
        const confirmation = await window.confirmation('Are you sure?')
        if (confirmation) {
            try {
                await deleteConfig(id)
                window.notify('success', 'TyreHotel config deleted successfully.')
                fetchConfigs()
            } catch (e) {
                window.handleError(e)
            } 
        }
    }

    function goToConfig(id) {
        navigate('../config', {state: { id: id}})
    }

    return (
        <div>                                 
            <h1>Super user dashboard</h1>
            <NewConfigModal fetchConfigs={fetchConfigs}/>
            <Table className="mt-2" striped hover>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Webstream Account</th>
                        <th>Admin e-mail</th>
                        <th>URL(s)</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {configs?.length > 0 ? configs.map(config => {
                        return <tr key={config._id}>
                            <td>{config.name}</td>
                            <td>{config.webstreamAccount}</td>
                            <td>{config.adminEmail}</td>
                            <td>{config.urls}</td>
                            <td>
                                <Button variant="outline-primary" size="sm" onClick={() => goToConfig(config._id)}><FontAwesomeIcon icon={fa.faSearch}/> View</Button>&nbsp;
                                <Button variant="outline-danger" size="sm" onClick={() => handleDelete(config._id)}><FontAwesomeIcon icon={fa.faTimes}/> Delete</Button>
                            </td>
                        </tr>
                    }) : <tr><td colSpan="100%">No configs loaded.</td></tr>}
                </tbody>
            </Table>
        </div>
    )
}

export default SuperUserPage
