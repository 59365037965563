import React, {useEffect, Suspense} from 'react';

import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux'
import { loginState } from './actions/user';
import { gsc, getTsConfigData } from './actions/general'
import { loadOperations } from './actions/operations';
import { getVehicleConfigs } from './actions/vehicles'

import Navbar from './components/navbar'

import Homepage from './components/homepage'
import HomepageAdmin from './components/adminpage/landing/'

import Adminpage from './components/adminpage'
import AdminHotelConfig from './components/adminpage/shopconfig/'
import AdminOperations from './components/adminpage/operations/'
import IntakeForm from './components/adminpage/intake/'
import FleetManagement from './components/adminpage/fleetmanagement'
import Invoicing from './components/adminpage/invoicing'

import Superuserpage from './components/superuserpage'
import Configlist from './components/superuserpage/configlist'
import Translations from './components/superuserpage/translations'

import Fleetpage from './components/fleetpage'
import FleetOverview from './components/fleetpage/overview'
import Vehicle from './components/fleetpage/vehicle'

import TyresPage from './components/tyres'
import TyresOverview from './components/tyres/overview'
import Notifications from './components/notifications'

import OperationsPage from './components/operations'
import OperationsOverview from './components/operations/overview'

import WizardPage from './components/wizard'
import LookupVehicle from './components/wizard/LookupVehicle.js'
import PickService from './components/wizard/PickService';
import SubmitOperation from './components/wizard/SubmitOperation'
import FinalStep from './components/wizard/FinalStep'

import Login from './components/login'
import PageNotFound from './components/PageNotFound'

import { Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import './styles/styles.scss'
import './styles/toastify.scss'

import '@fortawesome/fontawesome-svg-core/styles.css'

function App() {

  const siteConfigState = useSelector((store) => store.Siteconfig)
  //set site variables
  const favicon = document.getElementById("favicon");
  const site_title = document.getElementById('website-title')
  favicon.href = siteConfigState?.faviconPath ? siteConfigState?.faviconPath : ''
  site_title.innerHTML = siteConfigState?.name ? siteConfigState?.name : 'Site not defined'

  const userState = useSelector((store) => store.User)
  
  const dispatch = useDispatch()

  useEffect(() => {
    if (!userState.isAuthenticated) {
      dispatch(loginState())
      dispatch(gsc())
    }
  }, [])

  useEffect(() => {
    //load various data on first login 
    if (userState.isAuthenticated & userState.type !== 'superuser') {
      //vehicletypes & configs
      dispatch(getTsConfigData())
      dispatch(loadOperations())
      dispatch(getVehicleConfigs())
    } else {
      //if user is no longer authenticated, clear fleet from store
      dispatch({ type: 'FLEET_CLEAR'})
    }
  }, [userState.isAuthenticated])

  return (  
      <>
      { userState.isAuthenticated ? 
      <header className="App-header">
        <BrowserRouter>
          <Suspense fallback="Loading...">
            <Navbar/>
          </Suspense>

          <Container className="bg-light p-sm-3 p-md-5 mt-4 pt-3 view-container">
            
            <Notifications/>
            <Suspense fallback="Loading...">
              <Routes>
                {['user'].includes(userState.type) ? <Route path="/" element={ <Homepage/> }/> : ''}
                {['admin'].includes(userState.type) ? <Route path="/" element={ <HomepageAdmin/> }/> : ''}
                {['admin','user'].includes(userState.type) ? 
                  <Route path="fleet" element={ <Fleetpage/> }>
                    <Route path="" element={ <FleetOverview/> }/> 
                    <Route path="vehicle" element={ <Vehicle/> }/> 
                  </Route> 
                : ''}
                
                {['admin','user'].includes(userState.type) ? 
                  <Route path="tyres" element={ <TyresPage/> }>
                    <Route path="" element={ <TyresOverview/> }/> 
                  </Route> 
                : ''}
                {['admin','user'].includes(userState.type) ? <Route path="/tyres" element={ <TyresPage/> }/> : ''}
                
                {['admin','user'].includes(userState.type) ? <>
                  <Route path="operations" element={ <OperationsPage/> }>                    
                    <Route path="" element={<OperationsOverview/>}/>
                  </Route>
                  </> : ''}

                  {['admin','user'].includes(userState.type) ? <>
                  <Route path="wizard" element={ <WizardPage/> }>                    
                    <Route path="step1" element={<LookupVehicle/>}/>
                    <Route path="step2" element={<PickService/>}/>
                    <Route path="step3" element={<SubmitOperation/>}/>
                    <Route path="step4" element={<FinalStep/>}/>
                  </Route>
                  </> : ''}

                {['admin'].includes(userState.type) ? <>
                  <Route path="admin" element={ <Adminpage/> }>
                    <Route path="config" element={<AdminHotelConfig/>}/>
                    <Route path="operations" element={<AdminOperations/>}/>
                    <Route path="intake" element={<IntakeForm/>}/>
                    <Route path="fleetmanagement" element={<FleetManagement/>}/>
                    <Route path="invoicing" element={<Invoicing/>}/>
                  </Route>
                </>: ''}
                
                {['superuser'].includes(userState.type) ? 
                <>
                  <Route path="/" element={<Navigate to="/superuser"/>}/>
                  <Route path="superuser" element={ <Superuserpage/> }>
                    <Route path="" element={ <Configlist/> }/> 
                    <Route path="config" element={ <AdminHotelConfig/> }/> 
                    <Route path="translations" element = { <Translations/> }/>
                  </Route>
                </>: ''}
                
                <Route path="*" element={ <PageNotFound/> } />
              
              </Routes>
            </Suspense>
          </Container>
        </BrowserRouter>
      </header>
      : <Login/> }
      </>
  ) 

}

export default App;