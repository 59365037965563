import React from 'react'
import { withNamespaces } from 'react-i18next';
import { Card, Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'

function ScanResult({historyRecord, lang, index}) {
    return (
        <>
            {historyRecord.error ?     
            <Card className={`mt-1 ${index === 0 ? 'bg-danger text-white' : 'bg-danger bg-opacity-25'} `}>
                <Card.Header><small><FontAwesomeIcon icon={fa.faBarcode}/> {historyRecord.label}</small></Card.Header>
                <Card.Body>
                    <h5 className="mb-0">
                        <FontAwesomeIcon icon={fa.faTimes}/> {historyRecord.error}
                    </h5>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col className="text-start">
                            <small >
                                <FontAwesomeIcon icon={fa.faTimes}/> &nbsp;
                                {moment(historyRecord.scanTime).format('HH:mm:ss')}
                            </small>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card> :
            <Card className={`mt-1 ${index === 0 ? 'bg-success text-white' : 'bg-success bg-opacity-25'}`}>
                <Card.Header><small><FontAwesomeIcon icon={fa.faBarcode}/> {historyRecord.tyre.db_id}</small></Card.Header>
                <Card.Body>
                    <h3 className="mb-0">
                        <FontAwesomeIcon icon={fa.faCar}/>&nbsp;
                        {historyRecord.tyre.license_plate}
                    </h3>
                    {/*services mapping*/}
                    {historyRecord?.operation?.services?.length > 0 ? <>
                        <Row className="mt-4 justify-content-md-center">
                            <hr/>
                            {historyRecord?.operation?.services?.map((service,i) => <Col key={i} md={2}>
                                {service.icon ? <FontAwesomeIcon icon={fa[service.icon]}/> : ''}
                                <p className="text-center">{service[`description_${lang}`]}</p>
                            </Col>)}
                        </Row>
                    </> : ''}
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col className="text-start">
                            <small >
                                {historyRecord.tyre.scanned ? <FontAwesomeIcon icon={fa.faCheckDouble}/> : <FontAwesomeIcon className="spinner" icon={fa.faSpinner}/>} &nbsp;
                                {moment(historyRecord.scanTime).format('HH:mm:ss')}
                            </small>
                        </Col>
                        <Col className="text-end">
                            <small>{historyRecord.tyre.scanTimes > 1 ? <><FontAwesomeIcon icon={fa.faCopy}/> </>: '' }</small>
                        </Col>
                    </Row>

                </Card.Footer>
            </Card>  }
        </>

    )
}

export default withNamespaces()(ScanResult)