import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import * as fa from '@fortawesome/free-solid-svg-icons'
import { withNamespaces } from 'react-i18next';
import { markAsTyresInvoiced } from '../../../api/hoteladmin'
import moment from 'moment';

function NewVehicleModal({t,tyres,disabled,markUpdatedTyres}) {

    const [show,setShow] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    console.log('Marking', tyres)
    const [buttonDisabled,setButtonDisabled] = useState(false)
    async function handleSubmit(e) {
        e.preventDefault()
        try {
            setButtonDisabled(true)
            await markAsTyresInvoiced({tyres : tyres, date : date})
            window.notify('success',t('{{count}} tyres marked as invoiced',{count: tyres.length}))
            if (markUpdatedTyres) {
                const updatedTyres = [...tyres]
                updatedTyres.forEach(tyre => tyre.invoicedAt = date)
                markUpdatedTyres(updatedTyres)
                handleClose()
            }
        } catch (e) {
            window.handleError(e)
        }
        setButtonDisabled(false)

    }

    const [date,setDate] = useState(new Date())

    return (
        <>
        <Button variant="outline-primary" onClick={handleShow} className="d-inline-block" disabled={disabled}>
            <FontAwesomeIcon icon={fa.faCheck}/> {t('Mark invoiced tyres')}
        </Button>
        <Modal show={show} onHide={handleClose}>
            <form onSubmit={handleSubmit}>
                <Modal.Header>
                    <Modal.Title>{t('Mark invoiced tyres')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('Change the last invoice date of {{count}} tyres', {count : tyres.length})}</p>
                    <label>{t('Set invoice date')}</label>
                    <Form.Control type="date" name="date" value={moment(date).format('YYYY-MM-DD')} onChange={e => setDate(e.target.value)}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button  variant="secondary" onClick={() => handleClose()}>
                        {t('Close')}
                    </Button>
                    <Button disabled={buttonDisabled} variant="primary" type="submit">
                        <FontAwesomeIcon icon={fa.faCheck}/> {t('Submit')}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
        </>
    )
}

export default withNamespaces()(NewVehicleModal)