import React, { useState, useEffect  } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next';
import { getTyreLabels } from '../../api/tyres'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons'

function PrintLabelModal({show, vehicle, closeHook , tyres, t}) {
    const handleClose = (keepSelected) => closeHook(keepSelected);
    const reports = useSelector(store => store.Siteconfig).labelReports


    //set chosen report on component load
    useEffect(() => {
        if (localStorage.getItem('last_used_stocklabel_report')) {
            setChosenReport(localStorage.getItem('last_used_stocklabel_report'))
        } else {
            setChosenReport(reports ? reports[0].rpt_number[0] : undefined)
        }
    },[])

    //set chosen report on localstorage item change 
    useEffect(() => {
        if (localStorage.getItem('last_used_stocklabel_report')) {
            setChosenReport(localStorage.getItem('last_used_stocklabel_report'))
        }
        
    },[localStorage.getItem('last_used_stocklabel_report')])

    async function downloadPdf(e) {
        e.preventDefault()
        e.stopPropagation();
        setButtonDisabled(true)
        try {
            const base64data = await getTyreLabels(tyres,chosenReport)
            if (base64data && base64data.data !== '') {
                const linkSource = `data:application/pdf;base64,${base64data.data}`;
                const downloadLink = document.createElement("a");
                const fileName = `Tyre_labels_${vehicle?.license_plate[0]}.pdf`;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();

                //set last used report id to localstorage
                localStorage.setItem('last_used_stocklabel_report', chosenReport);

                handleClose(false)
                setButtonDisabled(false)
            } else {
                throw new Error(t('The PDF file is invalid.'))
            }

        } catch (e) {
            window.handleError(e)
            setButtonDisabled(false)
        }

    }

    //helper function to convert base64 to a blob
    const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
      
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
      }

    async function PrintPdf() {
        setButtonDisabled(true)
        try {
            const base64data = await getTyreLabels(tyres,chosenReport)
            if (base64data && base64data.data !== '') {   
 
                const blob = b64toBlob(base64data.data,'application/pdf')

                const blobUrl = URL.createObjectURL(blob);
                const iframe =  document.createElement('iframe'); //load content in an iframe to print later
                document.body.appendChild(iframe);

                iframe.style.display = 'none';
                iframe.src = blobUrl;
                iframe.onload = function() {
                  setTimeout(function() {
                    iframe.focus();
                    iframe.contentWindow.print();
                    handleClose(false)
                    setButtonDisabled(false)
                  }, 1);
                };

                //set last used report id to localstorage
                localStorage.setItem('last_used_stocklabel_report', chosenReport);
            } else {
                throw new Error(t('The PDF file is invalid.'))
            }

        } catch (e) {
            window.handleError(e)
            setButtonDisabled(false)
        }
    }

    const [ chosenReport, setChosenReport ] = useState(reports? reports[0].rpt_number : '')

    const [buttonDisabled, setButtonDisabled] = useState(false)

    return (
        <>
            <Modal show={show} onHide={() => handleClose(true)}>
                <Modal.Header closeButton>
                <Modal.Title>{t('Print tyre stock labels')}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={(e) => downloadPdf(e)}>
                    <Modal.Body>
                        <label>{t('License plate')}</label>
                        <p><b>{vehicle?.license_plate[0]}</b></p>
                        <label>{t('Tyres')}</label>
                        <ul>
                            {tyres?.map(tyre => <li key={tyre.db_id[0]}>{tyre.description[0]}</li>)}
                        </ul>
                        <label>{t('Select label type')}</label>
                        <Form.Select name="report_id" value={chosenReport} onChange={e => setChosenReport(e.target.value)}>
                            {reports?.map(report => <option key={report.rpt_number[0]} value={report.rpt_number[0]}>{report.rpt_name[0]}</option>)}
                        </Form.Select>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        <FontAwesomeIcon icon={fa.faTimes}/> {t('Close')}
                    </Button>
                    <Button disabled={buttonDisabled} type="submit" variant="outline-primary">
                        <FontAwesomeIcon icon={fa.faDownload}/> {t('Download labels')}
                    </Button>
                    <Button disabled={buttonDisabled} onClick={() => PrintPdf()} variant="primary">
                        <FontAwesomeIcon icon={fa.faPrint}/> {t('Print labels')}
                    </Button>
                    </Modal.Footer>
                </Form>

            </Modal>
        </>
    )
}

export default withNamespaces()(PrintLabelModal)
