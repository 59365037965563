const initialState =  {
    loaded : false,
    vehicles : []
}

export default (fleetState = initialState , action) => {
    switch (action.type) {
        case 'FLEET_LOAD':
            return {
                ...fleetState,
                loaded: true,
                vehicles : action.payload
            }
        case 'FLEET_ADD':
            return {
                ...fleetState,
                vehicles: [...fleetState.vehicles,action.payload]
            }
        case 'FLEET_CLEAR':
            return initialState;
        case 'FLEET_UPDATE':
            if (fleetState.vehicles.length > 0) {
                const copiedVehicles = [...fleetState.vehicles]
                const vehicleRecord = copiedVehicles.filter(vehicle => vehicle.flt_id[0] == action.payload.flt_id[0])[0]
                if (vehicleRecord) {
                    const index = copiedVehicles.indexOf(vehicleRecord)
                    copiedVehicles[index] = action.payload
                } else {
                    copiedVehicles.push(action.payload)
                }
                return {
                    ...fleetState,
                    vehicles : copiedVehicles
                }
            } else {
                return {...fleetState , vehicles : [action.payload] }
            }
        case 'FLEET_REMOVE':
            if (fleetState.vehicles.length > 0) {
                const copiedVehicles = [...fleetState.vehicles]
                copiedVehicles.forEach((vehicle,i) => {                    
                    if (vehicle.flt_id[0] == action.payload.flt_id) {
                        copiedVehicles.splice(i, 1);
                    }
                });
                return {
                    ...fleetState,
                    vehicles :copiedVehicles
                }
            }
            break;
        default:
            return fleetState;
    }
}