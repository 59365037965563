import React, { useEffect, useState } from 'react'
import {Row, Col, Badge, Button } from 'react-bootstrap'
import TyreLabelScanner from './tyreLabelScanner'
import { getAdminOperations, confirmScans } from '../../../api/hoteladmin'
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import ScanResult from './scanResult'
import { useSelector } from 'react-redux'

function Intakeform({t}) {

  const [label,setLabel] = useState(undefined)
  const hotelConfig = useSelector((store) => store.Siteconfig) 
  const userState = useSelector((store) => store.User) 
  const [scanError, setScanError] = useState(undefined)
  const [scanHistory, setScanHistory] = useState([])
  const [unmarkedTyres,setUnmarkedTyres] = useState([])

  const [operations,setOperations] = useState([])
  const [operationsLoaded, setOperationsLoaded] = useState(false)
  const [scanMode, setScanMode] = useState(undefined)

  useEffect(async () => {
    try {
      setOperations( (await getAdminOperations('submitted')).data )
      setOperationsLoaded(true)
    } catch (e) {
      window.handleError(e)
    }
  },[])

  const [qualifiedOperations, setQualifiedOperations] = useState([])

  useEffect(() => {
    if (scanMode) {
      const qualifiedOps = operations.filter(op => op.type === scanMode)
      qualifiedOps.forEach(operation => {
        operation.tyres.forEach(tyre => {
          const copiedOperation = {...operation}
          //delete operation tyres array to avoid circular reference
          delete copiedOperation.tyres
  
          copiedOperation.services = copiedOperation.services.map(serviceId => hotelConfig?.services?.filter(hotelService => hotelService._id === serviceId)[0])
          tyre["operation"] = copiedOperation
        })
      })
      setQualifiedOperations(qualifiedOps)
    }
  },[scanMode])


  let qualifiedTyres = []
  qualifiedOperations.forEach(operation => {
    operation.tyres.forEach(tyre => {
      tyre['license_plate'] = operation.vehicle?.license_plate[0]
      qualifiedTyres.push(tyre)
    })
  })

  function getTyrebyId(tyreId) {
    const foundTyre = qualifiedTyres.filter(tyre => parseFloat(tyre.db_id[0]) === tyreId)[0]
    console.log('foundTyre',foundTyre)
    if (foundTyre) {
      return foundTyre
    } else {
      return undefined
    }
  }

  useEffect(() => {
    if (label) {
      const tyre = getTyrebyId(label.tyreId)
      if (tyre) {
        const scannedTyre = {...tyre}
        //push to array of scan histories
        setScanHistory(scanHistory => ([{tyre : scannedTyre, operation: scannedTyre.operation, type : 'success', scanTime: new Date()}, ...scanHistory]))

        //push to array of tyres for marking (if it does not incldue the tyre id already)
        const unmarkedTyreIds = unmarkedTyres.map(unmarkedTyre => unmarkedTyre.db_id[0])
        if (!unmarkedTyreIds.includes(scannedTyre.db_id[0])) {
          setUnmarkedTyres(unmarkedTyres => ([scannedTyre, ...unmarkedTyres]))
        }
      } else {
        setScanHistory(scanHistory => [{error : t('Tyre not found'), label : label.tyreId, scanTime: new Date()},...scanHistory])
      }
    }
  },[label])


    //mark tyre(s) as scanned in DB
    async function markTyreScan(tyres) {
      try { 
        const markedTyres = (await confirmScans(tyres)).data
        //update to mark scan flag 
        markedTyres.forEach(markedTyre => {
          const copiedScanHistory = [...scanHistory]
          const relatedScanHistoryItems = copiedScanHistory.filter(scanHistoryItem => scanHistoryItem.tyre?.db_id[0] === markedTyre.db_id[0])
          //update tyre element of all related history items (history items containing same tyre id)
          relatedScanHistoryItems.forEach(relatedScanHistory => {
            const indexOfHistoryItem = scanHistory.indexOf(relatedScanHistory)
            copiedScanHistory[indexOfHistoryItem].tyre = markedTyre
          })
          //update scan history with responded data
          setScanHistory(copiedScanHistory)
          //remove tyre from unmarked tyres list
          setUnmarkedTyres(unmarkedTyres => unmarkedTyres.filter(unmarkedTyre => unmarkedTyre.db_id[0] !== markedTyre.db_id[0]))
        })
      } catch (e) {
        window.handleError(e)
      }
    }

  const [timer,setTimer] = useState(0)
  const timerReset = 10
  useEffect(() => {
    const increaseTimer = setInterval(() => {
      setTimer(timer => {
        if (timer < timerReset) {
          return timer + 1
        } else {
          return 0
        }
      })
      
    }, 1000)
    return () => clearInterval(increaseTimer);
  },[])

  useEffect(() => {
      //every n seconds --> check if there are tyres to be submitted
      if (unmarkedTyres.length > 0 && timer === timerReset) {
        markTyreScan(unmarkedTyres)
      }
  }, [timer]);

  useEffect(() => {
    if (scanError) {
      setScanHistory(scanHistory => [{error : scanError.message, label : scanError.label, scanTime: new Date()},...scanHistory])
    }
  },[scanError])

  function resetScan() {
    setScanMode(undefined)
    setLabel(undefined)
    setScanHistory([])
    setQualifiedOperations([])
    setUnmarkedTyres([])
  }
  return (
      operationsLoaded ? 
      <>
      {!scanMode ? <> 
      <Row className="g-2">
        <Col sm={12} ><p className="text-center">{t('Select scan mode')}</p></Col>
        <Col sm={12} lg={4}><Button onClick={() => setScanMode('to_customer')} variant="success" className="w-100" size="lg"><FontAwesomeIcon icon={fa.faUser}/> {t('To customer')}</Button></Col>
        <Col sm={12} lg={4}><Button onClick={() => setScanMode('to_stock')} variant="warning" className="w-100" size="lg"><FontAwesomeIcon icon={fa.faWarehouse}/> {t('To stock')}</Button></Col>
        <Col sm={12} lg={4}><Button onClick={() => setScanMode('to_waste')} variant="danger" className="w-100" size="lg"><FontAwesomeIcon icon={fa.faTrash}/> {t('To waste')}</Button></Col>
      </Row> </> : 
      <div className="col-sm-12 text-center">
        <TyreLabelScanner onScan={(data) => setLabel(data) } onError={error => setScanError(error)}/>
        <Button variant="outline-primary" onClick={() => resetScan()}><FontAwesomeIcon icon={fa.faChevronLeft}/> {t('Go back')}</Button>
        <p className="mt-2 text-center"><b>{t(`Scanning in "${scanMode}" mode`)}</b><br/><small>{qualifiedTyres.length} {t('tyres loaded')}</small></p>
        {scanHistory.length === 0 ? <Badge bg="success">{t('Ready to scan')}</Badge> : <>
        </>}
        {scanMode ? <div>
          {scanHistory.map((historyRecord,i) => <ScanResult key={i} lang={userState.lang_iso} index={i} historyRecord={historyRecord}/>)}
        </div> : '' }
        
      </div>}


    </> :
    t('Loading operations...')
  )
}

export default withNamespaces()(Intakeform)