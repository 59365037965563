import React, {useState,useEffect} from 'react'
import DataTable from 'react-data-table-component';
import { Col, Row, InputGroup, Form, Button} from 'react-bootstrap'
import { withNamespaces } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select';
import selectStyle from './selectStyle';
import moment from 'moment'
import { useNavigate } from 'react-router-dom';
import { adminOpsByDate } from '../../../../api/hoteladmin'
import { useSelector } from 'react-redux'
import OperationTypeLabel from '../../../common/operationTypeLabel';
import VehicleIcon from '../../../common/vehicleIcon'
import TyreTable from '../../../tyres/tyreTable'

function CompletedOperationsView({t,operationCount}) {

    const hotelConfig = useSelector((store) => store.Siteconfig) 
    const userState = useSelector((store) => store.User)  

    const [completedOps,setCompletedOps] = useState([])
    const [filteredCompletedOps, setFilteredCompletedOps] = useState([])
    const [customerSelectValues,setCustomerSelectValues] = useState([])

    useEffect(() => {
            if (filteredCompletedOps.length > 0) {
                const customerNames = completedOps?.map(op => op.user.companyname).filter((value,index,self) => self.indexOf(value) === index)
                setCustomerSelectValues(customerNames?.map(name => { return { value: name, label: name } } ))
            }
        }
    , [filteredCompletedOps])

    const [customerFilter,setCustomerFilter] = useState(undefined)
    const [fromDate,setFromDate] = useState(moment().subtract(1,'month').format('YYYY-MM-DD'))
    const [tillDate,setTillDate] = useState(moment().format('YYYY-MM-DD'))
    const [dateButtonDisabled, setDateButtonDisabled] = useState(false)

    const navigate = useNavigate()
    function goToVehicle(id) {
        navigate('/fleet/vehicle', {state: { id: id}})
    }


    const tableColumns = [
        { name: t('Status'), selector: operation => {
            let statusLabel
            if (operation.status === 'cancelled') {
                statusLabel = <><FontAwesomeIcon icon={fa.faTimes} className="text-danger"/> {t('Cancelled')}</>
            } else if (operation.status === 'confirmed') {
                statusLabel = <><FontAwesomeIcon icon={fa.faCheck} className="text-success"/> {t('Confirmed')}</>
            }
            return statusLabel }
        },
        { name: t('Customer'), selector: operation => operation.user.companyname },
        { name: t('Operation'), selector: operation => <OperationTypeLabel type={operation.type}/> },
        { name: t('Vehicle'), selector: operation => <b className="mt-2 text-primary pointer" onClick={() => goToVehicle(operation.flt_id)}>
            <VehicleIcon type={operation.vehicle.type[0]}/> {operation.vehicle.license_plate[0]}
        </b>},
        { name: t('Tyres'), selector: operation => {
            const scannedTyres = operation.tyres.filter(tyre => tyre.scanned)
            return <span className={scannedTyres.length === operation.tyres.length ? 'text-success' : 'text-primary'}><FontAwesomeIcon icon={fa.faBarcode}/> {scannedTyres.length} / {operation.tyres.length}</span>
        }},
        { name: t('Services'), selector: operation => {
                const services = operation?.services?.map(operationService => hotelConfig?.services?.filter(hotelService => hotelService._id === operationService)[0])
                return services.map((service,i) => <span key={i}>{i ? <><br/>{service ? service[`description_${userState.lang_iso}`] : <span className="opacity-50">{t('Unknown')}</span>}</> : service[`description_${userState.lang_iso}`]} </span>)
            }
        },
        { name: t('Recall date'), selector: operation => operation.deliveryDate ? moment(operation.deliveryDate).format('DD/MM/YYYY') : ''},
        { name: t('Transport'), selector: operation => {
            const transport = hotelConfig?.transportMethods?.filter(transportMethod => transportMethod._id === operation.transport)[0]
            return transport ? transport[`description_${userState.lang_iso}`] : ''}
        }, 
        { name: t('Handled on'), selector: operation =>  <small className="opacity-50">{operation.handledAt ? moment(operation.handledAt).format('DD/MM/YYYY HH:mm:ss') : ''}</small>} 
    ]


    useEffect(() => {
      let filteredOps = [...completedOps]
      if (customerFilter) {
        filteredOps = filteredOps.filter(op => op.user.companyname.toUpperCase().includes(customerFilter.toUpperCase()))
      }
      setFilteredCompletedOps(filteredOps)
    }, [completedOps, customerFilter])
    
    const paginationComponentOptions = {
        rowsPerPageText: t('Actions per page'),
        rangeSeparatorText: t('of'),
    };

    const tableStyles = {
        head : {
            style: {
                fontSize: '16px', // override the row height
            }
        },
        cells : {
            style: {
                fontSize: '16px', // override the row height
                cursor: 'pointer'
            }
        }
    }

    async function fetchHistoric() {
      setDateButtonDisabled(true)
      try {
        const completedOperationsBydate = (await adminOpsByDate (moment(fromDate,'YYYY-MM-DD'),moment(tillDate, 'YYYY-MM-DD')))?.data
        setCompletedOps(completedOperationsBydate.sort((a,b) => { return new Date(b.handledAt) - new Date(a.handledAt)}))

        if (operationCount) operationCount(completedOperationsBydate.length)
      } catch (e) {
        window.handleError(e)
      }
      setDateButtonDisabled(false)
        
    }

  return (
    <>
        <Row className="mt-3 mb-2">
            <Col md={3}>
              <InputGroup>
                <InputGroup.Text><FontAwesomeIcon icon={fa.faUser}/></InputGroup.Text>
                <div className="form-control p-0">
                    <Select styles={selectStyle} isClearable options={customerSelectValues} onChange={(e) => e ? setCustomerFilter(e.value) : setCustomerFilter('')} placeholder={t('Customer filter...')}/>
                </div>
                </InputGroup>
            </Col>
            <Col md={3}>
              <InputGroup>
              <InputGroup.Text><FontAwesomeIcon icon={fa.faCalendar}/></InputGroup.Text>
                <Form.Control value={fromDate} onChange={(e) => setFromDate(e.target.value)} type="date"></Form.Control>
                </InputGroup>
              </Col>
            <Col md={3}>
                <InputGroup>
                <InputGroup.Text><FontAwesomeIcon icon={fa.faCalendar}/></InputGroup.Text>
                <Form.Control value={tillDate} onChange={(e) => setTillDate(e.target.value)} type="date"></Form.Control>
                </InputGroup>
              </Col>
                
            <Col md={3}>
                <Button className="w-100 " disabled={dateButtonDisabled} onClick={ () => fetchHistoric()}>
                    {dateButtonDisabled ? <><FontAwesomeIcon className="spinner" icon={fa.faSpinner}/> {t('Loading')}...</> : <><FontAwesomeIcon icon={fa.faPaperPlane}/> {t('Fetch')}</> }</Button>
            </Col>
        </Row>
        <hr/>
        { filteredCompletedOps?.length > 0 ? <>
            <DataTable
                noDataComponent = {<p className="pt-3 pb-2">{t('No completed operations.')}</p>}
                paginationComponentOptions={paginationComponentOptions} 
                customStyles={tableStyles}
                columns={tableColumns}
                data={filteredCompletedOps}
                highlightOnHover={true}
                pagination={true}
                striped={true}
                expandableRows
                expandOnRowClicked={true}
                expandableRowsComponent = {(row) => <div className="p-2"><TyreTable showDepth={hotelConfig.allowProfileDepth} tyres={row.data.tyres}/></div>}
            />
        </> :
        <p className="text-center opacity-50 mt-3">{t('No completed operations')}</p> }
    </> 
  )
}

export default withNamespaces()(CompletedOperationsView)