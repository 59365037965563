import React, { useEffect, useState } from 'react'
import { loadFleetAndTyres } from '../../actions/vehicles'
import { Form, Table, Button, Row, Col, Tabs, Tab, Card, Badge } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component'
import Select from 'react-select'
import moment from 'moment';
import VehicleIcon from '../common/vehicleIcon'
import TyreStatusLabel from '../common/tyreStatusLabel'
import TyreStockDetailsModal from '../common/TyreStockDetailsModal';
import TyreStockDetailButton from '../common/TyreStockDetailButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TyreTable from './tyreTable';
import * as fa from '@fortawesome/free-solid-svg-icons'
import TyreDetailIcons from '../common/tyreDetailIcons'

function TyresOverview({ t }) {

    const loaderCell = (width) => {  
    
        const cellWidth = width ? (width + 'px') : '100%'
        return <ContentLoader 
            speed={2}
            width={width}
            height={25}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb">
            <rect x="0" y="0" rx="5" ry="5" width={cellWidth} height="100%" />
        </ContentLoader>
    }

    const RowLoader = function(i) {
    return <tr key={i}>
        <td>{loaderCell(150)}</td>
        <td>{loaderCell(50)}</td>
        <td>{loaderCell(100)}</td>
        <td>{loaderCell(500)}</td>
        <td>{loaderCell(50)}</td>
        <td>{loaderCell(100)}</td>
        <td>{loaderCell(75)}</td>
    </tr>
    }

    const RowPlaceholders = []
    for (let i = 0; i < 11; i++) {
        RowPlaceholders.push(RowLoader(i))
    }

    const TablePlaceHolder = function() {
        let rowPlaceHolders = []
        for (let i = 0; i < 11; i++) {
            rowPlaceHolders.push(RowLoader(i))
        }

        return <Table>
            <tbody>{rowPlaceHolders.map((x) => x)}</tbody>
        </Table>
    }

    const navigate = useNavigate()

    function goToVehicle(id) {
        navigate('/fleet/vehicle', {state: { id: id}})
    }
      
    const fleetState = useSelector((store) => store.Fleet)
    const hotelConfig = useSelector((store) => store.Siteconfig)
    const dispatch = useDispatch()
    useEffect(() => {
      if (!fleetState || !fleetState.loaded) {
        dispatch(loadFleetAndTyres())
      }
    }, [])

    const tableStyles = {
        head : {
            style: {
                fontSize: '16px', // override the row height
            }
        },
        cells : {
            style: {
                fontSize: '16px', // override the row height
                cursor: 'pointer'
            }
        }
    }

    const [tyreSets,setTyreSets] = useState([])

    const individualTableColumns = [
        { name: t('Plate'), maxWidth: '150px', selector: row => row.license_plate_w_icon },
        { name: t('Position'), maxWidth: '50px', selector: row => row.position },
        { name: t('Status'), maxWidth: '135px', selector: row => row.tyre_status },
        { name: t('Description'), selector: row => row.description },
        { name: t('Status date'), sortable: true, maxWidth: '175px', selector: row => row.status_date, format: row => moment(row.status_date,'YYYYMMDD').format('DD/MM/YYYY') },
    ];

    const setTableColumns = [
        { name: t('Plate'), maxWidth: '150px', selector: row => row.license_plate_w_icon },
        { name: t('Tyres'), maxWidth: '150px', selector: row => row.tyres_label },
        { name: t('Status'), maxWidth: '150px', selector: row => row.tyre_status },
        { name: t('Set name'), selector: row => row.set_name },
    ];

    const siteConfigState = useSelector((store) => store.Siteconfig)

    const [ statusFilter, setStatusFilter ] = useState(['in_stock'])
    const [ stringFilter, setStringFilter ] = useState('')

    const [ filteredRows, setFilteredRows ] = useState([])
    
    useEffect(() => {
        setFilteredRows(filterTyres())
    },[fleetState])

    

    function filterTyres() {

        const tyres = fleetState.loaded ? fleetState.vehicles.filter(vehicle => vehicle.tyres[0]).flatMap(vehicle => vehicle.tyres[0].tyre) : []

        return tyres.filter(tyre => {
            const tyre_description = tyre.tyre_memo ? tyre.tyre_memo[0] : tyre.description[0]
    
            //always hide waste tyres here
            if (tyre.tyre_status[0] === 'waste') {
                return false
            }
    
            if ((statusFilter.includes(tyre?.tyre_status[0]) || statusFilter.length === 0) && (
                tyre_description.toUpperCase().includes(stringFilter.toUpperCase()) ||
                tyre.license_plate[0].toUpperCase().includes(stringFilter.toUpperCase())
            )) {
    
                //filter out all "at_customer" tyres if it is not allowed in shop config
                if (!siteConfigState.showTyresOnVehicle && tyre.tyre_status[0] === 'at_customer') {
                    return false
                }
    
                //filter out all "at_customer" tyres if it is not allowed in shop config
                if (!siteConfigState.showTyresAtCustomer && tyre.tyre_status[0] === 'at_customer') {
                    return false
                }
    
                //filter out all "at_customer" tyres if it is not allowed in shop config
                if (!siteConfigState.showWasteTyres && tyre.tyre_status[0] === 'waste') {
                    return false
                }
    
                return true
                
            }
        })
    }

    useEffect(() => {
        if (statusFilter || stringFilter) {
            const inputDelay = setTimeout(() => {
                setFilteredRows(filterTyres())
              }, 200)
              return () => clearTimeout(inputDelay) 
        }
        
    }, [statusFilter, stringFilter])

    const individualTableRows = filteredRows.map(tyre => {

        function generateTableRow() {
            return { 
                id : tyre.db_id[0],
                art_id : tyre.art_id[0],
                flt_id : tyre.flt_id[0],
                license_plate : tyre.license_plate[0],
                license_plate_w_icon: <><VehicleIcon type={tyre.vehicle_type[0]}/> {tyre.license_plate[0]}</>,
                description: tyre.tyre_memo ? <><TyreDetailIcons tyre={tyre}/> {tyre.tyre_memo[0]}</> : <><TyreDetailIcons tyre={tyre}/> {tyre.description[0]}</>,
                //profile_depth : tyre.profile_depth[0],
                status_date : tyre.status_date[0],
                position: tyre.position[0],
                tyre_status : (tyre.tyre_status[0] === 'in_stock' && siteConfigState.allowDetailedStockReport) ? 
                                <><TyreStatusLabel status={tyre.tyre_status[0]}/> <TyreStockDetailButton onClick={() => showStockModal(tyre)}/></> 
                                : <TyreStatusLabel status={tyre.tyre_status[0]}/>
            }
        }
        if (statusFilter.length > 0) {
            if (statusFilter.includes(tyre.tyre_status[0])) {
                return generateTableRow()
            } else {
                return false
            }
        } else {

            return generateTableRow()
        }
    })

    const setTableRows = tyreSets.map(set => {

        function generateTableRow() {
            return { 
                flt_id : set.flt_id,
                set_name : set.name === 'setless' ? '' : set.name,
                license_plate : set.license_plate,
                license_plate_w_icon: <span className="text-primary pointer" onClick={() => goToVehicle(set.flt_id)}><VehicleIcon type={set.vehicle_type}/> {set.license_plate}</span>,
                tyres : set.tyres,
                tyres_label : <>{set.tyres.length} {t('tyre(s)')}</>,
                tyre_status : <TyreStatusLabel status={set.tyre_status}/>
            }
        }
        if (statusFilter.length > 0) {
            if (statusFilter.includes(set.tyre_status)) {
                return generateTableRow()
            } else {
                return false
            }
        } else { 
            return generateTableRow()
        }
    })

    const filterStatusOptions = [
        { value: 'in_stock', isSelected: true, label: <TyreStatusLabel status="in_stock"/> },
        /*{ value: 'on_spare', label: <TyreStatusLabel status="on_spare"/>}*/
      ]

      if (siteConfigState.showTyresAtCustomer) {
        filterStatusOptions.push({ value: 'at_customer', label: <TyreStatusLabel status="at_customer"/>})
      }

      if (siteConfigState.showWasteTyres) {
          /*filterStatusOptions.push({ value: 'waste', label: <TyreStatusLabel status="waste"/> })*/
      }

      const dropDownStyle = {
        multiValueLabel : (provided) => ({
            ...provided,
            padding : 0,
            margin: 0,
            borderRadius: 0,
            backgroundColor: 'transparent',
            fontSize: '100%',
            paddingLeft : 0,
        }),
        multiValue: (provided) => ({
            ...provided,
            background: "transparent",
        }),
        multiValueRemove: () => ({
            backgroundColor: 'transparent'
        })
      }

      function filterStatus(values) {
          let statusses = values.map(elem => elem.value)
          setStatusFilter(statusses)
      }

      const [stockDetailTyre, setStockDetailTyre] = useState(undefined)

    async function showStockModal(tyre) {
        await setStockDetailTyre(undefined)
        setStockDetailTyre(tyre)
    } 

    function copyToClipboard() {
        let copyString = `${t('License plate')}\t${'Tyre set'}\t${t('Position')}\t${t('Description')}\t${t('Status')}\t${t('Status date')}\t`
        if (hotelConfig.allowProfileDepth) {
            copyString += `${t('Profile depth')}\t${t('Profile date')}\t`
        }
        copyString += `\n`
        try {
            filteredRows.forEach(row => {
                copyString += `${row.license_plate[0]}\t`
                copyString += `${row.tyre_set ? row.tyre_set[0] : ''}\t`
                copyString += `${row.position[0]}\t`
                copyString += `${row.description[0]}\t`
                copyString += `${row.tyre_status[0]}\t`
                copyString += `${row.status_date ? moment(row.status_date[0],'YYYYMMDD').format('DD/MM/YYYY') : ''}\t`
                if (hotelConfig.allowProfileDepth) {
                    copyString += `${row.profile_depth ? row.profile_depth[0].replace('.',',') : ''}\t`
                    copyString += `${row.profile_date ? moment(row.profile_date[0],'YYYYMMDD').format('DD/MM/YYYY') : ''}\t`
                }
                copyString += `\n`
            })
            navigator.clipboard.writeText(copyString)
            window.notify('success',t('Data copied to clipboard'))
        } catch (e) {
            window.handleError(e)
        }
    }

    function downloadData() {
        let downloadString = `sep=,\n`
         downloadString += `${t('License plate')},${'Tyre set'}, ${t('Position')},${t('Description')},${t('Status')},${t('Status date')}`
        if (hotelConfig.allowProfileDepth) {
            downloadString += `,${t('Profile depth')},${t('Profile date')}`
        }
        downloadString += `\n`
        try {
            filteredRows.forEach(row => {
                downloadString += `${row.license_plate[0]},`
                downloadString += `${row.tyre_set ? row.tyre_set[0] : ''},`
                downloadString += `${row.position[0]},`
                downloadString += `${row.description[0]},`
                downloadString += `${row.tyre_status[0]},`
                downloadString += `${row.status_date ? moment(row.status_date[0],'YYYYMMDD').format('DD/MM/YYYY') : ''}`

                if (hotelConfig.allowProfileDepth) {
                    downloadString += `,${row.profile_depth ? row.profile_depth[0] : ''},`
                    downloadString += `${row.profile_date ? moment(row.profile_date[0],'YYYYMMDD').format('DD/MM/YYYY') : ''}`
                }
                downloadString += `\n`
            })
            let csvContent = "data:text/csv;charset=utf-8," + downloadString
            let encodedUri = encodeURI(csvContent);
            let link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", t('Exported tyres') + '.csv');
            document.body.appendChild(link);
            link.click();
            window.notify('success',t('Data downloaded as CSV'))
        } catch (e) {
            window.handleError(e)
        }
    }

    useEffect(() => {
        let tempTyreSets = []
        filteredRows.forEach(row => {
            //lookup vehicle
            if (!row.tyre_set) { row.tyre_set = ['setless']}
            const foundSet = tempTyreSets.filter(set => set.flt_id === row.flt_id[0] && set.name === row.tyre_set[0] && set.tyre_status === row.tyre_status[0])[0]
            if (foundSet) {
                foundSet.tyres.push(row)
            } else {
                tempTyreSets.push({
                    name : row.tyre_set[0],
                    flt_id : row.flt_id[0],
                    tyre_status : row.tyre_status[0],
                    license_plate : row.license_plate[0],
                    vehicle_type : row.vehicle_type[0],
                    tyres: [row] 
                })
            }
        })
        setTyreSets(tempTyreSets)
    },[filteredRows])

    const paginationComponentOptions_sets = {
        rowsPerPageText: t('Sets per page'),
        rangeSeparatorText: t('of'),
    };

    const paginationComponentOptions_tyres = {
        rowsPerPageText: t('Tyres per page'),
        rangeSeparatorText: t('of'),
    };

    return (
        <div>
        <h1>{t('Tyre overview')}</h1>
            {fleetState.loaded ? <>
                <TyreStockDetailsModal tyre={stockDetailTyre}/>
                <Row className="g-1">
                    <Col md={7}>
                        <Form.Control onChange={e => setStringFilter(e.target.value)} placeholder={t('Filter tyres')}></Form.Control>
                    </Col>
                    <Col md={3}>
                        <Select 
                            noOptionsMessage={() => t('All statuses are shown')}
                            closeMenuOnSelect={true}
                            placeholder={t('All statuses are shown')} 
                            onChange={(e) => { filterStatus(e) }} 
                            styles={dropDownStyle} isMulti={true} 
                            options={filterStatusOptions}
                            value={filterStatusOptions.filter(o => statusFilter.includes(o.value))}
                        />
                    </Col>
                    <Col md={1}>
                        <Button className="w-100" onClick={() => copyToClipboard()} variant="outline-secondary"><FontAwesomeIcon icon={fa.faCopy}/> {t('Copy')}</Button>
                    </Col>
                    <Col md={1}>
                        <Button className="w-100" onClick={() => downloadData()} variant="outline-secondary"><FontAwesomeIcon icon={fa.faFileExport}/> {t('Export')}</Button>
                    </Col>
                </Row>
                <Tabs className="mb-0 mt-2" defaultActiveKey="tyresets" id="tyre-tabs">
                <Tab eventKey="tyresets" title={<>{t('Tyre sets')} <Badge>{tyreSets.length}</Badge></>}>
                        <Card className="table-responsive border-top-0"><Card.Body>
                            <DataTable
                                responsive={true}
                                customStyles={tableStyles}
                                columns={setTableColumns}
                                data={setTableRows}
                                expandableRows
                                expandOnRowClicked={true}
                                paginationComponentOptions={paginationComponentOptions_sets}
                                noDataComponent = {<p className="pt-3 pb-2">{t('No tyre sets to display.')}</p>}
                                expandableRowsComponent = {(row) => <div className="p-2"><TyreTable showDepth={hotelConfig.allowProfileDepth} tyres={row.data.tyres}/></div>}
                                highlightOnHover={true}
                                pagination={true}
                                striped={true}
                            />
                            </Card.Body>
                        </Card>
                    </Tab>
                    <Tab eventKey="individual" title={<>{t('Individual tyres')} <Badge>{filteredRows.length}</Badge></>}>
                        <Card className="table-responsive border-top-0"><Card.Body>
                            <DataTable
                                responsive={true}
                                customStyles={tableStyles}
                                columns={individualTableColumns}
                                data={individualTableRows}
                                highlightOnHover={true}
                                pagination={true}
                                noDataComponent = {<p className="pt-3 pb-2">{t('No tyres to display.')}</p>}
                                paginationComponentOptions={paginationComponentOptions_tyres}
                                striped={true}
                                onRowClicked={(row) => goToVehicle(row.flt_id)}
                            />
                            </Card.Body>
                        </Card>
                    </Tab>
                </Tabs>
            </> : <TablePlaceHolder/>}
        </div>
    )
}

export default withNamespaces()(TyresOverview)
