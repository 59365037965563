import React, { useState, useEffect } from 'react'
import { Table, Row, Col, InputGroup } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next';
import SubmittedOperation from '../submittedOperation'
import * as fa from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OperationsToClipboardButton from './operationsToClipboardButton';
import PrintOperationsButton from './printOperationsButton';
import Select from 'react-select';
import selectStyle from './selectStyle';

function StorageTab({t,operationUpdated,storageOps,theadStyle,tbodyStyle}) {


    const [storageCustomer,setStorageCustomer] = useState(undefined)
    const [filteredOps,setFilteredOps] = useState(storageOps) 

    useEffect(() => { 
        const inputDelay = setTimeout(() => {
            setFilteredOps(storageOps.filter(op => { 
                const customerCheck = storageCustomer ? (new RegExp(storageCustomer,"i")).test(op.user.companyname) : true
                return (customerCheck)
            }))
          }, 200)
          return () => clearTimeout(inputDelay) 
    },[storageOps,storageCustomer,operationUpdated])

    const customerNames = storageOps.map(op => op.user.companyname).filter((value,index,self) => self.indexOf(value) === index)
    const customerSelectValues = customerNames.map(name => { return { value: name, label: name } } )


  return (
    <>
    { storageOps.length > 0 ? <>
        <hr className="mt-1"/>
        <Row className="mt-2 mb-2 g-2">
            <Col lg={8} sm={12}>
            <InputGroup>
                <InputGroup.Text><FontAwesomeIcon icon={fa.faUser}/></InputGroup.Text>
                <div className="form-control p-0">
                    <Select styles={selectStyle} isClearable options={customerSelectValues} onChange={(e) => e ? setStorageCustomer(e.value) : setStorageCustomer('')} placeholder={t('Customer filter...')}/>
                </div>
                
            </InputGroup>
            </Col>
            <Col lg={2} sm={6}><OperationsToClipboardButton operations={filteredOps} className="w-100"/></Col>
            <Col lg={2} sm={6}><PrintOperationsButton type="storage" operations={filteredOps} printServices className="w-100"/></Col>
        </Row>
        <hr/>
        <Table responsive={true} className="bg-white table table-hover">
        <thead style={theadStyle}>
            <tr>
            <th>{t('Customer')}</th>
            <th>{t('Operation')}</th>
            <th>{t('Vehicle')}</th>
            <th>{t('Tyres')}</th>
            <th>{t('Services')}</th>
            <th>{t('Submission')}</th>
            </tr>
        </thead>
        <tbody style={tbodyStyle}>
            {filteredOps.map(operation => <SubmittedOperation key={operation._id} hideTransport={true} hideDate={true} operation={operation} onUpdate={() => {operationUpdated(operation._id)}}  />)}
        </tbody> 
        </Table></> : <p className="text-center opacity-50 mt-2">{t('No submitted storage operations.')}</p>}
    </>
)
}

export default withNamespaces()(StorageTab)