import * as api from '../api/general'

export const gsc = () => async(dispatch) => {
    const { data } = await api.gsc();
    dispatch({ type: 'SITECONFIG_SET', payload: data})
}

export const getTsConfigData = () => async(dispatch) => {
    try {
        const { data } = await api.getTsConfigData();
        dispatch({ type: 'SITECONFIG_ADD_ELEMENTS', payload: data})

    } catch (error) {
        window.handleError(error)
    }
    
}