import React, { useState, useEffect } from 'react'
import { newConfig } from '../../api/superuser'
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'

function NewConfigModal(props) {

    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({})
    const [configUrls, setConfigUrls] = useState([])
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await newConfig(formData)
            response?.data?.message? window.notify('success', response.data.message) : window.notify('success', "Action performed successfully.")
            setShow(false)
            props.fetchConfigs()
        } catch (e) {
            window.handleError(e)
        }
    }

    const handleChange = (e) => {
        const copiedData = {...formData}
        if (e.target.files) {
            copiedData[e.target.name] = e.target.files[0]
        } else {
            copiedData[e.target.name] = e.target.value
        }
        setFormData(copiedData)
    }

    function addUrl() {
        const copiedUrls = [...configUrls]
        copiedUrls.push("")
        setConfigUrls(copiedUrls)
    }

    function removeUrl(index) {
        let urls = [...configUrls]
        urls.splice(index,1)
        setConfigUrls(urls)
    }

    function updateUrl(e,index) {
        const { value } = e.target;
        let urls = [...configUrls]
        urls[index] = value
        setConfigUrls(urls)
    }

    useEffect(() => {
        const copiedData = {...formData}
        copiedData["urls"] = configUrls
        setFormData(copiedData)
    },[configUrls])

    return (
        <>
          <Button variant="success" onClick={handleShow}>
          <FontAwesomeIcon icon={fa.faPlus}/> New configuration
          </Button>
    
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add new hotel configuration</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
            <Modal.Body>
                <Form.Group className="">
                    <Form.Label>Website name</Form.Label>
                    <Form.Control autoComplete="off" type="text" name="name" onChange={handleChange} required />
                </Form.Group>
                <Form.Group className="mt-2">
                    <Form.Label>Landing Logo</Form.Label>
                    <Form.Control name="landinglogo" type="file" onChange={handleChange} required />
                </Form.Group>
                <Form.Group className="mt-2">
                    <Form.Label>Navbar Logo</Form.Label>
                    <Form.Control name="navlogo" type="file" onChange={handleChange} required />
                </Form.Group>
                <Form.Group className="mt-2">
                    <Form.Label>Favicon</Form.Label>
                    <Form.Control name="favicon" type="file" onChange={handleChange} required />
                </Form.Group>
                <Form.Group className="mt-2">
                    <Form.Label>Webstream Account</Form.Label>
                    <Form.Control autoComplete="off" type="text" name="webstreamAccount" onChange={handleChange} required />
                </Form.Group>
                <Form.Group  className="mt-2">
                    <Form.Label >Administrator email</Form.Label>
                    <Form.Control autoComplete="off" type="email" name="adminEmail" onChange={handleChange} required />
                </Form.Group>
                <Form.Group className="mt-2">
                        <Form.Label>Website URL(s)</Form.Label> <Button size="sm" variant="outline-primary"  onClick={() => {addUrl()}}><FontAwesomeIcon icon={fa.faPlus}/> Add new</Button>
                       
                        {configUrls.map((url,i) => {
                            return <div key={i} className="d-flex mt-1">
                                <Form.Control className="" autoComplete="off" type="text" value={url} onChange={(e) => updateUrl(e,i)} required /> &nbsp;
                                <Button variant="outline-danger" className="ml-2" onClick={() => {removeUrl(i)}}><FontAwesomeIcon icon={fa.faTrashAlt}/></Button>
                            </div>
                        })}
                       
                        
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button type="submit" variant="primary">
                Save Changes
              </Button>
            </Modal.Footer>
            </Form>
          </Modal>
        </>
      );
}

export default NewConfigModal
