import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux';
import { Button, Form, Card, Row, Col } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next';
import { updateOperation, deleteOperation } from '../../actions/operations';
import VehicleIcon from '../common/vehicleIcon'
import { useNavigate } from 'react-router-dom'
import PrintLabelModal from '../tyres/printLabelModal'
import Select from 'react-select';
import moment from 'moment'

import "react-datepicker/dist/react-datepicker.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons';
import TyreTable from '../tyres/tyreTable';

function Storage({operation ,operationSubmitted, operationDeleted, hideDeleteAlert, printLabels, tyresExpanded, t}) {
    const fleetState = useSelector((store) => store.Fleet)
    const vehicles = fleetState.vehicles
    const dispatch = useDispatch()
    const vehicle = vehicles.filter(v => v.flt_id[0] == operation.flt_id)[0]
    const userState = useSelector((store) => store.User)   
    const hotelConfig = useSelector((store) => store.Siteconfig) 
    const [toggleDetails, setToggleDetails] = useState(tyresExpanded || hotelConfig.allowProfileDepth && operation.status === 'pending')
    const [remarks,setRemarks] = useState(operation.remarks)

    const [printLabelsProcess, setPrintLabelsProcess] = useState(printLabels ? true : false)
    const [showPrintModal,setShowPrintModal] = useState(false)
    async function changeOperationValue(fieldName, value) {
        const copiedOperation = {...operation}
        copiedOperation[fieldName] = value
        dispatch(updateOperation(copiedOperation,true))
    }

    const [tyreDepths, setTyreDepths] = useState(undefined)

    const [loading, setLoading] = useState(false)
    async function submitOperation(e,labelsPrinted) {
        e?.preventDefault()
        setLoading(true)

        //check if labels need to be printed
        if (printLabelsProcess && !labelsPrinted) {
            setShowPrintModal(true)
            //exit form without submitting to allow printing modal to close first
            return true
        }
        const copiedOperation = {...operation}

        if (setTyreDepths) {
            Object.keys(tyreDepths).forEach(tyreId => {
                copiedOperation.tyres.forEach(tyre => {
                    if (tyreId === tyre.db_id[0]) {
                        tyre["profile_depth"] = [tyreDepths[tyreId]]
                        tyre["profile_date"] = [moment().format('YYYYMMDD')]
                        if (tachometer) {
                            tyre["profile_tacho"] = [tachometer]
                        }
                        
                    }
                })
            })
        }


        copiedOperation["status"] = "submitted"
        await dispatch(updateOperation(copiedOperation))
        if (operationSubmitted) { operationSubmitted() }
        setLoading(false)
    }

    function handlePrintModalClose() {
        setShowPrintModal(false);
        if (printLabelsProcess) {
            submitOperation(undefined,true)
        }
    }

    /*async function undoSubmit() {
        setLoading(true)
        const copiedOperation = {...operation}
        copiedOperation["status"] = "pending"
        await dispatch(updateOperation(copiedOperation))
        if (operationSubmitted) { operationSubmitted() }
        setLoading(false)
    }*/

    async function handleDelete() {
        setLoading(true)
        await dispatch(deleteOperation(operation, hideDeleteAlert))
        if (operationDeleted) { operationDeleted() }
        setLoading(false)
    } 

    const navigate = useNavigate()
    function goToVehicle(id) {
        navigate('/fleet/vehicle', {state: { id: id}})
    }

    //SERVICE MANAGEMENT SEGMENT

    const [operationServices,setOperationServices] = useState(
        operation.services.map(serviceId => hotelConfig.services.filter(service => service._id === serviceId)[0])
    )

    const [availableServices, setAvailableServices] = useState(
        hotelConfig.services.filter(service => service.operation_type === operation.type && !operation.services.includes(service._id))
    )
    
    useEffect(() => {
        //on operation change --> reset operation linked services
        setOperationServices(operation.services.map(serviceId => hotelConfig.services.filter(service => service._id === serviceId)[0]))
        //on operation change --< reset operation available services
        setAvailableServices(hotelConfig.services.filter(service => service.operation_type === operation.type && !operation.services.includes(service._id)))
    },[operation])

    async function removeService(service) {
        const copiedOperation = {...operation}
        const indexToRemove = copiedOperation.services.indexOf(service._id)
        if (indexToRemove > -1) {
            copiedOperation.services.splice(indexToRemove,1)
        }
        setLoading(true)
        await dispatch(updateOperation(copiedOperation,true))
        setLoading(false)
    }

    async function addService(serviceToAdd) {
        if (serviceToAdd) {
            const copiedOperation = {...operation}
            copiedOperation.services?.push(serviceToAdd)
            setLoading(true)
            await dispatch(updateOperation(copiedOperation,true))
            setLoading(false)
            setShowAddServiceSelect(false)
        } else {
            setShowAddServiceSelect(false)
        }

    }

    function addServiceForm() {

        const options = availableServices.map(service => { return { value : service._id, label : <><FontAwesomeIcon icon={fa[service.icon]}></FontAwesomeIcon> {service[`description_${userState.lang_iso}`]}</>} })

        return  <Row className="gx-2">
        <Col xs={8}>
            <Select onChange={e => {addService(e.value);}} options={options}/>  
        </Col>
        <Col>
            <Button size="sm" disabled={loading} variant="outline-danger" onClick={() => setShowAddServiceSelect(false)} className="mt-1"><FontAwesomeIcon icon={fa.faTimes}/></Button>
        </Col>
    </Row>
    }

    const [showAddServiceSelect,setShowAddServiceSelect] = useState(false)

    const [tachometer,setTachometer] = useState(operation.tachometer)

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

    return (
        <>
            <PrintLabelModal show={showPrintModal} vehicle={vehicle} closeHook={() => {handlePrintModalClose()}} tyres={operation.tyres}/>
            <Form onSubmit={e => submitOperation(e)}>
                
                <Card className="mt-1 pt-2">
                    <Card.Body as={Row} className="pt-2 pb-2">
                        <Col lg={1}>
                            <small className="opacity-50">{t('License plate')}</small>
                            { vehicle ? 
                                <p className="mt-1 text-primary pointer" onClick={() => goToVehicle(vehicle.flt_id[0])}>
                                    <VehicleIcon type={vehicle.type[0]}/> {vehicle?.license_plate[0]}
                                </p>
                                : <p className="mt-1 opacity-50">
                                    <VehicleIcon type={operation.vehicle?.type[0]}/>  {operation.vehicle?.license_plate}
                                </p>
                            }                        
                        </Col>
                        <Col lg={1}>
                            <small className="opacity-50">{t('Tyres')}</small>
                            <p className="mt-1">
                                <span onClick={() => setToggleDetails(toggleDetails ? false : true)} className="pointer text-primary">{operation.tyres.length} {t('tyres')}</span>&nbsp;
                                {printLabels ? '' :  <FontAwesomeIcon title={t('Print labels')} onClick={() => setShowPrintModal(true)} className="pointer text-secondary" icon={fa.faPrint}/>}
                            </p>
                            
                        </Col>
                        <Col lg={3}>
                            <small><span className="opacity-50">{t('Services')}</span>
                            {operation.status === 'pending' && availableServices.length > 0 ? <span className="text-primary pointer" onClick={() => setShowAddServiceSelect(true)}> <FontAwesomeIcon icon={fa.faPlus}/> {t('Add service')}...</span> : '' }</small><br></br>
                            
                            <ul className="mt-1">
                            {operation.status === 'pending' ?
                                <>{operationServices.map((service,i) => service ? <li key={i}>
                                        <span>{service?.icon ? <><FontAwesomeIcon size="sm" className="opacity-50" icon={fa[service.icon]}/> </> : ''}{service[`description_${userState.lang_iso}`]} </span>
                                        {!service.blocked ?  
                                                    <span><FontAwesomeIcon size="xs" onClick={() => removeService(service)} icon={fa.faTimes} className="text-danger pointer"/></span> : 
                                                    <span><FontAwesomeIcon size="xs" icon={fa.faLock} className="opacity-50 opacity-30"/></span>
                                                }
                                        
                                    </li> : <li key={i} className="opacity-50">{t('Unknown')}</li>)}
                                    {showAddServiceSelect ? <li>
                                        {addServiceForm()}
                                    </li> : ''}
                                    </>
                            : <>{operationServices.map((service,i) => 
                                    service ? 
                                    <li key={i} className="mb-0">{service.icon ? <FontAwesomeIcon icon={fa[service.icon]} className="opacity-50" size="sm"/> : ''} {service[`description_${userState.lang_iso}`]}</li> :
                                    <li key={i} className="mb-0 opacity-50">{t('Unknown')}</li> 
                            )}</>}
                            </ul>
                        </Col>
                        <Col lg={2}>
                                    <small className="opacity-50">{t('Rims')}</small>
                                    {operation.status === 'pending' ?
                                        <Form.Select value={operation.withRims} onChange={(e) => changeOperationValue('withRims',e.target.value)} placeholder={t('Rims')}>
                                            <option value="">{t('None')}</option>
                                            <option value="steel">{t('Steel')}</option>
                                            <option value="aluminum">{t('Aluminum')}</option>
                                        </Form.Select> : 
                                        <p className="mt-1 mb-0">{operation.withRims ? t(capitalizeFirstLetter(operation.withRims)) : t('None')}</p> 
                                    }
   
                            <div className="mt-1">
                            {operation.status === 'pending' ? <>
                                    <Form.Label for={`with-bolts-${operation._id}`}><input checked={operation.withBolts} onChange={(e) => changeOperationValue('withBolts',e.target.checked)} id={`with-bolts-${operation._id}`} type="checkbox"/> {t('With bolts')} </Form.Label>
                                    &nbsp;
                                    <Form.Label for={`with-hubcaps-${operation._id}`}><input checked={operation.withHubcaps} onChange={(e) => changeOperationValue('withHubcaps',e.target.checked)} id={`with-hubcaps-${operation._id}`} type="checkbox"/> {t('With hubcaps')} </Form.Label>
                                </> :   <>
                                            {operation.withBolts ? <small className="mb-0 d-block ml-1"><FontAwesomeIcon className="text-primary" icon={fa.faCheck}/> {t('With bolts')}</small> : ''}
                                            {operation.withHubcaps ? <small className="mb-0 d-block ml-1"><FontAwesomeIcon className="text-primary" icon={fa.faCheck}/> {t('With hubcaps')}</small> : ''}
                                        </>
                            }
                           </div>
                        </Col>
                        <Col lg={3}>
                            
                            { operation.status === 'pending' ? <>
                                <small className="opacity-50">{t('Remarks')}</small>
                                <input className="form-control" 
                                    value={remarks} onBlur={() => {changeOperationValue("remarks", remarks )}} 
                                    onChange={(e) => {setRemarks(e.target.value)}}/>
                            </>: '' }
                            { operation.status !== 'pending' && operation.remarks ? <>
                                <small className="opacity-50">{t('Remarks')}</small>
                                <p className="mt-1 mb-0">{operation.remarks}</p> 
                            </> : '' }
                            {/*if config allows tachometer - if no tachometer present on submitted op /--> hide tag*/}
                            {hotelConfig.allowTachometer && operation.status=== 'pending' ? <>
                                <small className="opacity-50">{t('Tachometer')}</small>
                                    <Form.Control type="number" 
                                    step="1" min="1"
                                    value={tachometer}
                                    onBlur={() => changeOperationValue('tachometer',tachometer)}
                                    onChange={e => setTachometer(e.target.value)} />                                    
                                </> : ''}
                            {hotelConfig.allowTachometer && operation.status !== 'pending' && operation.tachometer ? <>
                                <small className="opacity-50">{t('Tachometer')}</small>
                                <p>{operation.tachometer} km</p>
                            </> : ''}
                        </Col>

                        <Col lg={2}>
                            <div>
                            {printLabels ? <label className="d-block" htmlFor="print-labels"> <FontAwesomeIcon icon={fa.faPrint}/> {t('Print labels')} &nbsp;
                                <input onChange={(e) => setPrintLabelsProcess(e.target.checked)} checked={printLabelsProcess} id="print-labels" type="checkbox"/>
                            </label> : <small>&nbsp;</small>}
                                {/*operation.status === 'submitted' ? <Button onClick={() => undoSubmit()} variant="outline-secondary" size="sm" disabled={loading} className="mt-1"><FontAwesomeIcon icon={faUndo}/> {t('Undo submit')}</Button>  : ''*/}
                                {operation.status === 'pending' ? 
                                <p>
                                    <Button type="submit" variant="success" size="sm" disabled={loading} className="mt-1"><FontAwesomeIcon icon={fa.faCheck}/> {t('Process')}</Button>&nbsp;
                                    <Button onClick={() => handleDelete()} disabled={loading} variant="outline-danger" className="mt-1" size="sm"><FontAwesomeIcon icon={fa.faTrash}/></Button>
                                </p> : '' }
                            </div>
                        </Col>
                        <Col md={12} className={toggleDetails ? 'mt-3' : 'collapse'}>
                            <TyreTable showDepth={hotelConfig.allowProfileDepth} tyres={[...operation.tyres]} onDepthChange={depths => setTyreDepths(depths) } allowDepthInput={hotelConfig.allowProfileDepth && operation.status == 'pending'}/>
                        </Col>
                    </Card.Body>
                </Card>
            </Form>
        </>
    )
}

export default withNamespaces()(Storage)