
import React from "react";
import { withNamespaces } from 'react-i18next';
import { Nav, Navbar, Container, Button, Badge} from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import { TyreIcon } from '../../resources/images/customIcons'
import { logoutUser } from '../../actions/user';
import { useNavigate } from "react-router-dom";

function Menu({t}) {
    const dispatch = useDispatch()
    const userState = useSelector((store) => store.User)
    const operationState = useSelector((store) => store.Operations)
    const pendingOps = operationState?.operations?.filter(o => o.status === 'pending')
    const navigate = useNavigate()
    const siteConfigState = useSelector((store) => store.Siteconfig)
    function handleLogout() {
        dispatch(logoutUser())
        navigate('/')
    }

    const location = useLocation()
    function isActive(pathName) {
        if (location.pathname === pathName) {
            return true;
        } else {
            return false;
        }
    }

    //pass array of authorizeable user groups --> returns true if matches user type
    function authorizeUsers(userGroups) {
        return userGroups.includes(userState.type)
    }

    //pass config type --> returns true if config is within boundaries
    function authorizeConfig(configType) {
        if (configType == 'hotelonly') {
            const authorizedConfigs = ['hotelonly','both']
            return authorizedConfigs.includes(siteConfigState.mode)
        } else if (configType == 'fleetonly') {
            const authorizedConfigs = ['fleetonly','both']
            return authorizedConfigs.includes(siteConfigState.mode)
        } else {
            throw new Error('Config type not supported')
        }
    }

    return (<Navbar bg="light" collapseOnSelect expand="lg">
                <Container max>
                    <Navbar.Brand><Link to="/"><img className="navbar-logo" src={siteConfigState?.navlogoPath}/></Link></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto mt-1">
                            {authorizeUsers(['admin','user']) ? 
                                <Nav.Link as={Link} eventKey="1" className="nav-link" to="/" active={isActive('/')}>
                                    <FontAwesomeIcon icon={fa.faHome}/> {t('Home')}
                                </Nav.Link> : '' 
                            }
                            {authorizeUsers(['user']) ?  
                                <Nav.Link as={Link} eventKey="1" className="nav-link" to="/fleet" active={isActive('/fleet')}>
                                    <FontAwesomeIcon icon={fa.faCar}/> {t('Fleet')}
                                </Nav.Link> : '' 
                            }
                            {authorizeUsers(['user']) ?  
                                <Nav.Link as={Link} eventKey="1" className="nav-link" to="/tyres" active={isActive('/tyres')}>
                                    <FontAwesomeIcon icon={TyreIcon}/> {t('Tyres')}
                                </Nav.Link> : '' 
                            }
                            {authorizeUsers(['user']) && authorizeConfig('hotelonly') ?  
                                <Nav.Link as={Link} eventKey="1" className="nav-link" to="/operations" active={isActive('/operations')}>
                                    <FontAwesomeIcon icon={fa.faCalendarCheck}/> {t('Operations')} {pendingOps.length > 0 ? <sup><Badge>{pendingOps.length}</Badge></sup> : '' }
                                </Nav.Link> : '' 
                            }
                            {authorizeUsers(['admin']) && authorizeConfig('hotelonly') ?  
                                <Nav.Link as={Link} eventKey="1" className="nav-link" to="/admin/operations" active={isActive('/admin/operations')}>
                                    <FontAwesomeIcon icon={fa.faCalendarAlt}/> {t('Operations')}
                                </Nav.Link> : ''
                            }
                            {authorizeUsers(['admin']) && authorizeConfig('hotelonly') ?  
                                <Nav.Link as={Link} eventKey="1" className="nav-link" to="/admin/intake" active={isActive('/admin/intake')}>
                                    <FontAwesomeIcon icon={fa.faBarcode}/> {t('Tyre Intake')}
                                </Nav.Link> : ''
                            }
                            {authorizeUsers(['admin']) && authorizeConfig('fleetonly') ?  
                                <Nav.Link as={Link} eventKey="1" className="nav-link" to="/admin/fleetmanagement" active={isActive('/admin/intake')}>
                                    <FontAwesomeIcon icon={fa.faCarSide}/> {t('Fleet management')}
                                </Nav.Link> : ''
                            }
                            {authorizeUsers(['admin']) && authorizeConfig('hotelonly') ?  
                                <Nav.Link as={Link} eventKey="1" className="nav-link" to="/admin/invoicing" active={isActive('/admin/invoicing')}>
                                    <FontAwesomeIcon icon={fa.faFile}/> {t('Invoicing')}
                                </Nav.Link> : ''
                            }
                            {authorizeUsers(['admin']) ?  
                                <Nav.Link as={Link} eventKey="1" className="nav-link" to="/admin/config" active={isActive('/admin')}>
                                    <FontAwesomeIcon icon={fa.faCog}/> {t('Configuration')}
                                </Nav.Link> : ''
                            }
                            { authorizeUsers(['superuser']) ? <>
                            <Nav.Link as={Link} eventKey="1" className="nav-link" to="/superuser" active={isActive('/superuser')}>
                                <FontAwesomeIcon icon={fa.faUserCog}/> Xerxes Admin
                            </Nav.Link>
                            <Nav.Link as={Link} eventKey="1" className="nav-link" to="/superuser/translations" active={isActive('/superuser/translations')}>
                                <FontAwesomeIcon icon={fa.faBook}/> Dictionary
                            </Nav.Link>
                            </> : '' }
                        </Nav>
                        {userState.isAuthenticated ? <>
                            {t('Welcome')}, {userState.fullname} &nbsp;
                            <Button size="sm" variant="outline-secondary" onClick={() => {handleLogout()}}><FontAwesomeIcon icon={fa.faSignOutAlt}/> {t('Log out')}</Button> 
                        </> : ''}
                        
                    </Navbar.Collapse>
                </Container>
            </Navbar>
    )
}

export default withNamespaces()(Menu)
