import axios from 'axios';

axios.defaults.withCredentials = true;


export const getFleet = () => axios.post( '/fleet/fetch')

export const fetchVehicleConfigs = () => axios.post( '/fleet/getvehicleconfigs')
export const loadVehicle = (id) => axios.post( '/fleet/vehicle', { flt_id : id })
export const updateVehicle = (vehicle) => axios.post ( '/fleet/update', vehicle)
export const newVehicle = (vehicleData,seq_id) => axios.post( '/fleet/new', {vehicleData : vehicleData, seq_id : seq_id} )
export const inactivateVehicle = (flt_id) => axios.post( '/fleet/inactivate', {flt_id : flt_id})

export const fetchHistory = (flt_id) => axios.post('/fleet/history', {flt_id : flt_id})
export const downloadDocument = (db_id,doctype) => axios.post('/fleet/historydocument', {db_id : db_id, doctype : doctype})

export const searchArticles = (search_value, last_article) => axios.post('/fleet/searcharticles', {search_value : search_value,last_article : last_article})