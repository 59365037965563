import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import Moment from 'react-moment';
import { BoltIcon, HubcapIcon, RimAluminum, RimSteel } from '../../resources/images/customIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function TyreStockDetailsModal({tyre, t}) {
    
    const [showModal,setShowModal] = useState(tyre ? true : false)
    function closeModal() { 
        setShowModal(false) 
    }
    useEffect(() => {
 
        setShowModal(true)
    }, [ tyre ])

    let rims = false

    if (tyre?.stock_rims) {
        if (tyre.stock_rims[0] === 'aluminum') {
            rims = 'Aluminum'
        } else if (tyre.stock_rims[0] === 'steel') {
            rims = 'Steel'
        }
    }

    return (   
    <>    
        {tyre ? <Modal size="lg" show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>{t('Tyre stock details')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={3}><b>{t('Tyre ID')}</b></Col>
                    <Col md={9}>{tyre.db_id[0]}</Col>
                </Row>
                <Row className="mt-2">
                    <Col md={3}><b>{t('Position')}</b></Col>
                    <Col md={9}>{tyre.position[0]}</Col>
                </Row>
                <Row className="mt-2">
                    <Col md={3}><b>{t('Description')}</b></Col>
                    <Col md={9}>{tyre.tyre_memo ? tyre.tyre_memo[0] : tyre.description[0]}</Col>
                </Row>
                <Row className="mt-2">
                    <Col md={3}><b>{t('Profile depth')}</b></Col>
                    <Col md={9}>{tyre.profile_depth[0]} mm</Col>
                </Row>
                <Row className="mt-2">
                    <Col md={3}><b>{t('Profile date')}</b></Col>
                    <Col md={9}><Moment parse="YYYYMMDD" format="DD/MM/YYYY" date={tyre.profile_date[0]} /></Col>
                </Row>
                <hr/>
                <Row>
                    <Col md={3}><b>{t('Site name')}</b></Col>
                    <Col md={9}>{tyre.stock_site_name[0]}</Col>
                </Row>
                <Row className="mt-2">
                    <Col md={3}><b>{t('Status date')}</b></Col>
                    <Col md={9}><Moment parse="YYYYMMDD" format="DD/MM/YYYY" date={tyre.status_date[0]} /></Col>
                </Row>
                <Row className="mt-2">
                    <Col md={3}><b>{t('Stock rack')}</b></Col>
                    <Col md={9}>{tyre.stock_rack[0]}</Col>
                </Row>
                <Row className="mt-2">
                    <Col md={3}><b>{t('Stock rims')}</b></Col>
                    <Col md={9}>
                        {rims === 'Aluminum' ? <><FontAwesomeIcon icon={RimAluminum}/> </> : '' }
                        {rims === 'Steel' ? <><FontAwesomeIcon icon={RimSteel}/> </> : '' }
                        {rims ? t(rims) : t('No')}
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={3}><b>{t('Stock bolts')}</b></Col>
                    <Col md={9}>{tyre.stock_bolts[0] === 'Y' ? <><FontAwesomeIcon icon={BoltIcon}/>  {t('Yes')}</> : t('No')}</Col>
                </Row>
                <Row className="mt-2">
                    <Col md={3}><b>{t('Stock hubcaps')}</b></Col>
                    <Col md={9}>{tyre.stock_hubcaps[0] === 'Y' ? <><FontAwesomeIcon icon={HubcapIcon}/>  {t('Yes')}</> : t('No')}</Col>
                </Row>
      
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal}>
                {t('Close')}
              </Button>
            </Modal.Footer>
        </Modal> : ''} 
        
    </>)
}

export default withNamespaces()(TyreStockDetailsModal)
