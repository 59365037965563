import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import { withNamespaces } from 'react-i18next'
import { logoutUser } from '../../actions/user'
import { useDispatch } from 'react-redux'
function Toast({t}) {

    const toastDuration = 7500
    const dispatch = useDispatch()

    const confirmation = (message) => {
      return new Promise((resolve) => {
        let resolved = false
        function handleConfirm(e) {
          e.target.disabled = true
          resolved = true
          resolve(true)
        }

        setTimeout(() => { resolve(false)}, toastDuration)
  
        const Msg = () => (
          <div>
            {message}<br/>
            <Button variant="success" onClick={e => handleConfirm(e)}><FontAwesomeIcon icon={fa.faCheck}/> {t('Confirm')}</Button>&nbsp;
            <Button variant="outline-danger" ><FontAwesomeIcon icon={fa.faTimes}/> {t('Close')}</Button>
    
          </div>
        )
        toast(<Msg/>, { 
          onClose : () => !resolved ? resolve(false) : ''
        })
      })

    }

    window.confirmation = confirmation

    const notify = (type, message, options) => {
        if (typeof(message) !== 'string') {
          message = message.toString()
        }
        toast(message, {
          ...options, 
          type: type
        })
      };
    
      window.notify = notify
      
      const handleError = (error,options) => {
        //muted will output error to console instead screen
        if (!options?.muted) {
          if (error.response && error.response.status && error.response.status === 401) {
            notify('error', t('Unauthorized, returning to login.'))
            setTimeout(() => {
              dispatch(logoutUser())
            }, toastDuration)
          } else {
            notify('error', distillErrorMessage(error))
          }
        } else {
          if (error.response && error.response.status && error.response.status === 401) {
            console.error('Unauthorized, returning to login.')
            setTimeout(() => {
              dispatch(logoutUser())
            }, toastDuration)
          } else {
            console.error(distillErrorMessage(error))
          }
        }

        
      }

      function distillErrorMessage(error) {
        if (error) {
          //on 401 error push user to login page? TODO
          return error.response?.data?.message? error.response.data.message :  error.message;
        } else {
          return t('Unknown error occured')
        }
      }
    
      window.handleError = handleError;
      
      const loadingShow = function(message) {
        return toast.loading(message)
      }

      window.loadingShow = loadingShow

      const loadingResolve = function(id,type,message) {
        const toastObject = { type: type, isLoading: false, autoClose: toastDuration, closeOnClick : true }
        if (type === 'error') {
          toastObject['render'] = distillErrorMessage(message)
        } else {
          toastObject['render'] = message
        }
        toast.update(id, toastObject);
      }

      window.loadingResolve = loadingResolve


    return (
        <ToastContainer
        theme="dark"
        position="bottom-center"
        autoClose={toastDuration}
        newestOnTop
        pauseOnHover
        pauseOnFocusLoss={false}
        closeOnClick
        draggable={false}
        />
    )
}

export default withNamespaces()(Toast)
