import React, { useEffect } from 'react'
import { withNamespaces } from 'react-i18next';

function TyreLabelScanner({t, onScan, onError }) {
    //eruda.init();
    let code = "";
    let reading = false;
  
    function decodeTsLabel(labelvalue) {
      //remove T$L prefix and % terminator 
      const trimmedString = labelvalue.substring(3,15)
      const dbIdPart = trimmedString.split('+')[0]
      const ticsIdPart = trimmedString.split('+')[1]
      return {
        tyreId : XxsCode36ToInteger(dbIdPart),
        ticsId : XxsCode36ToInteger(ticsIdPart),
        timestamp : new Date()
      }
    }
  
    //converts Xxs scanned code to integer
    //stolen straight outta dennis code lol :)
    function XxsCode36ToInteger(xxsCode) {
      const divider = 36
      function Convert(char) {
        if (char >= 48 && char <= 57 ) {
          return char - 48
        } else if (char >= 65 && char <= 90) {
          return char - 55
        }
      }
      let result = 0
      for (let i = xxsCode.length; i > 1; i--) {
        const char = xxsCode.charCodeAt(i-1)
        const digit = Convert(char)
        result += (digit * Math.trunc(Math.pow(divider, (xxsCode.length - i))))
      }
      return result
    }

    async function scanEvent(e) {
      code += e.key
      //start sequence detected
      if (code.includes('#$[')) {
        //console.log('start read')
        reading = true
        code = ''
      }

      if (reading) {
        if (code.includes(']$#')) {
          //console.log('end read')
          reading = false
          code = code.substring(0,code.length - 3)
          if(code.startsWith('T$L') && code.endsWith('%') && code.length === 16 ) {
            /// code ready to use   
            //console.log('code set')
            const decodedLabel = decodeTsLabel(code) 
            if (decodedLabel) {
              onScan(decodedLabel)
            }
          } else {
            onError({label: code, message : t('Error decoding scanned barcode')})
          }
        }

      }

    }

    useEffect(() => {
        //add event listener on mount component
        document.addEventListener('keypress', scanEvent); 
        //remove event listener on dismount component
        return () => {
          document.removeEventListener('keypress', scanEvent)
        }
    }, [])

  return (
    <div></div>
  )
}

export default withNamespaces()(TyreLabelScanner)