import * as api from '../api/user'
import i18n from '../i18n';
import {registerLocale, setDefaultLocale } from "react-datepicker";
import {nl,de,fr,uk} from 'date-fns/locale';

//sets datepicker language & body tag language
function setLocale(lang) {
    if (lang === 'dut') {
        registerLocale('nl', nl)
        document.documentElement.setAttribute('lang', 'nl');
        setDefaultLocale('nl')
    } else if (lang === 'fre') {
        registerLocale('fr', fr)
        document.documentElement.setAttribute('lang', 'fr');
        setDefaultLocale('fr')
    } else if (lang === 'ger') {
        document.documentElement.setAttribute('lang', 'de');
        registerLocale('de', de)
        setDefaultLocale('de')
    } else if (lang === 'eng') {
        document.documentElement.setAttribute('lang', 'en');
        registerLocale('uk', uk)
        setDefaultLocale('uk')
    }
}

const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLocale(lng);
}

export const loginUser = (username, password) => async(dispatch) => {
    const { data } = await api.authenticateUser(username, password);
    data["isAuthenticated"] = true
    dispatch({ type: 'USER_LOGIN', payload: data})
    changeLanguage(data.lang)
    
}

export const logoutUser = () => async(dispatch) => {

    try {
        await api.endSession();
        const action = { type: 'USER_LOGOUT'}
        dispatch(action)
    } catch(error) {
        window.handleError(error)
    }
}

export const loginState = () => async(dispatch) => {
        const { data } = await api.loginState();
        if (data.username && data.fullname) {
            
            const payload = {
                isAuthenticated: true,
                username: data.username,
                fullname: data.fullname,
                type: data.type,
                lang: data.lang
            }
            
            const action = { type: 'USER_LOGIN', payload: payload}
            dispatch(action)
            changeLanguage(data.lang)
        }
}
