import React, { useState } from "react";
import { Form, Button, Alert, FloatingLabel, Card } from "react-bootstrap";
import "./Login.css";

import { useDispatch,useSelector } from 'react-redux'
import { loginUser } from '../../actions/user';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faHeart, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import backgroundImage from '../../resources/images/logon_backdrop.jpg'

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error,setError] = useState(undefined);
  const [errorCode,setErrorCode] = useState(undefined);
  function validateForm() {
    return username.length > 0 && password.length > 0;
  }

  const dispatch = useDispatch()

  async function handleSubmit(event) {
    setLoading(true)
    event.preventDefault();
    try {
      await dispatch(loginUser(username,password))
    } catch(e) {

      if (e.response && e.response.data && e.response.data.message) {
        setError(e.response.data.message)
        if (e.response.data.code) {
          setErrorCode(e.response.data.code)
        }
      } else {
        setError(e.message)
      } 
    }
    setLoading(false)
  }
  const siteConfigState = useSelector((store) => store.Siteconfig)

  const loginBodyStyle = {
      backgroundImage: `url('${backgroundImage}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: "100vh"
  }

  document.body.style.backgroundImage = loginBodyStyle.backgroundImage
  document.body.style.backgroundPosition = loginBodyStyle.backgroundPosition
  document.body.style.backgroundRepeat = loginBodyStyle.backgroundRepeat
  document.body.style.backgroundSize = loginBodyStyle.backgroundSize
  document.body.style.height = loginBodyStyle.height

  const [loading,setLoading] = useState(false)

  return (
    <div className="Login d-flex">
      <div className="login-form-container">
      <Card>
        <Card.Body>
          <Form className="text-center" onSubmit={handleSubmit}>
            <img className="w-100" src={siteConfigState?.landinglogoPath}/>
            <FloatingLabel controlId="floatingUsername" label="Username" className="mt-2">
              <Form.Control size="lg" type="text" placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
            </FloatingLabel>

            <FloatingLabel controlId="floatingPassword" label="Password" className="mt-2">
              <Form.Control size="lg" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            </FloatingLabel>

            {error ? <Alert className="mt-2" variant={"danger"}>
                <span>{error}</span>
                {errorCode ? <><br/><small><b>Error code {errorCode}</b></small></> : ''}
            </Alert> : ''}
            <Button size="lg" className="mt-2" disabled={loading || !validateForm()} type="submit">
              {loading ?  <><FontAwesomeIcon className="spinner" icon={faCircleNotch}/> Logging in...</> : <><FontAwesomeIcon icon={faPaperPlane}/> Login</>}
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <small className="opacity-50">Made with <FontAwesomeIcon className="text-danger" icon={faHeart} /> by Xerxes Advanced Information Systems BV</small>
      </div>
    </div>
  );
}