import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux';
import { Button, Form, Card, Row, Col} from 'react-bootstrap'
import { withNamespaces } from 'react-i18next';
import { updateOperation, deleteOperation } from '../../actions/operations';
import { useNavigate } from 'react-router-dom';
import VehicleIcon from '../common/vehicleIcon'
import moment from 'moment'

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCheck } from '@fortawesome/free-solid-svg-icons'
import TyreTable from '../tyres/tyreTable';

function NewRecall({operation,operationSubmitted, operationDeleted, hideDeleteAlert, tyresExpanded, bookedDays,t}) {
    const fleetState = useSelector((store) => store.Fleet)
    const vehicles = fleetState.vehicles
    const dispatch = useDispatch()
    const vehicle = vehicles.filter(v => v.flt_id[0] == operation.flt_id)[0]
    const hotelConfig = useSelector((store) => store.Siteconfig)
    const userState = useSelector((store) => store.User)
    const maxMonths = 6

    const [toggleDetails, setToggleDetails] = useState(tyresExpanded)
    const [remarks,setRemarks] = useState(operation.remarks)

    async function changeOperationValue(fieldName, value) {
        const copiedOperation = {...operation}
        copiedOperation[fieldName] = value
        dispatch(updateOperation(copiedOperation,true))
    }
    const selectedDate = operation.deliveryDate ? new Date(operation.deliveryDate) : ''

    const [loading, setLoading] = useState(false)
    async function submitOperation(e) {
        e.preventDefault()
        setLoading(true)
        const copiedOperation = {...operation}
        copiedOperation["status"] = "submitted"
        await dispatch(updateOperation(copiedOperation))
        if (operationSubmitted) { operationSubmitted() }
        setLoading(false)
    }

    /*async function undoSubmit() {
        setLoading(true)
        const copiedOperation = {...operation}
        copiedOperation["status"] = "pending"
        await dispatch(updateOperation(copiedOperation))
        if (operationSubmitted) { operationSubmitted() }
        setLoading(false)
    }*/

    async function handleDelete() {
        setLoading(true)
        await dispatch(deleteOperation(operation,hideDeleteAlert))
        if (operationDeleted) { operationDeleted() }
        setLoading(false)
    } 

    const navigate = useNavigate()
    function goToVehicle(id) {
        navigate('/fleet/vehicle', {state: { id: id}})
    }

    const selectedTransportMethod = hotelConfig.transportMethods?.filter(tm => tm._id === operation.transport)[0]
    const [minDate,setMinDate] = useState(undefined)

    //list of all transport opening day ids
    const operationDays = selectedTransportMethod?.operationDays?.map(od => od.value)

    //calc min date
    useEffect(() => {
        if (operationDays) {
            let tempMinDate = moment().add(selectedTransportMethod?.advanceDays,'days')
            //keep adding days to temp date until an opening day is reached 
            while (!operationDays.includes(tempMinDate.day())) {
                
                tempMinDate.add(1,'day')
            }
            setMinDate(tempMinDate.toDate())
        }

    }, [selectedTransportMethod])

    //list of disabeld days (because fully booked)
    const disabledDays = selectedTransportMethod ? bookedDays?.filter(bd => bd.transport === selectedTransportMethod._id).map(bd => moment(bd.date).format('DDMMYYYY')) : undefined

    function filterDate(date) {
        //if date is in array of disabledDays || is not in operating days (closing days) --> return false
        if (disabledDays?.includes(moment(date).format('DDMMYYYY')) || !operationDays?.includes(moment(date).day())) {
            return false
        }  else {
            return true
        }
    }

    useEffect(() => {
        if (disabledDays?.includes(moment(operation.deliveryDate).format('DDMMYYYY'))) {
            changeOperationValue("deliveryDate",undefined)
        }
    }, [])


    return (
        <Form onSubmit={(e) => submitOperation(e)}>
        <Card className="mt-1 pt-2"><Card.Body as={Row} className="pt-2 pb-2">
            <Col lg={1}>
                <small className="opacity-50">{t('License plate')}</small>
                {vehicle ? 
                    <p className="mt-1 text-primary pointer" onClick={() => goToVehicle(vehicle?.flt_id[0])}>
                        <VehicleIcon type={vehicle?.type[0]}/> {vehicle?.license_plate[0]}
                    </p>
                    : <p className="mt-1 opacity-50">
                        <VehicleIcon type={operation.vehicle?.type[0]}/>  {operation.vehicle?.license_plate}
                    </p>
                }
            </Col>
            <Col lg={1}>
                <small className="opacity-50">{t('Tyres')}</small>
                <p className="mt-1 text-primary pointer" onClick={() => setToggleDetails(toggleDetails ? false : true)}>{operation.tyres.length} {t('tyres')}</p>
            </Col>
            <Col lg={3}>
                <small className="opacity-50">{t('Delivery date')}</small>
                { operation.status === 'pending' ?
                    <DatePicker
                     minDate={minDate} 
                     filterDate={filterDate}
                     maxDate={moment().add(maxMonths,'months').toDate()}
                     value={ operation.deliveryDate ? moment(operation.deliveryDate).format('DD/MM/YYYY') : '' } 
                     className="form-control"
                     selected={selectedDate} 
                     required
                     disabledKeyboardNavigation
                     onChange={(date) => {changeOperationValue("deliveryDate", date)}}/>
                    : <p className="mt-1">{moment(operation.deliveryDate).format('DD/MM/YYYY')}</p>
                }
                
            </Col>
            <Col lg={2}>
                <small className="opacity-50">{t('Transport')}</small>
                { operation.status === 'pending' ?

                    <select 
                        className="form-control" 
                        value={operation.transport} 
                        onChange={(e) => {changeOperationValue("transport", e.target.value )}}>
                            {hotelConfig?.transportMethods?.map(tm => <option key={tm._id} value={tm._id}>{tm[`description_${userState.lang_iso}` ]}</option>)}
                    </select>
                    : <p className="mt-1">{selectedTransportMethod[`description_${userState.lang_iso}` ]}</p>
                }
            </Col>
            <Col lg={3}>
                <small className="opacity-50">{t('Remarks')}</small>
                { operation.status === 'pending' ?
                    <input className="form-control" value={remarks} onBlur={(e) => {if (e.target.value !== operation.remarks) {changeOperationValue("remarks", remarks )}}} onChange={(e) => {setRemarks(e.target.value)}}></input>
                    : <p className="mt-1">{operation.remarks}</p>
                }
            </Col>
            <Col lg={2}>
                <small>&nbsp;</small>
                <div className="text-right">
                    {/*operation.status === 'submitted' ? <Button onClick={() => undoSubmit()} variant="outline-secondary" size="sm" disabled={loading} className="mt-1"><FontAwesomeIcon icon={faUndo}/> {t('Undo submit')}</Button>  : ''*/}
                    {operation.status === 'pending' ? 
                    <>
                        <Button type="submit" variant="success" size="sm" disabled={loading} className="mt-1"><FontAwesomeIcon icon={faCheck}/> {t('Process')}</Button>&nbsp;
                        <Button onClick={() => {handleDelete()}}  disabled={loading} variant="outline-danger" className="mt-1" size="sm"><FontAwesomeIcon icon={faTrash}/></Button>
                        </>: '' }
                </div>
            </Col>
            <Col md={12} className={toggleDetails ? '' : 'collapse'}>
                <TyreTable showDepth={hotelConfig.allowProfileDepth} tyres={operation.tyres}/>
            </Col>
        </Card.Body></Card>
        </Form>
    )
}

export default withNamespaces()(NewRecall)