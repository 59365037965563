import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons' 
import { loadFleetAndTyres } from '../../actions/vehicles'
import NewVehicleForm from '../fleetpage/NewVehicleForm'
import { useNavigate } from 'react-router-dom'
import VehicleIcon from '../common/vehicleIcon'

function LookupVehicle({t}) {

  const fleetState = useSelector((store) => store.Fleet)
  const dispatch = useDispatch()

  //if fleet state is missing or not laoded, dispatch load fleet
  useEffect(async () => {
    async function fetchVehicles() {
        if (!fleetState || !fleetState.loaded) {
          await dispatch(loadFleetAndTyres())
          setInputsDisabled(false)
        } else {
          setInputsDisabled(false)
        }
        document.getElementById('set-focus').focus()
    } 
    fetchVehicles();
  }, [])

  const [inputsDisabled,setInputsDisabled] = useState(true)
  const [lookupPlate,setLookupPlate] = useState('')

  const [wizardVehicle,setWizardVehicle] = useState(undefined);

  const [showLookupForm, setShowLookupForm] = useState(true);
  const [showNewForm, setShowNewForm] = useState(false);
  const [showMultiVehicle, setShowMultiVehicle] = useState(false);
  const [foundVehicles, setFoundVehicles] = useState([])

  function lookupLicensePlate(e) {
    e.preventDefault()
    if (fleetState.loaded) {
      const vehicles = fleetState.vehicles

      const foundVehicles = vehicles.filter(vehicle => vehicle.license_plate[0].toUpperCase() === lookupPlate.toUpperCase())
      if (foundVehicles && foundVehicles.length == 1) {
        setWizardVehicle(foundVehicles[0])
      } else if (foundVehicles.length  > 1) {
        setShowLookupForm(false)
        setFoundVehicles(foundVehicles)
        setShowMultiVehicle(true)
      } else {
        setShowLookupForm(false)
        setShowNewForm(true)
      }
      
    } else {
      window.notify('error',t('Failed to perform lookup: fleet not loaded'))
    }
    
  }

  const navigate = useNavigate()
  function goToStep2() {
      navigate('../step2', {state: { flt_id: wizardVehicle.flt_id[0]}})
  }

  function goToVehicle(id) {
      navigate('/fleet/vehicle', {state: { id: id}})
  }

  useEffect(() => {
    if (wizardVehicle) {
      goToStep2()
    }
  },[wizardVehicle])

  useEffect(() => {
    setTimeout(() => {
          document.getElementById('set-focus').focus()
      }, 100)
  },[])

  return (
    <Row className="justify-content-center">
      <h5 className="text-center">{t('Step 1')} { showMultiVehicle ? <Button size="sm" className="mb-1" variant="outline-primary" onClick={() => { setShowLookupForm(true); setShowMultiVehicle(false);}}><FontAwesomeIcon icon={fa.faChevronLeft}/> {t('Go back')}</Button> : '' }</h5>
      { showLookupForm ? 
      <Col className="text-center" md={4}>

        <small className="mb-1 d-block">{t('Insert license plate')}</small>
        <form onSubmit={(e) => lookupLicensePlate(e)}>
          <Form.Control id="set-focus" minLength="1" required disabled={inputsDisabled} style={{textAlign : 'center'}} placeholder="1ABC123" value={lookupPlate} onChange={(e) => setLookupPlate(e.target.value.replace(/[^0-9a-z]/gi, '') )}/>
          <Button type="submit" disabled={inputsDisabled} className="mt-2" variant="primary"><FontAwesomeIcon icon={fa.faPaperPlane}/> {t('Proceed')} </Button>
        </form>
      </Col> : '' }
      { showMultiVehicle ? <Row>
        <p className="text-center mb-0">{t('Multiple vehicles found, please pick one.')}</p>
          {foundVehicles.map(vehicle => <Card key={vehicle.flt_id[0]} className="mt-1">
              <Card.Body as={Row}>
                <Col md={2} sm={12}>
                  <small className="opacity-50">{t('License plate')}</small><br/>
                  <span className="text-primary pointer" onClick={() => goToVehicle(vehicle.flt_id[0])}><VehicleIcon type={vehicle.type[0]}/> {vehicle.license_plate[0]}</span>
                </Col>
                <Col md={2} sm={12}>
                  <small className="opacity-50">{t('Brand')}</small><br/>
                  {vehicle.brand[0]}
                </Col>
                <Col md={3} sm={12}>
                  <small className="opacity-50">{t('Description')}</small><br/>
                  {vehicle.description[0]}
                </Col>
                <Col md={3} sm={12}>
                  <small className="opacity-50">{t('Driver')}</small><br/>
                  {vehicle.driver_name ? vehicle.driver_name[0] : ''}
                </Col>
                <Col md={2}>
                  <Button className="mt-2" onClick={() => setWizardVehicle(vehicle)}>{t('Proceed')} <FontAwesomeIcon icon={fa.faChevronRight}/></Button>
                </Col>
              </Card.Body>
            </Card>)} 
        </Row>
       : ''}
      { showNewForm ? <Col className="text-right" md={6}>
      <Col className="text-center"><Button size="sm" className="mb-1" variant="outline-primary" onClick={() => { setShowLookupForm(true); setShowNewForm(false);}}><FontAwesomeIcon icon={fa.faChevronLeft}/> {t('Go back')}</Button></Col>
        <p className="text-center mb-0">{t('The vehicle was not found, please add information to proceed.')}</p>
        <NewVehicleForm lpPrefill={lookupPlate} insertedVehicle={vehicle => setWizardVehicle(vehicle)}/>
      </Col> : ''
      }
    </Row>
  )
}

export default withNamespaces()(LookupVehicle)