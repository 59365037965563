import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TyreIcon } from '../../resources/images/customIcons'
import * as fa from '@fortawesome/free-solid-svg-icons'
import React, { useEffect } from 'react'
import { Button, Row, Col, Card } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
import { loadFleetAndTyres } from '../../actions/vehicles'
import { useDispatch, useSelector } from 'react-redux'
import { loadOperations } from '../../actions/operations'
import moment from 'moment'
import './style.css'
import TyreTable from '../tyres/tyreTable'
import HomepageLineChart from './homepageLineChart'
import HomepagePieChart from './homepagePieChart'
import ContentLoader from 'react-content-loader'
 
function Homepage({t}) {

    const fleetState = useSelector((store) => store.Fleet)
    const operationState = useSelector((store) => store.Operations)
    const hotelConfig = useSelector((store => store.Siteconfig))
    const pendingOperations = operationState?.operations?.filter(op => op.status === 'pending')
    const submittedOperations = operationState?.operations?.filter(op => op.status === 'submitted')
    const finishedOperations = operationState?.operations?.filter(op => op.status === 'confirmed' ||  op.status === 'cancelled')
    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    function goToWizard() {
        navigate('/wizard/step1')
    }

    function goToOps(tab) {
        navigate('/operations', {state : { tab : tab}})
    }

    function goToFleet() {
        navigate('/fleet')
    }

    function goToTyres() {
        navigate('/tyres')
    }

    const tyres = fleetState.loaded ? fleetState.vehicles.filter(vehicle => vehicle.tyres[0]).flatMap(vehicle => vehicle.tyres[0].tyre) : []
    //filter on stock tyres without status change the last 6 months
    const referenceDate = moment().subtract(6,'months').format('YYYYMMDD')
    const oldStockTyres = tyres.filter(tyre => {
        if (tyre.tyre_status[0] === 'in_stock') {
            if (parseInt(tyre.status_date[0]) < parseInt(referenceDate)) {
                return true
            }
        }
    })

    //if fleet state is missing or not laoded, dispatch load fleet
    useEffect(async () => {
        async function fetchVehicles() {
            if (!fleetState || !fleetState.loaded) {
                await dispatch(loadFleetAndTyres())
            }
        }
        dispatch(loadOperations())
        fetchVehicles();
    }, [])

    const pageloadPlaceholder = () => {
        return <Row>
            <Col md={12}>
                <Row className="text-center justify-content-center">
                    <Col sm={12 }md={4}>
                        <ContentLoader
                            className="w-100 text-center"
                            speed={2}
                            height={200}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb">
                            <circle cx="50%" cy="53" r="47" /> 
                            <rect x="37.5%" y="129" width="25%" height="30" />
                            <rect x="0" y="164" rx="0" ry="0" width="100%" height="25" />
                        </ContentLoader>
                    </Col>
                    <Col sm={12 }md={4}>
                        <ContentLoader
                            className="w-100 text-center"
                            speed={2}
                            height={200}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb">
                            <circle cx="50%" cy="53" r="47" /> 
                            <rect x="37.5%" y="129" width="25%" height="30" />
                            <rect x="0" y="164" rx="0" ry="0" width="100%" height="25" />
                        </ContentLoader>
                    </Col>
                    <Col sm={12 }md={4}>
                        <ContentLoader
                            className="w-100 text-center"
                            speed={2}
                            height={200}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb">
                            <circle cx="50%" cy="53" r="47" /> 
                            <rect x="37.5%" y="129" width="25%" height="30" />
                            <rect x="0" y="164" rx="0" ry="0" width="100%" height="25" />
                        </ContentLoader>
                    </Col>
                    <Col md={4}>
                        <ContentLoader
                            className="d-inline"
                            speed={2}
                            height={50}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb">
                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="50" />
                        </ContentLoader>
                    </Col>
                        
                </Row>
            </Col>
            {/* GRAPHS */}
            <Col md={8}>
                <ContentLoader
                    className="w-100 mt-4"
                    speed={2}
                    height={350}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb">
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
                </ContentLoader>
            </Col>
            <Col md={4}>
                <ContentLoader
                    className="w-100 mt-4"
                    speed={2}
                    height={350}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb">
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
                </ContentLoader>
            </Col>
            {/* OVERTIME TYRES */}
            <Col md={12}>
                <ContentLoader
                    className="w-100 mt-3"
                    speed={2}
                    height={25}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb">
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
                </ContentLoader>
                <ContentLoader
                    className="w-100 mt-3"
                    speed={2}
                    height={25}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb">
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
                </ContentLoader>
                <ContentLoader
                    className="w-100 mt-3"
                    speed={2}
                    height={25}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb">
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
                </ContentLoader>
                <ContentLoader
                    className="w-100 mt-3"
                    speed={2}
                    height={25}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb">
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
                </ContentLoader>
            </Col>
        </Row>
    }


    return (
        ['hotelonly','both'].includes(hotelConfig.mode) ? 
            fleetState.loaded && operationState.loaded ? <Row>
            <Col md={12}>
                <Card>
                    <Card.Body as={Row} >
                        <Col sm={12} md={4} onClick={() => goToOps('pending')} className="text-center pointer hover-border p-2">
                            <span className="fa-layers fa-fw fa-5x">
                                <FontAwesomeIcon icon={fa.faCircle} className="text-warning" />
                                <FontAwesomeIcon icon={fa.faHourglassHalf} inverse transform="shrink-9" />
                            </span>
                            <h2>{pendingOperations.length}</h2>
                            <p className="mb-0">{pendingOperations.length > 0 ? t('Pending operations') : t('No pending operations')}</p>
                        </Col>
                        <Col sm={12} md={4} onClick={() => goToOps('submitted')} className="text-center pointer hover-border p-2">
                            <span className="fa-layers fa-fw fa-5x">
                                <FontAwesomeIcon icon={fa.faCircle} className="text-primary" />
                                <FontAwesomeIcon icon={fa.faDollyFlatbed} inverse transform="shrink-9" />
                            </span>
                            <h2>{submittedOperations.length}</h2>
                            <p className="mb-0">{submittedOperations.length > 0 ? t('Submitted operations') : t('No submitted operations')}</p>
                        </Col>
                        <Col sm={12} md={4} onClick={() => goToOps('completed')} className="text-center pointer hover-border p-2">
                            <span className="fa-layers fa-fw fa-5x">
                                <FontAwesomeIcon icon={fa.faCircle} className="text-success" />
                                <FontAwesomeIcon icon={fa.faCheck} inverse transform="shrink-8" />
                            </span>
                            <h2>{finishedOperations.length}</h2>
                            <p className="mb-0">{finishedOperations.length > 0 ? t('Completed operations') : t('No completed operations')}</p>
                        </Col>
                        <Col sm={12} className="text-center mt-3">
                            <Button size="lg" onClick={() => goToWizard()}><FontAwesomeIcon icon={fa.faPlus}/> {t('New operation')}</Button>
                        </Col>
                        
                    </Card.Body>
                </Card>
            </Col>


            {finishedOperations.length > 0 ? <Col md={8} className="mt-3 d-sm-none d-md-block"><Card >
                <Card.Body >
                    <HomepageLineChart finishedOperations={finishedOperations}/>
                </Card.Body>
            </Card></Col> : '' }
            {tyres && tyres.length > 0 ? <Col md={4}>
                <Card className="mt-3"><Card.Body>
                    <HomepagePieChart tyres={tyres}/>
                </Card.Body></Card>
            </Col> : '' }
            


            {oldStockTyres.length > 0 ? <>
                <Card as={Col} md={12} className="mt-3">
                    <Card.Body>
                        <p><b><FontAwesomeIcon className="text-warning" icon={fa.faExclamationCircle}/> {t('Attention')}</b> {t('There are {{count}} stock tyres that have an unchanged status in a long period of time',{count : oldStockTyres.length})}.</p>
                        <TyreTable showDepth={hotelConfig.allowProfileDepth} showVehicle tyres={oldStockTyres}/>
                    </Card.Body>
                </Card>
            </> : ''}
        </Row> : pageloadPlaceholder()
        : <Row>
            <Col className="text-center">
                {/* Fleet mode dashboard */}
                {t('Welcome to the fleet tyre portal')}<br/><br/>
                <Button size="lg" onClick={goToFleet}><FontAwesomeIcon icon={fa.faCar}/> {t('Consult vehicles')}</Button>&nbsp;
                <Button size="lg" onClick={goToTyres}><FontAwesomeIcon icon={TyreIcon}/> {t('Consult tyres')}</Button>
                <Row className="justify-content-md-center mt-3">
                    <Col md={4} sm={12}>
                        <HomepagePieChart tyres={tyres}/>
                    </Col>
                </Row>
            </Col>
        </Row>

    )
}

export default withNamespaces()(Homepage)
        