import React, { useState, useEffect } from 'react'
import { Form, Col, Row, Card, Button } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next';
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'

function EditTransportForm({ transportMethod, removeTransport, onEdit, t }) {

    const [transportData,setTransportData] = useState(transportMethod)
    const handleChange = (value,fieldName) => {
        if (fieldName === 'operationDays') {
            value = value.sort((a,b) => a.value - b.value)
        }
        if (value && typeof(value) === "string") {
            if (value === "true") { value = true }
            if (value === "false") { value = false }
        }
        setTransportData({...transportData, [fieldName] : value })
    }

    const dayOptions = [
        { value: 0, label: 'Sunday' },
        { value: 1, label: 'Monday' },
        { value: 2, label: 'Tuesday' },
        { value: 3, label: 'Wednesday' },
        { value: 4, label: 'Thursday' },
        { value: 5, label: 'Friday' },
        { value: 6, label: 'Saturday' },
    ]

    const [collapse,setCollapse] = useState(transportData.newlyAdded ? false : true)

    useEffect(() => {
        onEdit(transportData)
    }, [transportData])

    async function handleRemove() {
        if (!transportData.newlyAdded) {
            const confirmation = await window.confirmation(t('Remove transport?'))
            if (confirmation) {
                removeTransport()
            }
        } else {
            removeTransport()
        }

    }


    return (
        <Card className="mt-2" >
            <Card.Header className="bg-light pointer" onClick={() => setCollapse(!collapse)}>
                <b className="d-inline-block mt-1"><FontAwesomeIcon icon={fa.faTruck}/> {transportData.name} </b>
                <span className="mt-1 float-end " >
                    <Button size="sm" variant="outline-primary">
                    {collapse ? t('Expand') : t('Collapse')} <FontAwesomeIcon icon={collapse ? fa.faChevronCircleDown : fa.faChevronCircleUp}/>
                    </Button>
                    &nbsp;
                    <Button size="sm" variant="outline-danger" onClick={() => handleRemove()}>
                        <FontAwesomeIcon icon={fa.faTrash}/>
                    </Button>
                </span>

            </Card.Header>
            <Card.Body className={collapse ? 'collapse' : ''}>
                <Row>
                    <Form.Group as={Col} md={3} className="mt-2">
                        <Form.Label>{t('Transport method name')}</Form.Label>
                        <Form.Control autoComplete="off" type="text" name="name" value={transportData.name} onChange={e => handleChange(e.target.value, e.target.name)} required />
                    </Form.Group>  
                    <Form.Group as={Col} md={3} className="mt-2">
                        <Form.Label>{t('Transport code')}</Form.Label>
                        <Form.Control autoComplete="off" type="text" name="code" value={transportData.code} onChange={e => handleChange(e.target.value, e.target.name)} required />
                    </Form.Group>   
                    <Form.Group as={Col} md={3} className="mt-2">
                        <Form.Label>{t('Default method')}</Form.Label>
                        <select value={transportData.default || false} onChange={e => handleChange(e.target.value, e.target.name)} name="default" className="form-control">
                            <option value={true}>{t('Yes')}</option>
                            <option value={false}>{t('No')}</option>
                        </select>
                    </Form.Group>                            
                </Row>                            
                <Row>
                    <Form.Group as={Col} className="mt-2">
                        <Form.Label>{t('Description Dutch')}</Form.Label>
                        <Form.Control autoComplete="off" type="text" name="description_nl" value={transportData.description_nl} onChange={e => handleChange(e.target.value, e.target.name)} required />
                    </Form.Group>
                    <Form.Group as={Col} className="mt-2">
                        <Form.Label>{t('Description French')}</Form.Label>
                        <Form.Control autoComplete="off" type="text" name="description_fr" value={transportData.description_fr} onChange={e => handleChange(e.target.value, e.target.name)} required />
                    </Form.Group>
                    <Form.Group as={Col} className="mt-2">
                        <Form.Label>{t('Description English')}</Form.Label>
                        <Form.Control autoComplete="off" type="text" name="description_en" value={transportData.description_en} onChange={e => handleChange(e.target.value, e.target.name)} required />
                    </Form.Group>
                    <Form.Group as={Col} className="mt-2">
                        <Form.Label>{t('Description German')}</Form.Label>
                        <Form.Control autoComplete="off" type="text" name="description_de" value={transportData.description_de} onChange={e => handleChange(e.target.value, e.target.name)} required />
                    </Form.Group>
                </Row>
                <Row className="mt-2">
                    <Form.Group as={Col} md={4}>
                        <Form.Label>{t('Operating days')}</Form.Label>
                        <Select name="operationDays" value={transportData.operationDays} onChange={(e) => handleChange(e, 'operationDays')} isMulti={true} options={dayOptions}></Select>
                    </Form.Group>
                    <Form.Group as={Col} md={2}>
                        <Form.Label>{t('Advance days')}</Form.Label>
                        <Form.Control autoComplete="off" type="number" required step="1" value={transportData.advanceDays} onChange={e => handleChange(e.target.value, e.target.name)} name="advanceDays"></Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md={2}>
                        <Form.Label>{t('Cut-off hour')}</Form.Label>
                        <Form.Control autoComplete="off" type="time" value={transportData.cutoffHour} onChange={e => handleChange(e.target.value, e.target.name)} name="cutoffHour"></Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md={2}>
                        <Form.Label>{t('Operations per day')}</Form.Label>
                        <Form.Control autoComplete="off" type="number" required step="1" value={transportData.operationsPerDay} onChange={e => handleChange(e.target.value, e.target.name)} name="operationsPerDay"></Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md={2}>
                        <Form.Label>{t('Tyres per day')}</Form.Label>
                        <Form.Control autoComplete="off" type="number" required step="1" value={transportData.tyresPerDay} onChange={e => handleChange(e.target.value, e.target.name)} name="tyresPerDay"></Form.Control>
                    </Form.Group>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default withNamespaces()(EditTransportForm)