  const initialState =  {
    isAuthenticated: false,
    username: undefined,
    fullname: undefined,
    type: undefined,
    lang: undefined,
    lang_iso : undefined
  };

  function generateIsoLang(tsLang) {
    switch(tsLang) {
      case 'dut':
        return 'nl'
      case 'fre':
        return 'fr'
      case 'eng':
        return 'en'
      case 'ger':
        return 'de'
      default:
        return undefined
    }
  }

export default (user = initialState , action) => {
    switch (action.type) {
        case 'USER_LOGIN':
            return { 
              isAuthenticated : true, 
              username : action.payload.username, 
              fullname: action.payload.fullname, 
              type: action.payload.type, 
              lang: action.payload.lang,
              lang_iso : generateIsoLang(action.payload.lang)
            }
        case 'USER_LOGOUT':
            return {...initialState};
        default:
            return user;
    }
}