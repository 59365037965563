import React, { useEffect, useState } from 'react'
import {Row, Col, Badge, Button, Form, InputGroup, Card } from 'react-bootstrap'
import { findCustomerbyName } from '../../../api/hoteladmin'
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import * as fleet_actions from '../../../actions/fleetadmin'
import { useDispatch } from 'react-redux'

function CustomerForm({t}) {

    const dispatch = useDispatch()

    const [lookupString,setLookupString] = useState(undefined)
    const [customerOptions,setcustomerOptions] = useState([])
    const [searching, setSearching] = useState(false)
    const adminFleetStore = useSelector(store => store.AdminFleet)
    async function lookupCustomer(e) {
        e.preventDefault()
        setSearching(true)
        try {
            const fetchedOptions = await findCustomerbyName(lookupString)
            setcustomerOptions(fetchedOptions.data)
        } catch(e) {
            window.handleError(e)
        }
        setSearching(false)
    }

    async function setCustomer(customer) {
        await dispatch(fleet_actions.setCustomer(customer))
        localStorage.setItem('last_used_fleet_customer_seq_id', customer.seq_id[0]);
        localStorage.setItem('last_used_fleet_customer_name', customer.name[0]);
    }

    async function clearCustomer() {
        await dispatch({type: 'ADMIN_FLEET_CLEAR'})
        localStorage.removeItem('last_used_fleet_customer_seq_id');
        localStorage.removeItem('last_used_fleet_customer_name');
    }

    //on page load, retrieve last used fleet customer and set (stored in localstorage)
    useEffect(() => {
        const seqid = localStorage.getItem('last_used_fleet_customer_seq_id')
        const name = localStorage.getItem('last_used_fleet_customer_name')
        if (seqid && name) {
            setSearching(true)
            setCustomer({name: [name], seq_id: [seqid]})
            setSearching(false)
        }
    },[])

  return (
    <>
    {
        adminFleetStore.seq_id ? <>
            <Row>
            <Col md={6}>
                <InputGroup className="mb-3">
                    <InputGroup.Text >
                        <FontAwesomeIcon icon={fa.faUser}/>
                    </InputGroup.Text>
                    <Form.Control disabled value={adminFleetStore.name}/>
                    <Button variant='outline-dark' type="submit" onClick={() => clearCustomer()} disabled={searching}>
                        <FontAwesomeIcon icon={fa.faTimes}/> {t('Clear')}
                    </Button>
                </InputGroup>
            </Col>
            </Row>
        </> : <>
        <Row>
            <Col md={6}>
            <form onSubmit={lookupCustomer}>
                <InputGroup className="mb-3">
                    <InputGroup.Text >
                        <FontAwesomeIcon icon={fa.faUser}/>
                    </InputGroup.Text>
                    <Form.Control required value={lookupString} onChange={(e) => setLookupString(e.target.value)} placeholder={t('Search for customer...')}/>
                    <Button type="submit" disabled={searching}>
                        {searching ? 
                            <><FontAwesomeIcon icon={fa.faSpinner} className="spinner"/> {t('Searching')}</> :
                            <><FontAwesomeIcon icon={fa.faSearch}/> {t('Search')}</>
                        }
                    </Button>
                </InputGroup>
            </form>
            </Col>
        </Row>
        <Row>
            {customerOptions.length > 0 ? customerOptions.map(customer => <Col md={12} key={customer.seq_id[0]}>
                <Card as={Col} md={6} className="mt-1 pointer" 
                        onClick={() => setCustomer(customer)}
                        onMouseOver={(e) => e.target.classList.add('border-primary')} 
                        onMouseOut={(e) => e.target.classList.remove('border-primary')} >
                    <Card.Body style={{pointerEvents: 'none'}} as={Row}>
                        <Col md={11}>
                            <Badge>{customer.seq_id[0]}</Badge> <b>{customer.name[0]}</b>&nbsp;
                            <small>{customer.street[0]}, {customer.zipcode[0]} {customer.city[0]}</small>
                        </Col>
                        <Col md={1}><FontAwesomeIcon icon={fa.faChevronRight}/></Col>
                    </Card.Body>
                </Card>
            </Col>) : ''}
        </Row>
        </>
    }
    
    </>
  )
}

export default withNamespaces()(CustomerForm)