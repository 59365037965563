import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { loadFleetAndTyres } from '../../actions/vehicles'
import { Form, Table, Button, Row, Col } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NewVehicleModal from './newVehicleModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import VehicleIcon from '../common/vehicleIcon'

function VehicleOverview({ t }) {

    function goToWizard() {
        navigate('/wizard/step1')
    }

    const loaderCell = (width) => {  
    
        const cellWidth = width ? (width + 'px') : '100%'
        return <ContentLoader 
            speed={2}
            width={width}
            height={25}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb">
            <rect x="0" y="0" rx="5" ry="5" width={cellWidth} height="100%" />
        </ContentLoader>
    }

    const RowLoader = function(i) {
    return <tr key={i}>
        <td>{loaderCell(25)}</td>
        <td>{loaderCell(200)}</td>
        <td>{loaderCell(200)}</td>
        <td>{loaderCell(200)}</td>
        <td>{loaderCell(200)}</td>
    </tr>
    }

    const TablePlaceHolder = function() {
        let rowPlaceHolders = []
        for (let i = 0; i < 11; i++) {
            rowPlaceHolders.push(RowLoader(i))
        }

        return <Table>
            <tbody>{rowPlaceHolders.map((x) => x)}</tbody>
        </Table>
    }


    const navigate = useNavigate()
    function goToVehicle(id) {
        navigate('../vehicle', {state: { id: id}})
    }
      
    const fleetState = useSelector((store) => store.Fleet)
    const hotelConfig = useSelector((store => store.Siteconfig))
    const dispatch = useDispatch()

    //if fleet state is missing or not laoded, dispatch load fleet
    useEffect(() => {
      if (!fleetState || !fleetState.loaded) {
        dispatch(loadFleetAndTyres())
      }
    }, [])

    useEffect(() => {
        if (fleetState.loaded) {
            setFilteredVehicles(fleetState?.vehicles?.map(vehicle => {
            return { 
                id : vehicle.flt_id[0],
                license_plate : vehicle.license_plate[0],
                license_plate_w_icon: <><VehicleIcon type={vehicle.type[0]}/> {vehicle.license_plate[0]}</>,
                brand: vehicle.brand[0],
                description: vehicle.description[0],
                driver : extractDriverName(vehicle)
             }
         })) 
        }
    },[fleetState])

    const customStyles = {
        head : {
            style: {
                fontSize: '16px', // override the row height
            }
        },
        rows : {
            style: {
                fontSize: '16px', // override the row height
                cursor : 'pointer'
            }
        }
    }
    
    const tableColumns = [
        { name: t('Plate'), selector: row => row.license_plate_w_icon },
        { name: t('Brand'), selector: row => row.brand },
        { name: t('Description'), selector: row => row.description },
        { name: t('Driver'), selector: row => row.driver },
    ];

    //response from fleet listing contains driver_name
    //response from fleet update contains driver.name ...
    function extractDriverName (vehicle) {
        if (vehicle.driver_name) {
            return vehicle.driver_name[0]
        } else if (vehicle.driver) {
            if (vehicle.driver[0].name) {
                return vehicle.driver[0].name[0]
            } else {
                return ''
            }
        } else {
            return ''
        }
    }

    const vehicleRows = fleetState?.vehicles?.map(vehicle => {
       return { 
           id : vehicle.flt_id[0],
           license_plate : vehicle.license_plate[0],
           license_plate_w_icon: <><VehicleIcon type={vehicle.type[0]}/> {vehicle.license_plate[0]}</>,
           brand: vehicle.brand[0],
           description: vehicle.description[0],
           driver : extractDriverName(vehicle)
        }
    })

    const [filterValue, setFilterValue ] = useState('')

    const [filteredVehicles, setFilteredVehicles] = useState(vehicleRows)

    useEffect(() => {
        if (filterValue && filterValue !== '') {
            const inputDelay = setTimeout(() => {
                setFilteredVehicles(vehicleRows?.filter(vehicle => {
                    if (
                        vehicle.license_plate.toUpperCase().includes(filterValue.toUpperCase()) || 
                        vehicle.brand.toUpperCase().includes(filterValue.toUpperCase()) || 
                        vehicle.description.toUpperCase().includes(filterValue.toUpperCase()) || 
                        vehicle.driver.toUpperCase().includes(filterValue.toUpperCase())
                    ) {
                        return true
                    }
                }) )   
            }, 200)
            return () => clearTimeout(inputDelay) 
        }
    },[filterValue])



    const paginationComponentOptions = {
        rowsPerPageText: t('Vehicles per page'),
        rangeSeparatorText: t('of'),
    };

    return (
        <div>
            <h1>{t('Fleet overview')}</h1>
            {fleetState.loaded ? <>
                <Row>
                    <Col lg={4} md={6} xs={6}><Form.Control onChange={(e) => setFilterValue(e.target.value)} placeholder={t('Filter vehicles')}></Form.Control></Col>
                    <Col lg={8} md={6} xs={6}>
                        <span className="float-end">   
                            {
                                ['hotelonly','both'].includes(hotelConfig.mode) ? <>
                                    <Button onClick={() => goToWizard()}><FontAwesomeIcon icon={faPlus}/> {t('New operation')}</Button>&nbsp;
                                    <NewVehicleModal/>  
                                </> : ''
                            }                     
                                           
                        </span>                        
                    </Col>
                </Row>

                <div className="table-responsive mt-3">
                    <DataTable
                        onRowClicked={(row) => goToVehicle(row.id)}
                        noDataComponent = {<p className="pt-3 pb-2">{t('No vehicles to display.')}</p>}
                        paginationComponentOptions={paginationComponentOptions} 
                        customStyles={customStyles}
                        columns={tableColumns}
                        data={filteredVehicles}
                        highlightOnHover={true}
                        pagination={true}
                        striped={true}
                    />
                </div>
            </>
            : <TablePlaceHolder/> }

        </div>
    )
}

export default withNamespaces()(VehicleOverview)
