import React, {useState} from 'react';
import { Button, Form } from 'react-bootstrap';
import { updateTranslation, deleteTranslation } from '../../api/superuser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons';

function TranslationRow({translation, onDelete}) {

    const [translationValue, setTranslationValue] = useState(translation.value)

    function shortcodeToFull(language) {
        switch (language) {
            case 'dut': 
                return 'Dutch'
            case 'eng':
                return 'English'
            case 'fre':
                return 'French'
            case 'ger':
                return 'German'
            default:
                return 'Unknown'
        }
    }

    async function handleChange() {
        try {
            await updateTranslation(translation._id,translationValue)
            window.notify('success', 'Translation updated.', {autoClose: 1000, position: 'bottom-right', progress: 0,})
        } catch (e) {
            window.handleError(e)
        }
        
    }

    async function handleDeleteTranslation() {
        try {
            await deleteTranslation(translation._id)
            onDelete(translation._id)
            window.notify('success','Translation deleted')
        } catch (e) {
            window.handleError(e)
        }
    }

    return ( <tr>
        <td>{shortcodeToFull(translation.language)}</td>
        <td>{translation.namespace}</td>
        <td>{translation.key}</td>
        <td>
            <Form.Control 
                onFocus={e => e.target.select()}
                onBlur={() => handleChange()} as="textarea"onChange={e => setTranslationValue(e.target.value)} 
                size="sm" style={{width: 550}} 
                value={translationValue}/>
        </td>
        <td><Button onClick={() => handleDeleteTranslation()} variant="outline-danger" className="mt-2" size="sm"><FontAwesomeIcon icon={fa.faTrash}/></Button></td>
    </tr>
  )
}

export default TranslationRow