import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import HttpApi from 'i18next-http-backend';

const backendOptions = {
  loadPath: '/locale/{{lng}}/{{ns}}/translation.json',
  addPath: '/locale/{{lng}}/{{ns}}/translation.missing.json',
}

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .use(HttpApi)
  .init({
    backend: backendOptions,
    saveMissing : true,
    saveMissingTo: 'all',
    fallbackLng: false,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
export default i18n;