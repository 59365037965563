import React, { useState, useEffect } from 'react'
import { Form, Col, Row, Card, Button } from 'react-bootstrap'
import { useLocation } from 'react-router';
import { withNamespaces } from 'react-i18next';
import { getHotelConfig, editConfig, editConfigImage } from '../../../api/hoteladmin'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import EditTransportForm from './editTransportForm';
import EditServiceForm from './editServiceForm';
import {useSelector} from 'react-redux'
import Select from 'react-select'
function ShopConfig({t}) {
    const [formData, setFormData] = useState(undefined)
    const userState = useSelector((store) => store.User)
    const hotelConfig = useSelector((store) => store.Siteconfig)
    const location = useLocation();
    
    async function fetchConfigData() {
        try {
            const config_id = location?.state?.id
            const response = await getHotelConfig(config_id)
            setFormData(response?.data)
        } catch (e) {
            window.handleError(e)
        }
    }

    useEffect(async () => {
        fetchConfigData()
    }, [])

    const handleChange = (e) => {
        const copiedData = {...formData}
        let value = e.target.value
        if (value === "true") { value = true }
        if (value === "false") { value = false }
        copiedData[e.target.name] = value
        setFormData(copiedData)
    }

    const handleChangeSelect = (value,name) => {
        const copiedData = {...formData}
        copiedData[name] = value
        setFormData(copiedData)
    }

    const handleFile = async (e) => {

        if (e.target.files) {
            const toast =  window.loadingShow('Uploading file...')
            try {
                const fileData = {}
                fileData[e.target.name] = e.target.files[0]
                await editConfigImage(formData, fileData)
                window.loadingResolve(toast,'success',t('File changed.'))
                fetchConfigData()
            } catch (e) {
                window.loadingResolve(toast,'error',e)
            }
        } else {
            window.notify('error',t('No valid files selected.'))
        }

    }

    const dispatch = useDispatch()

    async function handleSubmit(e) {
        e.preventDefault()
        const toast =  window.loadingShow(t('Updating configuration...'))
        try {
            const updatedConfig = (await editConfig(formData)).data
            dispatch({ type: 'SITECONFIG_SET', payload: updatedConfig})
            window.loadingResolve(toast,'success',t('Shop config updated successfully.'))
        } catch (e) {
            window.loadingResolve(toast,'error',e)
        }
    }

    function addUrl() {
        const copiedUrls = [...formData.urls]
        copiedUrls.push("")
        setFormData({...formData,urls: copiedUrls})
    }

    function removeUrl(index) {
        let copiedUrls = [...formData.urls]
        copiedUrls.splice(index,1)
        setFormData({...formData,urls: copiedUrls})
    }

    function updateUrl(e,index) {
        const { value } = e.target;
        let copiedUrls = [...formData.urls]
        copiedUrls[index] = value
        setFormData({...formData,urls: copiedUrls})
    }    

    function addBlancTransport() {
        const copiedData = {...formData}
        copiedData.transportMethods.push({newlyAdded : true})
        setFormData(copiedData)
    }

    function addBlancService() {
        const copiedData = {...formData}
        copiedData.services.push({newlyAdded : true})
        setFormData(copiedData)
    }


    async function removeTransport(index) {
        const toast =  window.loadingShow(t('Removing transport...'))
        try {
            const copiedData = {...formData}
            copiedData.transportMethods.splice(index,1)
            const updatedConfig = (await editConfig(copiedData)).data
            dispatch({ type: 'SITECONFIG_SET', payload: updatedConfig})
            window.loadingResolve(toast,'success',t('Transport removed successfully.'))
        } catch(e) {
            window.loadingResolve(toast,'error',e)
        }
    }

    async function removeService(index) {
        const toast =  window.loadingShow(t('Removing service...'))
        try {
            const copiedData = {...formData}
            copiedData.services.splice(index,1)
            const updatedConfig = (await editConfig(copiedData)).data
            dispatch({ type: 'SITECONFIG_SET', payload: updatedConfig})
            window.loadingResolve(toast,'success',t('Service removed successfully.'))
        } catch(e) {
            window.loadingResolve(toast,'error',e)
        }
    }

    const exampleImageStyle = {
        maxHeight: '200px',
        maxWidth: '100%'
    }

    const docInsertExceptionOptions = [
        {value : 'to_stock',label: t('to_stock')},
        {value : 'to_customer',label: t('to_customer')},
        {value : 'to_waste',label: t('to_waste')},
    ]

    return (
        <div>

            {formData ? 
            <Form onSubmit={handleSubmit}>
                { userState.type === 'superuser' ? <Card><Card.Body>
                    <h1>Xerxes parameters</h1>
                        <Row>
                            <Form.Group as={Col} className="mt-2">
                                <Form.Label>Webstream Account</Form.Label>
                                <Form.Control autoComplete="off" type="text" name="webstreamAccount" value={formData.webstreamAccount} onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group as={Col} className="mt-2">
                                <Form.Label>Default site code</Form.Label>
                                <Form.Control autoComplete="off" type="text" name="default_site_code" value={formData.default_site_code} onChange={handleChange} required />
                            </Form.Group>
                        </Row>
                        <Form.Group className="mt-2">
                            <Form.Label>Website URL(s)</Form.Label> <Button size="sm" variant="outline-primary"  onClick={() => {addUrl()}}><FontAwesomeIcon icon={fa.faPlus}/> Add new</Button>
                            {formData.urls ? 
                                formData.urls.map((url,i) => {
                                    return <div key={i} className="d-flex mt-1">
                                        <Form.Control className="" autoComplete="off" type="text" value={url} onChange={(e) => updateUrl(e,i)} required /> &nbsp;
                                        <Button variant="outline-danger" className="ml-2" onClick={() => {removeUrl(i)}}><FontAwesomeIcon icon={fa.faTrashAlt}/></Button>
                                    </div>
                                })
                        : ''}
                        </Form.Group>
                        <Form.Group className="mt-2">
                            <Form.Label>Website mode</Form.Label>
                            <Form.Select autoComplete="off" type="text" name="mode" value={formData.mode} onChange={handleChange}>                            
                                <option value="hotelonly">Hotel only</option>
                                <option value="fleetonly">Fleet only</option>
                                <option value="both">Fleet &amp; Hotel</option>
                            </Form.Select>
                        </Form.Group>
                        </Card.Body>
                    </Card>
                    : ''}
            <h1>{t('Website config')}</h1>
            <Card className="mt-2">
                <Card.Body>
                    <h3><FontAwesomeIcon icon={fa.faCog}/> {t('General settings')}</h3>
                    <hr/>
                    <Form.Group className="">
                        <Form.Label>{t('Website name')}</Form.Label>
                        <Form.Control autoComplete="off" type="text" name="name" value={formData.name} onChange={handleChange} required />
                    </Form.Group>                    
                    <Form.Group  className="mt-2">
                        <Form.Label>{t('Administrator email')}</Form.Label>
                        <Form.Control autoComplete="off" type="email" name="adminEmail" value={formData.adminEmail} onChange={handleChange} required />
                    </Form.Group>
                    <Form.Label className="mt-2">{t('Images')}</Form.Label>
                    <Row>
                        <Col md={4} className="mt-2">
                            <Card>
                                <Card.Header>{t('Landing page image')}</Card.Header>
                                <Card.Body><img style={exampleImageStyle} src={formData.landinglogoPath}/></Card.Body>
                                <Card.Footer>
                                    <Form.Group className="mt-2">
                                        <Form.Control name="landinglogo" type="file" onChange={handleFile} />
                                    </Form.Group>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col md={4} className="mt-2">
                            <Card>
                                <Card.Header>{t('Navigation bar image')}</Card.Header>
                                <Card.Body><img style={exampleImageStyle} src={formData.navlogoPath}/></Card.Body>
                                <Card.Footer>
                                    <Form.Group className="mt-2">
                                        <Form.Control name="navlogo" type="file" onChange={handleFile} />
                                    </Form.Group>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col md={4} className="mt-2">
                            <Card>
                                <Card.Header>{t('Favicon image')}</Card.Header>
                                <Card.Body><img style={exampleImageStyle} src={formData.faviconPath}/></Card.Body>
                                <Card.Footer>
                                    <Form.Group className="mt-2">
                                        <Form.Control name="favicon" type="file" onChange={handleFile} />
                                    </Form.Group>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>            
                </Card.Body>
            </Card>
            <Card className="mt-2">
                <Card.Body>
                    <h3><FontAwesomeIcon icon={fa.faUsers}/> {t('User rights')}</h3>
                    <hr/>
                        {hotelConfig.mode !== 'fleetonly' ? <>
                        <Row>
                            <p className="mb-0"><b>{t('Unknown articles')}</b></p>
                            <Form.Group as={Col} md={4} className="mt-2">
                                
                                <Form.Label>{t('Allow submission of unknown articles')}</Form.Label>
                                <Form.Select value={formData.allowUnknownArticle} onChange={handleChange} name="allowUnknownArticle" className="form-control">
                                    <option value={true}>{t('Yes')}</option>
                                    <option value={false}>{t('No')}</option>
                                </Form.Select>
                            </Form.Group>
                            {formData.allowUnknownArticle === 'true' || formData.allowUnknownArticle === true  ? <>
                            <Form.Group as={Col} md={2} className="mt-2">
                                <Form.Label>{t('Winter article ID')}</Form.Label>
                                <Form.Control autoComplete="off" type="number" min="1" step="1" name="unknownWinterArticleId" value={formData.unknownWinterArticleId} onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group as={Col} md={2} className="mt-2">
                                <Form.Label>{t('Summer article ID')}</Form.Label>
                                <Form.Control autoComplete="off" type="number" min="1" step="1" name="unknownSummerArticleId" value={formData.unknownSummerArticleId} onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group as={Col} md={2} className="mt-2">
                                <Form.Label>{t('All Season article ID')}</Form.Label>
                                <Form.Control autoComplete="off" type="number" min="1" step="1" name="unknownAllSeasonArticleId" value={formData.unknownAllSeasonArticleId} onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group as={Col} md={2} className="mt-2">
                                <Form.Label>{t('Snow article ID')}</Form.Label>
                                <Form.Control autoComplete="off" type="number" min="1" step="1" name="unknownSnowArticleId" value={formData.unknownSnowArticleId} onChange={handleChange} />
                            </Form.Group>
                            </> : ''}
                        </Row>
                        <Row>
                            <p className="mb-0 mt-2"><b>{t('Stock parameters')}</b></p>
                            <Form.Group as={Col} md={4} className="mt-2">
                                <Form.Label>{t('Allow detailed stock report')}</Form.Label>
                                <Form.Select value={formData.allowDetailedStockReport} onChange={handleChange} name="allowDetailedStockReport" className="form-control">
                                    <option value={true}>{t('Yes')}</option>
                                    <option value={false}>{t('No')}</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md={4} className="mt-2">
                                <Form.Label>{t('Allow profile depth insertion')}</Form.Label>
                                <Form.Select value={formData.allowProfileDepth} onChange={handleChange} name="allowProfileDepth" className="form-control">
                                    <option value={true}>{t('Yes')}</option>
                                    <option value={false}>{t('No')}</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md={4} className="mt-2">
                                <Form.Label>{t('Allow tachometer insertion')}</Form.Label>
                                <Form.Select value={formData.allowTachometer} onChange={handleChange} name="allowTachometer" className="form-control">
                                    <option value={true}>{t('Yes')}</option>
                                    <option value={false}>{t('No')}</option>
                                </Form.Select>
                            </Form.Group>
                        </Row>
                            </>: ''}

                        <Row>
                            <p className="mb-0 mt-2"><b>{t('Visibility options')}</b></p>
                            <Form.Group as={Col} md={4} className="mt-2">
                                <Form.Label>{t('Show tyres on vehicle')}</Form.Label>
                                <Form.Select value={formData.showTyresOnVehicle} onChange={handleChange} name="showTyresOnVehicle" className="form-control">
                                    <option value={true}>{t('Yes')}</option>
                                    <option value={false}>{t('No')}</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md={4} className="mt-2">
                                <Form.Label>{t('Show tyres at customer')}</Form.Label>
                                <Form.Select value={formData.showTyresAtCustomer} onChange={handleChange} name="showTyresAtCustomer" className="form-control">
                                    <option value={true}>{t('Yes')}</option>
                                    <option value={false}>{t('No')}</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md={4} className="mt-2">
                                <Form.Label>{t('Show waste tyres')}</Form.Label>
                                <Form.Select value={formData.showWasteTyres} onChange={handleChange} name="showWasteTyres" className="form-control">
                                    <option value={true}>{t('Yes')}</option>
                                    <option value={false}>{t('No')}</option>
                                </Form.Select>
                            </Form.Group>
                        </Row>
                    </Card.Body>
                </Card>
                {hotelConfig.mode !== 'fleetonly' ? <>
                <Card className="mt-2">
                    <Card.Body>
                        <h3><FontAwesomeIcon icon={fa.faFileAlt}/> {t('Invoicing')}</h3>
                        <hr/>
                        <Row>
                            <Form.Group as={Col} md={2} className="mt-2">
                                <Form.Label>{t('Invoicing quantity')}</Form.Label>
                                <Form.Select value={formData.invoicing_quantity} onChange={handleChange} name="invoicing_quantity" className="form-control">
                                    <option value='per_set'>{t('Per set')}</option>
                                    <option value='per_tyre'>{t('Per tyre')}</option>
                                </Form.Select>
                            </Form.Group> 
                            <Form.Group as={Col} md={2} className="mt-2">
                                <Form.Label>{t('Invoicing mode')}</Form.Label>
                                <Form.Select value={formData.invoicing_mode} onChange={handleChange} name="invoicing_mode" className="form-control">
                                    <option value='per_day'>{t('Per day')}</option>
                                    <option value='per_period'>{t('Per period')}</option>
                                </Form.Select>
                            </Form.Group> 
                            <Form.Group as={Col} md={2} className="mt-2">
                                <Form.Label>{t('Default invoicing period (days)')}</Form.Label>
                                <Form.Control value={formData.invoicing_period} onChange={handleChange} name="invoicing_period" type="number" step="0" className="form-control"/>
                            </Form.Group> 
                            <Form.Group as={Col} md={2} className="mt-2">
                                <Form.Label>{t('Article ID')}</Form.Label>
                                <Form.Control value={formData.invoicing_article_id} onChange={handleChange} name="invoicing_article_id" type="number" step="0" className="form-control"/>
                            </Form.Group>    
                            <Form.Group as={Col} md={2} className="mt-2">
                                <Form.Label>{t('Document insert type')}</Form.Label>
                                <Form.Select value={formData.invoicing_document_type} onChange={handleChange} name="invoicing_document_type" className="form-control">
                                    <option value={'reservation'}>{t('Reservation')}</option>
                                    <option value={'worknote'}>{t('Worknote')}</option>
                                    <option value={'deliverynote'}>{t('Deliverynote')}</option>
                                </Form.Select>
                            </Form.Group>                                                      
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="mt-2">
                    <Card.Body>
                        <h3><FontAwesomeIcon icon={fa.faBarcode}/> {t('Intake')}</h3>
                        <hr/>
                        <Row>
                            <Form.Group as={Col} md={3} className="mt-2">
                                <Form.Label>{t('Auto process when fully scanned')}</Form.Label>
                                <Form.Select value={formData.autoProcessIntakeOnFullScan} onChange={handleChange} name="autoProcessIntakeOnFullScan" className="form-control">
                                    <option value={true}>{t('Yes')}</option>
                                    <option value={false}>{t('No')}</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md={3} className="mt-2">
                                <Form.Label>{t('Document insert')}</Form.Label>
                                <Form.Select value={formData.doDocInsert || false} onChange={handleChange} name="doDocInsert" className="form-control">
                                    <option value={true}>{t('Yes')}</option>
                                    <option value={false}>{t('No')}</option>
                                </Form.Select>
                            </Form.Group>
                            {formData.doDocInsert ? 
                            <Form.Group as={Col} md={3} className="mt-2">
                                <Form.Label>{t('Document insert type')}</Form.Label>
                                <Form.Select value={formData.docInsertType} onChange={handleChange} name="docInsertType" className="form-control">
                                    <option value={'reservation'}>{t('Reservation')}</option>
                                    <option value={'worknote'}>{t('Worknote')}</option>
                                    <option value={'deliverynote'}>{t('Deliverynote')}</option>
                                </Form.Select>
                            </Form.Group> 
                            : ''}
                            {formData.doDocInsert ? 
                            <Form.Group as={Col} md={3} className="mt-2">
                                <Form.Label>{t('Document exceptions')}</Form.Label>
                                <Select options={docInsertExceptionOptions} value={formData.docInsertExceptions} onChange={(value) => handleChangeSelect(value, 'docInsertExceptions')} isMulti={true}>
                                    <option value={'to_stock'}>{t('to_stock')}</option>
                                    <option value={'to_customer'}>{t('to_customer')}</option>
                                    <option value={'to_waste'}>{t('to_waste')}</option>
                                </Select>
                            </Form.Group> 
                            : ''}
                
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="mt-2">
                    <Card.Body>
                        <h3><FontAwesomeIcon icon={fa.faTruckLoading}/> {t('Transport methods')}</h3>
                        <hr/>
                        { formData.transportMethods?.length > 0 ? 
                            formData.transportMethods.map((transportMethod,i) => <EditTransportForm key={transportMethod._id} removeTransport={() => removeTransport(i)} transportMethod={transportMethod} 
                            onEdit={editedMethod => { 
                                    const copiedData = {...formData}; 
                                    copiedData.transportMethods[i] = editedMethod; 
                                    setFormData(copiedData); 
                                }
                            } />)
                        : ''}
                        <Button variant="outline-primary" onClick={() => addBlancTransport() } className="mt-2"><FontAwesomeIcon icon={fa.faPlus}/> {t('Add transport method')}...</Button>
                    </Card.Body>
                </Card>
                <Card className="mt-2">
                    <Card.Body>
                        <h3><FontAwesomeIcon icon={fa.faWrench}/> {t('Services')}</h3>
                        <hr/>
                        { formData.services?.length > 0 ? 
                            formData.services.map((service,i) => <EditServiceForm key={service._id} removeService={() => removeService(i)} service={service} 
                            onEdit={editedService => { 
                                    const copiedData = {...formData}; 
                                    copiedData.services[i] = editedService; 
                                    setFormData(copiedData); 
                                }
                            } />)
                        : ''}
                        <Button variant="outline-primary" onClick={() => addBlancService() } className="mt-2"><FontAwesomeIcon icon={fa.faPlus}/> {t('Add service')}...</Button>
                    </Card.Body>
                </Card>
            </>: ''}

            <Button type="submit" className="mt-2"><FontAwesomeIcon icon={fa.faSave}/> {t('Save changes')}</Button>
            </Form>
            
            : 'Loading config.'}
        </div>
    )
}

export default withNamespaces()(ShopConfig)
