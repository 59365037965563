import React, { useState, useEffect } from 'react';
import { Row, Col, Form, InputGroup } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { loadFleet } from '../../../actions/fleetadmin';
import { useDispatch } from 'react-redux';
import VehicleIcon from '../../common/vehicleIcon';
import NewVehicleModal from '../../fleetpage/newVehicleModal'

function FleetTable({t}) {

    const customStyles = {
        head : {
            style: {
                fontSize: '16px', // override the row height
            }
        },
        rows : {
            style: {
                fontSize: '16px', // override the row height
                cursor : 'pointer'
            }
        }
    }

    const tableColumns = [
        { name: t('Plate'), selector: row => row.license_plate_w_icon },
        { name: t('Number'), selector: row => row.number },
        { name: t('Brand'), selector: row => row.brand },
        { name: t('Description'), selector: row => row.description },
        { name: t('Driver'), selector: row => row.driver },
    ];

    const paginationComponentOptions = {
        rowsPerPageText: t('Vehicles per page'),
        rangeSeparatorText: t('of'),
    };

    const adminFleetStore = useSelector(store => store.AdminFleet)
    const [filteredVehicles,setFilteredVehicles] = useState(adminFleetStore.vehicles || [])
    const dispatch = useDispatch()

    useEffect(() => {
        if (adminFleetStore.seq_id) {
            dispatch(loadFleet(adminFleetStore.seq_id))
        }
    },[adminFleetStore.seq_id])

    function extractDriverName (vehicle) {
        if (vehicle.driver_name) {
            return vehicle.driver_name[0]
        } else if (vehicle.driver) {
            if (vehicle.driver[0].name) {
                return vehicle.driver[0].name[0]
            } else {
                return ''
            }
        } else {
            return ''
        }
    }

    function transformVehicleArray(vehicles) {
        return vehicles?.map(vehicle => {
            return { 
                id : vehicle.flt_id[0],
                license_plate : vehicle.license_plate[0],
                license_plate_w_icon: <><VehicleIcon type={vehicle.type[0]}/> {vehicle.license_plate[0]}</>,
                brand: vehicle.brand[0],
                number : vehicle.number[0],
                description: vehicle.description[0],
                driver : extractDriverName(vehicle),
                vehicle: vehicle
             }
         })
     }

    useEffect(() => {
        if (adminFleetStore.loaded) {
            setFilteredVehicles(transformVehicleArray(adminFleetStore.vehicles))
        }
    },[adminFleetStore.vehicles])

    /* STRING VALUE FILTER */

    const vehicleRows = transformVehicleArray(adminFleetStore?.vehicles)

    const [filterValue, setFilterValue] = useState(undefined)
    useEffect(() => {
        if (filterValue && filterValue !== '') {
            const inputDelay = setTimeout(() => {
                setFilteredVehicles(vehicleRows?.filter(vehicle => {
                    if (
                        vehicle.license_plate.toUpperCase().includes(filterValue.toUpperCase()) || 
                        vehicle.brand.toUpperCase().includes(filterValue.toUpperCase()) || 
                        vehicle.description.toUpperCase().includes(filterValue.toUpperCase()) || 
                        vehicle.driver.toUpperCase().includes(filterValue.toUpperCase()) ||
                        vehicle.number.toUpperCase().includes(filterValue.toUpperCase())
                    ) {
                        return true
                    }
                }) )   
            }, 200)
            return () => clearTimeout(inputDelay) 
        } else {
            setFilteredVehicles(vehicleRows)
        }
    },[filterValue])

    async function pushVehicleToSelected(vehicle) {
        const existingFltIds = adminFleetStore.selectedVehicles.map(vehicle => vehicle.flt_id[0])
        //if vehicle already selected --> display message
        if (!existingFltIds.includes(vehicle.flt_id[0])) {
            //if amount of vehicles is above 9 --> remove first in array on confirmation
            if (adminFleetStore.selectedVehicles.length > 9) {
                const confirmation = await window.confirmation(<>{t(`Tab limit reached. Would you like to close vehicle`)} <b>{adminFleetStore.selectedVehicles[0].license_plate}</b> ?</>)
                if (confirmation && !existingFltIds.includes(vehicle.flt_id[0])) { 
                    dispatch({ type: 'ADMIN_SELECTED_REMOVE', payload: adminFleetStore.selectedVehicles[0]})
                    dispatch({ type: 'ADMIN_SELECTED_ADD', payload: vehicle})
                }
            } else {
                dispatch({ type: 'ADMIN_SELECTED_ADD', payload: vehicle})
            }
        }
        dispatch({type: 'ADMIN_SET_ACTIVE_VEHICLE', payload: vehicle.flt_id[0]})
    }

  return (
    adminFleetStore.seq_id ? 
        adminFleetStore.loaded ?
        <>  
            <Row>
                <Col md={6}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text >
                            <FontAwesomeIcon icon={fa.faCar}/>
                        </InputGroup.Text>
                        <Form.Control placeholder={t('Filter vehicles') + '...'} value={filterValue} onChange={e => setFilterValue(e.target.value)}/>
                    </InputGroup>
                </Col>
                <Col>
                    <span className="float-end"><NewVehicleModal fleetAdminMode/></span>
                </Col>
            </Row>
            <DataTable
                noDataComponent = {<p className="pt-3 pb-2">{t('No vehicles to display.')}</p>}
                paginationComponentOptions={paginationComponentOptions} 
                onRowClicked={(row) => pushVehicleToSelected(row.vehicle)}
                customStyles={customStyles}
                columns={tableColumns}
                data={filteredVehicles}
                highlightOnHover={true}
                pagination={true}
                striped={true}
            />
        </> : <div className="opacity-50"><FontAwesomeIcon icon={fa.faSpinner} className="spinner"/> {t('Loading fleet')}</div>
    : ''
  )
}

export default withNamespaces()(FleetTable)