import React from 'react'
import { withNamespaces } from 'react-i18next';
import { Button } from 'react-bootstrap'
import * as fa from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useSelector} from 'react-redux';
import moment from 'moment'

function OperationsToClipboardButton({className,t,operations}) {
    const siteConfigState = useSelector((store) => store.Siteconfig)
    const userState = useSelector((store) => store.User)  
    const transportMethods = siteConfigState.transportMethods
    const services = siteConfigState.services

    function copyToClipboard() {
        try {

            //set of fields with their datasource and labels
            let fields = [ 
                { source: 'tyres', name: 'db_id', label: 'Tyre ID' },
                { source: 'tyres', name: 'position', label: 'Position' },
                { source: 'tyres', name: 'description', label: 'Description' },
                { source: 'tyres', name: 'stock_rack', label: 'Rack' },
                { source: 'tyres', name: 'profile_depth', label: 'Profile depth' },
                { source: 'tyres', name: 'tyre_set', label: 'Tyre set' },
                { source: 'operation', name: 'license_plate', label: 'License plate' },
                { source: 'operation', name: 'customer', label: 'Customer' },
                { source: 'operation', name: 'type', label: 'Operation type', translateData : true },
                { source: 'operation', name: 'services', label: 'Services' },
                { source: 'operation', name: 'deliveryDate', label: 'Delivery date' },
                { source: 'operation', name: 'transport', label: 'Transport' },
                { source: 'operation', name: 'updated', label: 'Submission date' },
                { source: 'operation', name: 'remarks', label: 'Remarks' },

            ]

            //init string with translated label names on first row
            let copyString = fields.map(field => `${t(field.label)}`).join('\t') + '\n'
            
            //for every operation>tyre create a line
            operations.forEach(operation => {

                const copiedOp = {...operation}

                //format some data here for correct display in text
                copiedOp.transport = transportMethods.filter(transportMethod => transportMethod._id == copiedOp.transport)[0]
                copiedOp.transport ? copiedOp.transport = copiedOp.transport[`description_${userState.lang_iso}`] : ''
                copiedOp.vehicle ? copiedOp.license_plate = copiedOp.vehicle.license_plate : undefined
                copiedOp.services = copiedOp.services.map(serviceId => services.filter((siteService) => siteService._id == serviceId)[0])
                copiedOp.services = copiedOp.services.map(service => service[`description_${userState.lang_iso}`]).join(',')

                copiedOp.customer = copiedOp.user.companyname
                console.log(copiedOp.deliveryDate)
                copiedOp.deliveryDate ? copiedOp.deliveryDate = moment(copiedOp.deliveryDate).format('DD/MM/YYYY') : '';
                copiedOp.updated ? copiedOp.updated = moment(operation.updated).format('DD/MM/YYYY HH:mm:ss') : '';
                console.log()
                //loop through tyres and push
                copiedOp.tyres.forEach(tyre => {
                    console.log(tyre.tyre_set)
                    tyre.tyre_set && tyre.tyre_set[0] === 'setless' ? tyre.tyre_set[0] = '' : ''

                    fields.forEach(field => {
                        if (field.name == 'license_plate') {
                            console.log(copiedOp)
                            console.log('--->', copiedOp[field.name])
                        }
                        //if field is empty
                        //if a field is to be translated --> use t() on data component
                        if (field.source === 'operation') {
                            copyString+= `${copiedOp[field.name] ? (
                                field.translateData ? t(copiedOp[field.name]) : copiedOp[field.name]
                            ) : ''}\t`
                        } else if (field.source === 'tyres') {
                            copyString+= `${tyre[field.name] ? (
                                field.translateData ? t(tyre[field.name][0]) : tyre[field.name][0]
                            ) : ''}\t`
                        }
                    })
                    copyString+= '\n'
                })
            })
            navigator.clipboard.writeText(copyString)
            window.notify('success',t('Data copied'))
        } catch (e) {
            window.handleError(e)
        }

    }

  return (
    <Button variant="outline-secondary" onClick={() => copyToClipboard()} className={className}><FontAwesomeIcon icon={fa.faCopy}/> {t('Copy')}</Button>
  )
}

export default withNamespaces()(OperationsToClipboardButton)