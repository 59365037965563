const initialState =  {
    loaded : false,
    seq_id: undefined,
    name : undefined,
    vehicles : [],
    selectedVehicles : [],
    activeVehicle : undefined
}

export default (fleetState = initialState , action) => {
    switch (action.type) {
        case 'ADMIN_CUSTOMER_SET' :
            return {
                ...fleetState,
                seq_id : action.payload.seq_id,
                name : action.payload.name,
            }
        case 'ADMIN_FLEET_LOAD':
            return {
                ...fleetState,
                loaded: true,
                vehicles : action.payload.vehicles
            }
        case 'ADMIN_FLEET_ADD':
            return {
                ...fleetState,
                vehicles: [...fleetState.vehicles,action.payload]
            }
        case 'ADMIN_FLEET_CLEAR':
            return initialState;
        case 'ADMIN_FLEET_UPDATE':
            if (fleetState.vehicles.length > 0) {
                const copiedVehicles = [...fleetState.vehicles]
                const vehicleRecord = copiedVehicles.filter(vehicle => vehicle.flt_id[0] == action.payload.flt_id[0])[0]
                if (vehicleRecord) {
                    const index = copiedVehicles.indexOf(vehicleRecord)
                    copiedVehicles[index] = action.payload
                } else {
                    copiedVehicles.push(action.payload)
                }
                return {
                    ...fleetState,
                    vehicles : copiedVehicles
                }
            } else {
                return {...fleetState , vehicles : [action.payload] }
            }
        case 'ADMIN_FLEET_REMOVE':
            if (fleetState.vehicles.length > 0) {
                const copiedVehicles = [...fleetState.vehicles]
                copiedVehicles.forEach((vehicle,i) => {                    
                    if (vehicle.flt_id[0] == action.payload.flt_id) {
                        copiedVehicles.splice(i, 1);
                    }
                });
                return {
                    ...fleetState,
                    vehicles :copiedVehicles
                }
            }
            break;
        case 'ADMIN_SELECTED_ADD': {
            const existingVehicleIds = [...fleetState.selectedVehicles].map(vehicle => vehicle.flt_id[0])

            if (!existingVehicleIds.includes(action.payload.flt_id[0])) {
                return {
                    ...fleetState,
                    selectedVehicles: [...fleetState.selectedVehicles,action.payload]
                }
            } else {
                return {...fleetState}
            }
        }
        case 'ADMIN_SELECTED_REMOVE':
            if (fleetState.selectedVehicles.length > 0) {
                const copiedVehicles = [...fleetState.selectedVehicles]
                copiedVehicles.forEach((vehicle,i) => {                    
                    if (vehicle.flt_id[0] == action.payload.flt_id) {
                        copiedVehicles.splice(i, 1);
                    }
                });
                return {
                    ...fleetState,
                    selectedVehicles :copiedVehicles
                }
            }
            break;
            case 'ADMIN_SET_ACTIVE_VEHICLE':
                console.log('Setting active vehicle to ' + action.payload)
                return {
                    ...fleetState,
                    activeVehicle :action.payload
                }
        default:
            return fleetState;
    }
}