import React, {useState} from 'react'
import OperationTypeLabel from '../../common/operationTypeLabel';
import TyreTable from '../../tyres/tyreTable'
import { Button } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment'
import VehicleIcon from '../../common/vehicleIcon'
import {  cancelOperation, confirmOperation } from '../../../api/hoteladmin'
import { updateOperation } from '../../../actions/operations'


function SubmittedOperation({onUpdate, operation,hideDate,hideTransport,hideServices,t}) {

    const [showTyres,setShowTyres] = useState(false)

    function toggleTyres() {
        showTyres ? setShowTyres(false) : setShowTyres(true)
    }

    const hotelConfig = useSelector((store) => store.Siteconfig) 
    const navigate = useNavigate()
    function goToVehicle(id) {
        navigate('/fleet/vehicle', {state: { id: id}})
    }
    const userState = useSelector((store) => store.User)  
    const services = operation?.services?.map(operationService => hotelConfig?.services?.filter(hotelService => hotelService._id === operationService)[0])
    const transport = hotelConfig?.transportMethods?.filter(transportMethod => transportMethod._id === operation.transport)[0]

    const [loading,setLoading] = useState(false)

    async function cancel() {
        setLoading(true)
        try {
            const confirmation = await window.confirmation(t('Cancel operation?'))
            if (confirmation) {
                const toast = window.loadingShow(t('Canceling operation'))
                try {
                    await cancelOperation(operation)
                    onUpdate()
                    window.loadingResolve(toast,'success',t('Operation cancelled'))
                } catch(e) {
                    window.loadingResolve(toast,'error',e)
                }
            }
        } catch (e) {
            window.handleError(e)
        }
        setLoading(false)
    }

    async function approve() {
        setLoading(true)
        const scannedTyres = operation.tyres.filter(tyre => tyre.scanned)
        if (scannedTyres.length === operation.tyres.length) {
            const toast = window.loadingShow(t('Confirming operation'))
            try {
                await confirmOperation(operation)
                onUpdate()
                window.loadingResolve(toast,'success',t('Operation confirmed'))
            } catch (e) {
                window.loadingResolve(toast,'error',e)
            }
        } else {
            const confirmation = await window.confirmation(t('Not all tyres scanned, confirm anyway?'))
            if (confirmation) {
                const toast = window.loadingShow(t('Confirming operation'))
                try {
                    await confirmOperation(operation)
                    onUpdate()
                    window.loadingResolve(toast,'success',t('Operation confirmed'))
                } catch(e) {
                    window.loadingResolve(toast,'error',e)
                }
            }
        }
        setLoading(false)
    }

    const dispatch = useDispatch()

    async function undoSubmit() {
        try {
            setLoading(true)
            const copiedOperation = {...operation}
            copiedOperation["status"] = "pending"
            await dispatch(updateOperation(copiedOperation,true))
            onUpdate()
        } catch(e) {
            window.handleError(e)
            setLoading(false)
        }

    }

    const scannedTyres = operation.tyres.filter(tyre => tyre.scanned)
  return (
    <>
        <tr className="single-line">
            <td>{operation.user.companyname}</td>
            <td><OperationTypeLabel type={operation.type}/></td>
            <td>
                <b className="mt-2 text-primary pointer" onClick={() => goToVehicle(operation.flt_id)}><VehicleIcon type={operation.vehicle.type[0]}/> {operation.vehicle.license_plate[0]}</b>
            </td>
            <td className={scannedTyres.length === operation.tyres.length ? 'text-success' : 'text-primary'}><span className="pointer" onClick={() => toggleTyres()} ><FontAwesomeIcon icon={fa.faBarcode}/> {scannedTyres.length} / {operation.tyres.length}</span></td>
            {hideServices ? undefined : 
                <td>{services.map((service,i) => {
                    if (service) {
                        return <span key={i}>{(i ? ', ': '') + service[`description_${userState.lang_iso}`]} </span>
                    } else {
                        return <span className="opacity-50" key={i}>{(i ? ', ': '') + t('Unknown')} </span>
                    }
                })}</td>
            }
            {hideDate ? undefined : <td>{operation.deliveryDate ? moment(operation.deliveryDate).format('DD/MM/YYYY') : ''}</td>}
            {hideTransport ? undefined : <td>{transport ? transport[`description_${userState.lang_iso}`] : ''}</td>}
            <td>
                <small className="opacity-50">{operation.updated ? moment(operation.updated).format('DD/MM/YYYY HH:mm:ss') : ''}</small>
            </td>
            <td width="125">
                <Button disabled={loading} size="sm"onClick={() => undoSubmit()} variant="outline-secondary" ><FontAwesomeIcon icon={fa.faUndo}/> {t('Undo submit')}</Button>&nbsp;
                <Button disabled={loading} size="sm" onClick={() => approve()} variant="success"><FontAwesomeIcon icon={fa.faCheck}/></Button>&nbsp;
                <Button disabled={loading} size="sm" onClick={() => cancel()} variant="danger"><FontAwesomeIcon icon={fa.faTimes}/></Button>    
            </td>
        </tr>
        <tr className={`bg-light ${showTyres ? '' : 'collapse'}`}>
            <td colSpan={'100%'}><TyreTable showDepth={hotelConfig.allowProfileDepth} showScans className="bg-light" tyres={operation.tyres}/></td>
        </tr>
    </>
  )
}

export default withNamespaces()(SubmittedOperation)