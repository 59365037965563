import React, { useState, useEffect } from 'react'
import {Tabs, Tab, Badge} from 'react-bootstrap'
import { withNamespaces } from 'react-i18next'
import RecallTab from './recallTab';
import StorageTab from './storageTab';
import WasteTab from './wasteTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'

function SubmittedOperationsView({t,submittedOps,operationUpdated,theadStyle,tbodyStyle}) {

    console.log('render','submittedOps')

    const [recallOps,setRecallOps] = useState([])
    const [storageOps, setStorageOps] = useState([])
    const [wasteOps, setWasteOps] = useState([])

    useEffect(() => {
      setRecallOps(submittedOps.filter(op => op.type === 'to_customer'))
      setStorageOps(submittedOps.filter(op => op.type === 'to_stock'))
      setWasteOps(submittedOps.filter(op => op.type === 'to_waste'))
    },[submittedOps])

  return (
    <Tabs variant="pills" fill className="mb-0"  id="operation-tabs">
            <Tab tabClassName="tab-success my-1" eventKey="recall" title={<><FontAwesomeIcon icon={fa.faUser}/> {t('Recall operations')} <Badge bg="success" text="white">{recallOps.length}</Badge></>}>
              <RecallTab theadStyle={theadStyle} tbodyStyle={tbodyStyle} recallOps={recallOps} operationUpdated={operationUpdated}/>
            </Tab>
            <Tab tabClassName="tab-warning my-1" eventKey="storage" title={<><FontAwesomeIcon icon={fa.faWarehouse}/> {t('Storage operations')} <Badge bg="warning" text="white">{storageOps.length}</Badge></>}>
              <StorageTab theadStyle={theadStyle} tbodyStyle={tbodyStyle} storageOps={storageOps} operationUpdated={operationUpdated}/>
            </Tab>
            <Tab tabClassName="tab-danger my-1" eventKey="waste" title={<><FontAwesomeIcon icon={fa.faTrash}/> {t('Waste operations')} <Badge bg="danger" text="white">{wasteOps.length}</Badge></>}>
              <WasteTab theadStyle={theadStyle} tbodyStyle={tbodyStyle} wasteOps={wasteOps} operationUpdated={operationUpdated}/>
            </Tab>
    </Tabs>
  )
}

export default withNamespaces()(SubmittedOperationsView)