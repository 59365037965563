import React, { useEffect, useState } from 'react'
import { Button, Row, Col, Form, Card, Badge, Tab, Tabs, Table } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import { loadFleetAndTyres } from '../../actions/vehicles'
import { useSelector, useDispatch } from 'react-redux';
import CompletedOperation from './completedOperation';
import Recall from './recall'
import Storage from './storage'
import Disposal from './disposal';
import { useNavigate } from 'react-router';
import { loadOperations } from '../../actions/operations'
import { faUser, faWarehouse, faTrash, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment'

import { bookedTransportDays, completedBydate } from '../../api/operations'
import { useLocation } from 'react-router';



function OperationsOverview({ t }) {

    //set default active tab to pending or to what is passed in router state
    const location = useLocation()
    const defaultActiveTab = location?.state?.tab || 'pending'
    const fleetState = useSelector((store) => store.Fleet)
    const dispatch = useDispatch()

    //if fleet state is missing or not laoded, dispatch load fleet
    useEffect(() => {
      if (!fleetState || !fleetState.loaded) {
        dispatch(loadFleetAndTyres())
      }
      dispatch(loadOperations())
    }, [])

    const operations = useSelector((store) => store.Operations).operations

    const loaderCell = (width) => {  
        return <ContentLoader
            className={`mt-1 col-sm-${width}`} 
            speed={2}
            height={25}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb">
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
        </ContentLoader>
    }

    const RowLoader = function(i) {
    return <Card key={i} className="mt-2 bg-light"><Card.Body>
            <Row>
            {loaderCell(2)}
            {loaderCell(1)}
            {loaderCell(2)}
            {loaderCell(3)}
            {loaderCell(2)}
     
            </Row>
            <Row>
            {loaderCell(2)}
            {loaderCell(1)}
            {loaderCell(2)}
            {loaderCell(3)}
            {loaderCell(2)}
            {loaderCell(2)}
            </Row>

    </Card.Body></Card>
    }

    const OperationPlaceholder = function() {
        let rowPlaceHolders = []
        for (let i = 0; i < 6; i++) {
            rowPlaceHolders.push(RowLoader(i))
        }

        return <>
           {rowPlaceHolders.map((x) => x)}
        </>
    }

    const pendingOperations = operations.filter(o => o.status === 'pending')

    const newStorages = pendingOperations.filter(o => o.type === 'to_stock')
    const newRecalls = pendingOperations.filter(o => o.type === 'to_customer')
    const newDisposals = pendingOperations.filter(o => o.type === 'to_waste')
    const submittedOperations = operations.filter(o => o.status === 'submitted')
    const submittedStorages = submittedOperations.filter(o => o.type === 'to_stock')
    const submittedRecalls = submittedOperations.filter(o => o.type === 'to_customer').sort((a, b) => new Date(a.deliveryDate) - new Date(b.deliveryDate));
    const submittedDisposals = submittedOperations.filter(o => o.type === 'to_waste')

    const [completedOperations,setCompletedOperations] = useState([])
    
    //array of all booked days per transport method --> used to block dates in recalls
    const [bookedDays,setBookedDays] = useState([])

    useEffect(async () => {
        try {
            //fetch booked days on initial load
            setBookedDays((await bookedTransportDays()).data)
        } catch (e) {
            window.handleError(e)
        }
        
    },[])

    useEffect(() => {
        setCompletedOperations(operations.filter(o => o.status === 'confirmed' || o.status === 'cancelled').reverse())
    }, [operations])

    //fetch booked days every time recall submission is done
    async function recallSubmitted() {
        const data = (await bookedTransportDays()).data
        setBookedDays(data)
    }

    const navigate = useNavigate()

    function goToWizard() {
        navigate('/wizard/step1')
    }

    const [fromDate,setFromDate] = useState(moment().subtract(1,'month').format('YYYY-MM-DD'))
    const [tillDate,setTillDate] = useState(moment().format('YYYY-MM-DD'))
    const [dateButtonDisabled, setDateButtonDisabled] = useState(false)
    async function fetchHistoric() {
        setDateButtonDisabled(true)
        try {
            const completedOperationsBydate = (await completedBydate (moment(fromDate,'YYYY-MM-DD'),moment(tillDate, 'YYYY-MM-DD')))?.data
            setCompletedOperations(completedOperationsBydate)
        } catch (e) {
            window.handleError(e)
        }
        setDateButtonDisabled(false)
    }

    return (
        <div>
            <h1>{t('Operations overview')} <Button onClick={() => goToWizard()} className="float-end"><FontAwesomeIcon icon={faPlus}/> {t('New operation')}</Button></h1>
            {fleetState.loaded && operations ? <>
            <Tabs className="mb-0" defaultActiveKey={defaultActiveTab} id="operation-tabs">
                <Tab eventKey="pending" title={<>{t('Pending')} <Badge>{pendingOperations.length}</Badge></>}>
                <Card className="border-top-0">
                    <Card.Body>
                        {pendingOperations.length > 0 ? <>
                            {newRecalls.length > 0 ? <>
                                <h5 className="mt-3 text-success"><FontAwesomeIcon icon={faUser} /> {t('New recalls')}</h5>
                                {newRecalls.map(operation => <Recall key={operation._id} operationSubmitted={recallSubmitted} bookedDays={bookedDays} operation={operation}/>)}
                            </> : ''}
                            {newStorages.length > 0 ? <>
                                <h5 className="mt-3 text-warning"><FontAwesomeIcon className="" icon={faWarehouse} /> {t('New storages')}</h5>
                                {newStorages.map(operation => <Storage key={operation._id} operation={operation}/>)}
                            </>
                            : ''}
                            {newDisposals.length > 0 ? <>
                                <h5 className="mt-3 text-danger"><FontAwesomeIcon className="" icon={faTrash} /> {t('New disposals')}</h5>
                                {newDisposals.map(operation => <Disposal key={operation._id} operation={operation}/>)}
                            </>
                            : ''}
                        </> : 
                        <div className="text-center opacity-50">{t('No pending operations')}</div>}
                    </Card.Body>
                </Card>
                </Tab>
                <Tab eventKey="submitted" title={<>{t('Submitted')} <Badge>{submittedOperations.length}</Badge></>}>
                    <Card className="border-top-0">
                        <Card.Body>
                            {submittedOperations.length > 0 ? <>
                                { submittedRecalls.length > 0 ? <>
                                    <h6 className="mt-3 text-success"><FontAwesomeIcon icon={faUser} /> {t('Submitted recalls')}</h6>
                                    {submittedRecalls.map(operation => <Recall key={operation._id} operationSubmitted={recallSubmitted} operation={operation}/>)}
                                </> : ''}
                                { submittedStorages.length > 0 ? <>
                                    <h6 className="mt-3 text-warning"><FontAwesomeIcon className="" icon={faWarehouse} /> {t('Submitted storages')}</h6>
                                    {submittedStorages.map(operation => <Storage key={operation._id} operation={operation}/>)}
                                </>
                                : ''}
                                { submittedDisposals.length > 0 ? <>
                                    <h6 className="mt-3 text-danger"><FontAwesomeIcon className="" icon={faTrash} /> {t('Submitted disposals')}</h6>
                                    {submittedDisposals.map(operation => <Disposal key={operation._id} operation={operation}/>)}
                                </>
                                : ''}
                            </> : 
                            <div className="text-center opacity-50">{t('No submitted operations')}</div>}
                        </Card.Body>
                    </Card>
                </Tab>
                <Tab eventKey="completed" title={<>{t('Completed')} <Badge>{completedOperations.length}</Badge></>}>
                <Card className="border-top-0">
                    <Card.Body>
                    <Row>
                                <Col md={3}>
                                    <Form.Control value={fromDate} onChange={(e) => setFromDate(e.target.value)} type="date"></Form.Control></Col>
                                <Col md={3}>
                                    <Form.Control value={tillDate} onChange={(e) => setTillDate(e.target.value)} type="date"></Form.Control></Col>
                                <Col md={3}>
                                    <Button disabled={dateButtonDisabled} onClick={ () => fetchHistoric()}><FontAwesomeIcon icon={faSearch}/> {t('Get operations')}</Button>
                                </Col>
                            </Row>
                        {completedOperations.length > 0 ? <>

                            <Table className="table-hover bg-white p-1">
                                <thead>
                                    <tr >
                                        <th>{t('Vehicle')}</th>
                                        <th>{t('Operation')}</th>
                                        <th>{t('Tyres')}</th>
                                        <th>{t('Status')}</th>
                                        <th>{t('Date')}</th>
                                    </tr>
                                </thead>
                                <tbody style={{borderTop: '1px solid rgb(222, 226, 230)'}}>
                                    {completedOperations.map(operation => <CompletedOperation key={operation._id} operation={operation}/>)}
                                </tbody>
                            </Table>
                        </> : 
                        <div className="text-center opacity-50 mt-2">{t('No fullfilled operations')}</div>
                        }
                    </Card.Body>
                </Card>
                </Tab>
            </Tabs>



            </> : OperationPlaceholder()}
                    
        </div>
    )
}

export default withNamespaces()(OperationsOverview)
