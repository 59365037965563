import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next'
import TyreStockDetailsModal from './TyreStockDetailsModal'
import TyreActionButton from '../tyres/tyreActionButton'
import AddTyreModal from '../tyres/addTyreModal';
import TyreSet from '../tyres/tyreSet'

import { useSelector } from 'react-redux'

function VehicleTyreTable({vehicle, tyreSelection, wizardMode, status, t}) {
    const [tyres] = [vehicle.tyres[0].tyre]

    const [stockDetailTyre, setStockDetailTyre] = useState(undefined)
    const [showWasteItems, setShowWasteItems] = useState(false)


    const hotelConfig = useSelector((store) => store.Siteconfig)
    const [selectedTyres, setSelectedTyres] = useState([])
    const [selectedSet, setSelectedSet] = useState(undefined)
    const [toggledClearRows, setToggleClearRows] = useState(false);
    
    function unselectAllRows() {
        setToggleClearRows(true)
        setSelectedTyres([])
        setToggleClearRows(false)
    }

    const [filteredTyres, setFilteredTyres] = useState([])

    useEffect(() => {
        let filteredTyres 
        if (tyres && tyres.length > 0) {
            filteredTyres = tyres.filter(tyre => showWasteItems ? true : tyre.tyre_status[0] === 'waste' ? false : true)
            if (status) {
                filteredTyres = filteredTyres.filter(tyre => tyre.tyre_status[0] === status )
            }
    
            if (filteredTyres) {
                setFilteredTyres(filteredTyres)
            }
        } else {
            setFilteredTyres([])
        }


    }, [status, tyres, showWasteItems])

    const [tyreSets,setTyreSets] = useState([])

    function generateSets() {
        let setNames = []

        filteredTyres.forEach(tyre => {
            //fetch unique tyreset names
            if (!tyre.tyre_set) {
                tyre.tyre_set = ['setless']
            }

            if (!setNames.includes(tyre.tyre_set[0])) {
                setNames.push(tyre.tyre_set[0])
            }
        })

        let sets = []
        for (let i = 0; i < setNames.length; i++) {
            const setName = setNames[i]
            sets.push({
                name : setName,
                tyres : filteredTyres.filter(tyre => tyre.tyre_set? tyre.tyre_set[0] === setName : false)
            })
        }
        setTyreSets(sets)
    }

    //function to select tyres after addition
    function handleTyreSelect(tyresToSelect) {
        
        //unselect all checkboxes
        const checkedCheckboxes = document.querySelectorAll('.rdt_TableCell input[type=checkbox]:checked')
        checkedCheckboxes.forEach(checkbox => {
                checkbox.click()
        })
        setSelectedTyres(tyresToSelect) 

        setTimeout(() => {
            tyresToSelect.forEach(tyre => {
                document.getElementsByName(`select-row-${tyre.db_id[0]}`)[0].click();                           
            }) 
            
        }, 250)
     
    }

    useEffect(() => {
        generateSets()
    }, [filteredTyres])

    useEffect(() => {
        if (selectedTyres.length > 0) {
            const firstTyre = selectedTyres[0]
            const setName = firstTyre.tyre_set ? firstTyre.tyre_set[0] : 'setless'
            setSelectedSet(setName)
        } else {
            setSelectedSet(undefined)
        }
        //tyre selection prop for wizard
        if (tyreSelection) {
            tyreSelection(selectedTyres)
        }
    },[selectedTyres])

    return (
        <>
            <TyreStockDetailsModal tyre={stockDetailTyre}/>
            {!wizardMode && ['hotelonly','both'].includes(hotelConfig.mode) ? 
            <Row>
                <Col>
                    <AddTyreModal selectTyres={(tyres) => handleTyreSelect(tyres)} className="btn-success d-inline-block" vehicle={vehicle}/>&nbsp;
                    {selectedTyres.length > 0 ? <TyreActionButton className="d-inline-block pb-0" vehicle={vehicle} setSelectedTyres={setSelectedTyres} unselectRows={unselectAllRows} selectedTyres={selectedTyres}/> : ''}
                </Col>
            {hotelConfig.showWasteTyres ? 
                <Col>
                    <Form.Check 
                        className="float-end mb-4" id="show-waste-items" 
                        type='checkbox' value={showWasteItems} label={t('Show waste items')}
                        onChange={(e) => {setShowWasteItems(e.target.checked)}} />
                        
                </Col>
            : ''}
            </Row> : '' }
            <div className="table-responsive mt-0">
                { tyreSets.length > 0 ? tyreSets.map(set => 
                <TyreSet
                    clearRows={toggledClearRows}
                    stockDetailTyre={(tyre => setStockDetailTyre(tyre))} 
                    selectedTyres={(tyres) => setSelectedTyres(tyres)} 
                    vehicle={vehicle} 
                    selectedSet={selectedSet} 
                    tyreSet={set} 
                    key={set.name}/>) : ''}
            </div>
        </>
    )
}

export default withNamespaces()(VehicleTyreTable)
