import React, { useState, useEffect } from 'react'
import { Row, Col, Card, Form, Button, InputGroup } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import CustomersTable from './customersTable'
import { getInvoicingCustomers } from '../../../api/hoteladmin'
import SelectedCustomerTable from './selectedCustomerTable'
import FetchingPlaceholder from './fetchingPlaceholder'

function index({t}) {

    const hotelConfig = useSelector(store => store.Siteconfig)
    const [days,setDays] = useState(undefined)
    const [customerRecord,setCustomerRecords] = useState([])
    const [fetching,setFetching] = useState(false)

    async function fetchInvoicingOps(e) {
        if (e) { e.preventDefault() }
        setCustomerRecords([])
        setSelectedCustomer(undefined)
        setFetching(true)
        try {
            const customers = (await(getInvoicingCustomers(days || hotelConfig.invoicing_period))).data
            setCustomerRecords(customers)
        } catch(e) {
            window.handleError(e)
        }
        setFetching(false)

    }

    function resetAllData() {
        setCustomerRecords([])
        setSelectedCustomer(undefined)
        fetchInvoicingOps()
    }

    useEffect(() => {
        setDays(hotelConfig.invoicing_period)
    }, [hotelConfig])

    //clear customers & selected customer on date change
    useEffect(() => {
        setCustomerRecords([])
        setSelectedCustomer(undefined)
    },[days])
    const [selectedCustomer,setSelectedCustomer] = useState(undefined)

    return (
        <>
            <Row>
                <h1>{t('Invoicing')}</h1>
                <Col md={3} >
                    <Form className="" onSubmit={fetchInvoicingOps}>
                        <InputGroup>
                            <InputGroup.Text>{t('Longer than')}</InputGroup.Text>
                            <Form.Control type="number" step="0" className="text-center" value={days} onChange={e => {setDays(e.target.value)}}/>
                            <InputGroup.Text>{t('days overdue')}</InputGroup.Text>
                        </InputGroup>
                        <Button type="submit" className="w-100 mt-1" disabled={fetching}>
                            <FontAwesomeIcon icon={fa.faSearch}/> {t('Fetch data')} 
                        </Button>
                    </Form>     
                </Col>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <label><b>{t('Invoicing quantity')}</b></label><br></br>
                                    {t(hotelConfig.invoicing_quantity)}
                                </Col>
                                <Col>
                                    <label><b>{t('Invoicing mode')}</b></label><br></br>
                                    {t(hotelConfig.invoicing_mode)}
                                </Col>
                                <Col>
                                    <label><b>{t('Article ID')}</b></label><br></br>
                                    {hotelConfig.invoicing_article_id}
                                </Col>
                                <Col>
                                    <label><b>{t('Document type')}</b></label><br></br>
                                    {t(hotelConfig.invoicing_document_type)}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <h1 className="mt-3">{t('Customers')}</h1>
            {
                fetching ? <FetchingPlaceholder/> :
                <Row>
                    <Col>
                        {selectedCustomer ? 
                            <SelectedCustomerTable customer={selectedCustomer} onClearCustomer={() => setSelectedCustomer(undefined)} setDays={days} onComplete={() => resetAllData()}/>
                            :
                            <CustomersTable customers={customerRecord}  onCustomerSelect={(customer) => setSelectedCustomer(customer)}/> }
                    </Col>
                </Row>
            }

        </>
    )
}
export default withNamespaces()(index)