import React from 'react'
import { withNamespaces } from 'react-i18next'
import { Card, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import { TyreIcon } from '../../../resources/images/customIcons'

function customersTable({t,customers,onCustomerSelect}) {
    
    //global array of vehicles
    const vehicles = customers.flatMap(customer => customer.vehicles)

    return (
        customers.length > 0 ? <>
            {vehicles.length > 0 ? 
                customers.map((customer,i) => {
                    if (customer.vehicles.length > 0) {
                        return <Card className="mt-2 pointer" key={i} onClick={() => onCustomerSelect(customer)}>
                            <Card.Body>
                                <Row>
                                    <Col md={6}>
                                        <b>{customer.companyname}</b>
                                    </Col>
                                    <Col>
                                        <FontAwesomeIcon icon={fa.faCar}/> {customer.vehicles.length} {t('vehicles')}
                                    </Col>
                                    <Col>
                                        <FontAwesomeIcon icon={TyreIcon}/> {customer.vehicles.flatMap(vehicle => vehicle.tyres[0]).length} {t('tyres')}
                                    </Col>
                                    <Col className="text-end">
                                        <FontAwesomeIcon className="text-muted" icon={fa.faChevronRight}/>&nbsp;
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    }
                }
            ) : <Card className="mt-2"><Card.Body className="text-muted text-center"><span>{t('No overdue records fetched.')}</span></Card.Body></Card>}
        </> : 
        <Card className="mt-2"><Card.Body className="text-muted text-center"><span>{t('No overdue records fetched.')}</span></Card.Body></Card>
    )
}

export default withNamespaces()(customersTable)