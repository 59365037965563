import React, {useEffect, useState} from 'react'
import { Dropdown, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { updateVehicle } from '../../actions/vehicles'
import { faWarehouse, faBan, faTrash, faPrint, faPencilAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { withNamespaces } from 'react-i18next';
import SetNameModal from './setNameModal'
import PrintLabelModal from './printLabelModal'
import { newOperation } from '../../actions/operations'
import { useDispatch } from 'react-redux'

function TyreActionButton({selectedTyres, className, unselectRows, vehicle, t}) {
    
    const [showSetModal, setShowSetModal] = useState(false)

    function showSetNameModal() {
        setShowSetModal(true)
    }

    function setModalClosed(keepSelected) {
        if (!keepSelected) {
            unselectRows()
        }
        setShowSetModal(false)
    }

    const [showLabelModal, setShowLabelModal] = useState(false)

    const dispatch = useDispatch()

    //operation = {flt_id : flt_id, tyres : tyres, operationType : operationType
    async function handleOperation(operationType) {
        //single vehicle operation
        if (vehicle) {
            await dispatch(newOperation({
                vehicle : vehicle,
                flt_id : vehicle.flt_id[0],
                tyres : selectedTyres,
                operationType : operationType
            }))
            unselectRows()
        } else {
            //multi vehicle operations
        }
    }

    async function deleteTyres() {
        const copiedVehicle = {...vehicle}
        const selectedTyreIds = selectedTyres.map(tyre => tyre.db_id[0])
        copiedVehicle.tyres[0].tyre?.forEach(tyre => {
            if (selectedTyreIds.includes(tyre.db_id[0])) {
                tyre.operation = 'delete'
            }
        })
        dispatch(updateVehicle(copiedVehicle))
        unselectRows()
    }

    const [toStockDisabled,setToStockDisabled] = useState(false)
    const [toVehicleDisabled, setToVehicleDisabled] = useState(false)
    const [toWasteDisabled, setToWasteDisabled] = useState(false)
    const [deleteDisabled,setDeleteDisabled] = useState(false)

    useEffect(() => {
        const tyreStatuses = selectedTyres.map(t => t.tyre_status[0])

        //reset disabled statuses
        setToStockDisabled(false)
        setToVehicleDisabled(false)
        setToWasteDisabled(false)
        setDeleteDisabled(false)

        //set disabled statuses based on tyre operation
        if (tyreStatuses.includes('in_stock')) {
            setToStockDisabled(true)
            setDeleteDisabled(true)
        }
        if (tyreStatuses.includes('waste')) {
            setToStockDisabled(true)
            setToVehicleDisabled(true)
            setToWasteDisabled(true)
            setDeleteDisabled(true)
        }

        if (tyreStatuses.includes('at_customer')) {
            setToVehicleDisabled(true)
            setToWasteDisabled(true)
        }

        if (tyreStatuses.includes('on_vehicle')) {
            setToVehicleDisabled(true)
            setToWasteDisabled(true)
        }

    },[selectedTyres])


    return (
        selectedTyres.length > 0 ? <>
        <SetNameModal show={showSetModal} vehicle={vehicle} closeHook={setModalClosed} tyres={selectedTyres}/>
        <PrintLabelModal show={showLabelModal} vehicle={vehicle} closeHook={() => setShowLabelModal(false)} tyres={selectedTyres}/>
        <Dropdown className={className}>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                {t('Tyre actions')} <Badge bg="light" className="text-primary">{selectedTyres.length}</Badge>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item disabled={toStockDisabled} onClick={() => handleOperation('to_stock')}><FontAwesomeIcon icon={faWarehouse}/> {t('To stock')}</Dropdown.Item>
                <Dropdown.Item disabled={toVehicleDisabled} onClick={() => handleOperation('to_customer')}><FontAwesomeIcon icon={faUser}/> {t('To customer')}</Dropdown.Item>
                <Dropdown.Item disabled={toWasteDisabled} onClick={() => handleOperation('to_waste')}><FontAwesomeIcon icon={faTrash}/> {t('To waste')}</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item  onClick={() => showSetNameModal()}><FontAwesomeIcon icon={faPencilAlt}/> {t('Set name')}</Dropdown.Item> 
                <Dropdown.Item disabled={deleteDisabled} onClick={() => deleteTyres()}><FontAwesomeIcon icon={faBan}/> {t('Delete tyres')}</Dropdown.Item>
                <Dropdown.Item onClick={() => setShowLabelModal(true)}><FontAwesomeIcon icon={faPrint}/> {t('Print labels')}</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        </> : ''
    )
}

export default withNamespaces()(TyreActionButton)
