import React from 'react'
import { Badge } from 'react-bootstrap'
import { withNamespaces } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faUser, faWarehouse, faBox, faQuestion} from '@fortawesome/free-solid-svg-icons';

const operationLabelGenerator = function(type) {
    if (type === 'to_customer') {
        return { color : 'success', label :'To customer', icon : faUser }
    } else if (type === 'to_stock') {
        return { color : 'warning', label : 'To stock', icon : faWarehouse }
    } else if (type === 'to_spare') {
        return { color : 'info', label : 'To Spare', icon: faBox  }
    } else if (type === 'to_waste') {
        return { color : 'danger', label : 'To waste', icon : faTrash }
    } else {
        return { color : 'secondary', label : 'Unknown', icon: faQuestion }
    }
}

window.operationLabelGenerator = operationLabelGenerator

function OperationtypeLabel({t,type,className}) {
    return (
        <Badge data-tag="allowRowEvents" className={className} bg={operationLabelGenerator(type).color}><FontAwesomeIcon icon={operationLabelGenerator(type).icon}/> {t(operationLabelGenerator(type).label)}</Badge>
    )
}

export default withNamespaces()(OperationtypeLabel)
