import React from 'react'
import { Tabs, Tab }  from 'react-bootstrap'
import { withNamespaces } from 'react-i18next';
import CustomerForm from './customerForm'
import FleetTable from './fleetTable'
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons'
import VehicleIcon from '../../common/vehicleIcon';

function Fleetmanagement({t}) {

  const adminFleetStore = useSelector(store => store.AdminFleet)
  const dispatch = useDispatch()
  function closeTab(e,vehicle) {
    e.stopPropagation();
    dispatch({ type: 'ADMIN_SELECTED_REMOVE', payload: vehicle})
    dispatch({ type: 'ADMIN_SET_ACTIVE_VEHICLE', payload: 'customer'})
  }

  function changeActiveVehicle(key) {
    console.log('setting key')
    dispatch({ type: 'ADMIN_SET_ACTIVE_VEHICLE', payload: key})
  }

  return (
    <>
      <Tabs className="mb-0" defaultActiveKey="customer" onSelect={changeActiveVehicle} activeKey={adminFleetStore.activeVehicle} id="operation-tabs">
        <Tab className="pt-2 bg-white p-2 border border-top-0"  eventKey="customer" title={<><FontAwesomeIcon icon={fa.faUser}/> {t('Customer')} </>}>
          <div className="p-3">
            <h1>{t('Fleet management')}</h1>
            <CustomerForm/>
            <FleetTable/>
          </div>
        </Tab>        
        {adminFleetStore.selectedVehicles.map(vehicle => 
          <Tab key={vehicle.flt_id[0]} className="pt-2 bg-white p-2 border border-top-0" eventKey={vehicle.flt_id[0]} 
            title={
            <>
              <VehicleIcon type={vehicle.type[0]}/> 
              <span> {vehicle.license_plate[0]} </span>
              <FontAwesomeIcon className="text-danger" onClick={(e) => closeTab(e,vehicle)} icon={fa.faTimes}/>
            </>
          }>
            <div>
              <h1>{t('Vehicle')} {vehicle.license_plate[0]}</h1>
            </div>
          </Tab>   
        )}
      </Tabs>
    </>
  )
}

export default withNamespaces()(Fleetmanagement)