import * as api from '../api/operations'
import i18next from 'i18next';
export const newOperation = (operation,hideAlert) => async(dispatch) => {
    const toast = hideAlert ? undefined : window.loadingShow(i18next.t('Creating operation...'));
    try {
        //operation = {flt_id : flt_id, tyres : tyres, operationType : operationType
        const response = await api.insertOperation(operation)
        const insertedOperation = response?.data
        if (insertedOperation) {
            dispatch({type: 'OPERATIONS_ADD', payload : insertedOperation })
            window.loadingResolve(toast,'success',i18next.t('Operation inserted.'))
        }
        return insertedOperation
    } catch (e) {
        window.loadingResolve(toast,'error',e)
    }
}

export const updateOperation = (operation,hideAlert) => async(dispatch) => {
    //based on operation status --> populate alerts accordingly
    const keywords = operation.status === 'submitted' ? ['Submitting','submitted'] : ['Updating','updated']

    const toast = hideAlert ? undefined : window.loadingShow(i18next.t(`${keywords[0]} operation`))
    try {
        const updatedOperation = (await api.updateOperation(operation)).data
        await dispatch({type: 'OPERATIONS_UPDATE', payload : updatedOperation })
        if (toast) {
            window.loadingResolve(toast,'success',i18next.t(`Operation ${keywords[1]}`)) 
        }
    } catch (e) {
        if (toast) {
            window.loadingResolve(toast,'error',e)
        } else {
            window.handleError(e)
        }
    }
}

export const loadOperations = () => async(dispatch) => {
    try {
        //operation = {flt_id : flt_id, tyres : tyres, operationType : operationType
        const operations = (await api.getOperations()).data
        dispatch({type: 'OPERATIONS_LOAD', payload : operations })
    } catch (e) {
        window.handleError(e)
    }
}

export const deleteOperation = (operation,hideAlert) => async(dispatch) => {
    const confirmation = hideAlert ? true : await window.confirmation(i18next.t('Delete operation?'))
    if (confirmation) {
        const toast = !hideAlert ? window.loadingShow(i18next.t('Deleting operation')) : undefined
        try {
            await api.deleteOperation(operation)
            await dispatch({type: 'OPERATIONS_REMOVE', payload : operation })
            if (!hideAlert) {
                window.loadingResolve(toast,'success',i18next.t('Operation deleted'))
            }
            
        } catch (e) {
            if (!hideAlert) {
                window.loadingResolve(toast,'error',e)
            }
        }
    }
}