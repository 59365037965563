import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMotorcycle, faQuestion, faTruckMoving, faTruck, faCarSide, faCaravan, faTrailer, faTruckPickup, faBus, faTractor } from '@fortawesome/free-solid-svg-icons'

function vehicleIcon(props) {

    let icon = undefined

    const type = props.type?.toUpperCase() 

    if (['PERSONENWAGEN', 'VOITURE', 'CAR'].includes(type)) {
        icon = faCarSide
    } else if (['BESTELWAGEN', 'CAMIONETTE', 'VAN'].includes(type)) {
        icon = faTruck
    } else if (['VRACHTWAGEN', 'CAMION', 'TRUCK'].includes(type)) {
        icon = faTruckMoving
    } else if (['SUV', '4X4', 'OFFROAD'].includes(type)) {
        icon = faTruckPickup
    } else if (['AANHANGWAGEN', 'REMORQUE'].includes(type)) {
        icon = faTrailer
    } else if (['CARAVAN', 'CARAVANE'].includes(type)) {
        icon = faCaravan
    } else if (['BUS'].includes(type)) {
        icon = faBus
    } else if (['TRACTOR','TREKKER','TRACTEUR'].includes(type)) {
        icon = faTractor
    } else if (['MOTO','SCOOTER','MOTEUR'].includes(type)) {
        icon = faMotorcycle
    } else {
        icon = faQuestion
        console.log('Missing type: ' + type)
    }

    return (
        <FontAwesomeIcon className={props.className} icon={icon}/>
    )
}

export default vehicleIcon
