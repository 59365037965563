import React from 'react'
import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  adminOpsByDateLight } from '../../../api/hoteladmin'
import * as fa from '@fortawesome/free-solid-svg-icons'
import { Form, Button, Row, Col, Table, Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import OperationCountPieChart from './OperationCountPieChart'
import moment from 'moment'
import { useSelector } from 'react-redux'

function HomepageAdmin({t}) {

    const navigate = useNavigate()
    const hotelConfig = useSelector(store => store.Siteconfig)

    const [operations,setOperations] = useState([])

    const [startDate,setStartDate] = useState(moment().subtract(1,'years'))
    const [endDate,setEndDate] = useState(moment())
    
    useEffect(async () => {
      fetchOps()
    },[])

    async function fetchOps() {
      try {
        setOperations( (await adminOpsByDateLight(startDate,endDate)).data )
      } catch (e) {
        window.handleError(e)
      }
    }

    //random colors to fill charts with
    const colorPalette = [      
      '#17e8c5',
      '#e55c3d',
      '#e08a1a',
      '#2988e8',
      '#1bd170',
      '#b9e85c',
      '#f25ec3',
      '#d3ad39',
      '#05668c',
      '#ea8948',
      '#30d3b0',
      '#a027f7',
      '#87cc47',
      '#c1c92c',
      '#a6d142',
      '#d029f2',
      '#58a3d8',
      '#13d6a8',
      '#0a8238',
      '#09c8ea',
      '#cec514',
      '#b323db',
      '#f78062',
      '#c547ef',
      '#fcc071'
    ]

    const theadStyle = {height: '51px', verticalAlign : 'middle'}
    const tbodyStyle = {borderTop : '1px solid rgb(222, 226, 230)'}

    let operationCountsPerCustomer = []
    
    //based on operations populate the counts-per-customer object
    operations.forEach(op => {
      if (op.user?.companyname) {
        const customerObj = operationCountsPerCustomer.filter(countObj => countObj.customer === op.user.companyname)[0]
        if (customerObj) {
          customerObj.count++
          if (customerObj[op.type]) {
            customerObj[op.type]++
          } else {
            customerObj[op.type] = 1
          }
        } else {
          const newObj ={
            customer : op.user.companyname,
            count : 1,
          }
          newObj[op.type] = 1
          operationCountsPerCustomer.push(newObj)
        }
      }
    })

    return (
        <>
            <h1 className='text-center'>{t('Welcome, administrator')}</h1>
            <Row className="mt-3 justify-content-center">
              {hotelConfig.mode !== 'fleetonly' ? <>
                <Col sm={12} md={4}><Button onClick={() => navigate('/admin/operations')} className="w-100 my-1" variant="outline-primary" size="lg"><FontAwesomeIcon icon={fa.faCalendarAlt}/> {t('Operations')}</Button></Col>
                <Col sm={12} md={4}><Button onClick={() => navigate('/admin/intake')} className="w-100 my-1" variant="outline-primary" size="lg"><FontAwesomeIcon icon={fa.faBarcode}/> {t('Tyre Intake')}</Button></Col>
              </> : ''}
                <Col sm={12} md={4}><Button onClick={() => navigate('/admin')} className="w-100 my-1" variant="outline-primary" size="lg"><FontAwesomeIcon icon={fa.faCog}/> {t('Configuration')}</Button></Col>
            </Row>
            {hotelConfig.mode !== 'fleetonly' ? <>
              <h1 className='text-center mt-3'>{t('Statistics')}</h1>
              <Card>
                <Card.Body>
                  <Row className="mt-4">
                      <Col sm={12} md={4}>
                        <OperationCountPieChart colors={colorPalette} operationCountsPerCustomer={operationCountsPerCustomer}/>     
                      </Col>
                      <Col sm={12} md={8}>
                        <Row>
                          <Col><Form.Control type="date" value={moment(startDate).format('YYYY-MM-DD')} onChange={e => setStartDate(e.target.value)}/></Col>
                          <Col><Form.Control type="date" value={moment(endDate).format('YYYY-MM-DD')} onChange={e => setEndDate(e.target.value)}/></Col>
                          <Col>
                            <Button className="w-100" onClick={() => fetchOps()}><FontAwesomeIcon icon={fa.faSearch}/> {t('Change date')}</Button>
                          </Col>
                        </Row>
                        { operationCountsPerCustomer.length > 0 ? 
                          <Table responsive={true} className="bg-white table table-hover">
                          <thead style={theadStyle}>
                            <tr>
                              <th>{t('Customer')}</th>
                              <th>{t('Total')}</th>
                              <th>{t('Recall operations')}</th>
                              <th>{t('Storage operations')}</th>
                              <th>{t('Waste operations')}</th>
                            </tr>
                          </thead>
                          <tbody style={tbodyStyle}>
                            {operationCountsPerCustomer.map((countObj,i) => <tr key={i}>
                              <td><FontAwesomeIcon style={{color: colorPalette[i]}} icon={fa.faSquare}/> {countObj.customer}</td>
                              <td>{countObj.count || 0 }</td>
                              <td>{countObj.to_customer || 0 }</td>
                              <td>{countObj.to_stock || 0 }</td>
                              <td>{countObj.to_waste || 0 }</td>
                            </tr>)}
                          </tbody>
                        </Table> : <p className="text-center opacity-50 mt-3">{t('No operations to display')}</p>}
                      </Col>
                  </Row>
                </Card.Body>
              </Card>
            </> : '' }
        </>
      )
}

export default withNamespaces()(HomepageAdmin)
        