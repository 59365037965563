import React from 'react'
import { Outlet } from "react-router-dom";
import { Card } from 'react-bootstrap'
function index() {
    return (
        <Card>
            <Card.Body>
                <Outlet/>
            </Card.Body>
        </Card>
       
    )
}

export default index