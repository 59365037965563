import React, {useState} from 'react'
import { withNamespaces } from 'react-i18next'

function VehicleDrawing({configuration, onPositionSelect, onPositionDeselect, blockedPositions, activePositions, tyrePositions, t}) {

    //HIGHLIGHT TYRE ROWS
    const highlightTyreRow = function(tyreSquare) {
        tyreSquare.classList.add('bg-secondary')
        tyreSquare.nextSibling.classList.add('text-white')
        const position = tyreSquare.dataset.position
        const tags = document.getElementsByClassName(`tyre-row-${position}`)
        for (const tag of tags) {
            const rowStatus = tag.dataset.status
            const rowColor = window.tyreLabelGenerator(rowStatus).color
            const row = tag.parentElement.parentElement.parentElement
            row.classList.add('bg-' + rowColor)
            row.classList.add('text-white')
        }
    }

    const [selectedPositions,setSelectedPositions] = useState([])

    const unHighlightTyreRow = function(tyreSquare) {
        tyreSquare.classList.remove('bg-secondary')
        if (!tyreSquare.classList.contains('bg-primary')) {
            tyreSquare.nextSibling.classList.remove('text-white')
        }
        
        const position = tyreSquare.dataset.position
        const tags = document.getElementsByClassName(`tyre-row-${position}`)
        for (const tag of tags) {
            const rowStatus = tag.dataset.status
            const rowColor = window.tyreLabelGenerator(rowStatus).color
            const row = tag.parentElement.parentElement.parentElement
            row.classList.remove('bg-' + rowColor)
            row.classList.remove('text-white')
        }
    }
    const tyreClick = function(tyreSquare, position) {
        if (blockedPositions && blockedPositions.includes(position)) {
            return;
        }
        tyreSquare.classList.remove('bg-secondary')
        const copiedPositions = [...selectedPositions]
        if (tyreSquare.dataset.selected === 'true') {
            tyreSquare.dataset.selected = false 
            tyreSquare.classList.remove('bg-primary')
            tyreSquare.nextSibling.classList.remove('text-white')
            const indexOfPosition = copiedPositions.indexOf(position)
            if (indexOfPosition >= 0) {
                copiedPositions.splice(indexOfPosition,1)
            }
        } else {
            tyreSquare.dataset.selected = true 
            tyreSquare.classList.add('bg-primary')
            tyreSquare.nextSibling.classList.add('text-white')
            if (!copiedPositions.includes(position)) {
                copiedPositions.push(position)
            }
        }
        //if position is blocked -- return & do nothing

        
        if (copiedPositions.includes(position)) {
            if (onPositionSelect) { onPositionSelect(position) }
        } else {
            if (onPositionDeselect) { onPositionDeselect(position) }
        }
        setSelectedPositions(copiedPositions.sort())
        
    }


    function selectAllPositions(e) {
        const tyreSquares = e.target.parentElement.nextSibling.querySelectorAll('.config-tyre')
        for (let i = 0; i < tyreSquares.length; i++) {
            const tyreSquare = tyreSquares[i]
            const tyreLabel = tyreSquare.id.split('-')[1]
            if (!selectedPositions.includes(tyreLabel)) {
                setTimeout(() => window.tyreClick(tyreSquare,tyreLabel), i)
            }
        }
    }

    function unselectAllPositions(e) {
        const tyreSquares = e.target.parentElement.nextSibling.querySelectorAll('.config-tyre')
        for (let i = 0; i < tyreSquares.length; i++) {
            const tyreSquare = tyreSquares[i]
            const tyreLabel = tyreSquare.id.split('-')[1]
            if (selectedPositions.includes(tyreLabel)) {
                setTimeout(() => window.tyreClick(tyreSquare,tyreLabel), i)
            }
        }
    }

    window.highlightTyreRow = highlightTyreRow
    window.unHighlightTyreRow = unHighlightTyreRow
    window.tyreClick = tyreClick

    let tyrePositionsArray = []

    function generateSvg() {     
        try {
            if (configuration) {
                var axleHeight = Number(configuration.axle_height[0])
                var axleWidth = Number(configuration.axle_width[0])
                var chassisHeight = 150
                var wheelWidth = 25
                var axleDrawHeight = axleHeight / 2 + 5
                var separatorDrawHeight = axleHeight / 2 + 5
                var alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G']
                
                //start generating svg
                var svgcontent = ''

                //DRAW CHASSIS FIRST FOR LAYERING REASONS
                //double axle counter
                var axleCounter_Chassis = 0
                configuration.axles[0].axle.forEach(axle => {
                    if (axle.type[0] === 'separator') {
                        axleCounter_Chassis = 0
                        svgcontent += '<line fill="none" stroke="#000000" stroke-width="1" stroke-miterlimit="10" x1="' + axleWidth / 2 + '" y1="' + (separatorDrawHeight) + '" x2="' + axleWidth / 2 + '" y2="' + (chassisHeight + separatorDrawHeight) + '"/>'
                        separatorDrawHeight += chassisHeight
                    } else if (axle.type[0] === 'axle') {
                        axleCounter_Chassis++
                        //2 subsequent axles have been detected, draw small vertical line to connect 2 axles
                        if (axleCounter_Chassis > 1) {
                            svgcontent += '<line fill="none" stroke="#000000" stroke-width="1" stroke-miterlimit="10" x1="' + axleWidth / 2 + '" y1="' + (separatorDrawHeight) + '" x2="' + axleWidth / 2 + '" y2="' + (separatorDrawHeight + axleHeight + 10) + '"/>'
                            separatorDrawHeight += axleHeight + 10
                        }
                    }
                })
                var tyreCounter = 0
                var axleCounter_Axle = 0
                configuration.axles[0].axle.forEach(axle => {

                    if (axle.type[0] === 'axle') {
                        axleCounter_Axle++
                        //2 subsequent axles have been detected, add to axle width
                        if (axleCounter_Axle > 1) {
                            axleDrawHeight += axleHeight + 10
                        }

                        //lift axle
                        var strokeStyle = ''
                        if (axle.lift_axle[0] === 'T') {
                            strokeStyle = 'stroke-dasharray= "4 2" stroke-width="1"'
                        } else {
                            strokeStyle = 'stroke-width="1"'
                        }

                        //draw horizontal line
                        svgcontent += '<line fill="none" stroke="#000000" ' + strokeStyle + ' stroke-miterlimit="10" x1="0" y1="' + axleDrawHeight + '" x2="' + axleWidth + '" y2="' + axleDrawHeight + '"/>'

                        //draw "drive" circle
                        if (axle.drive_axle[0] === 'T') {
                            svgcontent += '<circle fill="#FFFFFF" stroke="#000000" ' + strokeStyle + ' stroke-miterlimit="10" cx="' + axleWidth / 2 + '" cy="' + axleDrawHeight + '" r="10"/>'
                        }

                        //steering axle
                        var steeringClass = ''
                        if (axle.steering_axle[0] === 'T') {
                            steeringClass = 'steering'
                        }

                        //draw wheels
                        tyreCounter++
                        var wheelLeftPosition = 0
                        var wheelRightPosition = axleWidth - wheelWidth
                        var wheelsLeft = Number(axle.wheels_left[0])
                        var wheelsRight = Number(axle.wheels_right[0])
                        
                        

                        //draw left wheel squares
                        for (var i = 0; i < wheelsLeft; i++) {
                            var textValue = tyreCounter + alphabet[i]

                            const selectBlocked = blockedPositions && blockedPositions.includes(textValue)
                            const selectActive = activePositions && activePositions.includes(textValue)
                            tyrePositionsArray.push(textValue)
                            svgcontent += 
                            `<rect 
                                data-position="${textValue}" 
                                id="tyre-${textValue}" 
                                data-selected="${selectActive ? 'true' : 'false'}"
                                ${onPositionSelect ? `onclick="window.tyreClick(this,'${textValue}')" style="cursor:pointer"` : 'onmouseenter="window.highlightTyreRow(this)" onmouseleave="window.unHighlightTyreRow(this)"' }
                                x="${wheelLeftPosition}" y="${(axleDrawHeight - axleHeight / 2)}" 
                                class="config-tyre ${steeringClass} ${onPositionSelect ? 'selectable-square' : ''} ${selectBlocked ? 'bg-secondary' : ''} ${selectActive ? 'bg-primary' : ''}" 
                                fill="#FFFFFF"  
                                ${strokeStyle} 
                                stroke="#000000" 
                                stroke-miterlimit="10" 
                                width="25" 
                                height="${axleHeight}"
                            />`
                            svgcontent += 
                            `<text 
                                class="${selectBlocked || selectActive ? 'text-white' : ''}"
                                transform="matrix(1 0 0 1 ${(wheelLeftPosition + 4)} ${((axleHeight / 2 + 6.5) + axleDrawHeight - axleHeight / 2)})" 
                                font-size="15">${textValue}
                            </text>`
                            wheelLeftPosition = wheelLeftPosition + wheelWidth + 5
                        }

                        //draw right wheel squares
                        tyreCounter++
                        for (let i = 0; i < wheelsRight; i++) {
                            let textValue = tyreCounter + alphabet[i]

                            const selectBlocked = blockedPositions && blockedPositions.includes(textValue)
                            const selectActive = activePositions && activePositions.includes(textValue)

                            tyrePositionsArray.push(textValue)
                            svgcontent += 
                            `<rect 
                                data-position="${textValue}" 
                                id="tyre-${textValue}" 
                                data-selected="${selectActive ? 'true' : 'false'}"
                                ${onPositionSelect ? `onclick="window.tyreClick(this,'${textValue}')" style="cursor:pointer"` : 'onmouseenter="window.highlightTyreRow(this)" onmouseleave="window.unHighlightTyreRow(this)"' }
                                x="${wheelRightPosition}" y="${(axleDrawHeight - axleHeight / 2)}" 
                                class="config-tyre ${steeringClass} ${selectBlocked ? 'bg-secondary' : ''}  ${selectActive ? 'bg-primary' : ''}" 
                                fill="#FFFFFF" 
                                ${strokeStyle} 
                                stroke="#000000" 
                                stroke-width="2" 
                                stroke-miterlimit="10" 
                                width="25" 
                                height="${axleHeight}"
                            />`
                            
                            svgcontent += 
                            `<text 
                                class="${selectBlocked || selectActive ? 'text-white' : ''}"
                                transform="matrix(1 0 0 1 ${(wheelRightPosition + 4)} ${((axleHeight / 2 + 6.5) + axleDrawHeight - axleHeight / 2)})" 
                                font-size="15">${textValue}
                            </text>`
                            
                            wheelRightPosition = wheelRightPosition - wheelWidth - 5
                        }

                    } else if (axle.type[0] === "separator") {
                        axleCounter_Axle = 0
                        axleDrawHeight += chassisHeight
                    }
                })

                if (tyrePositions) {
                    tyrePositions(tyrePositionsArray);
                }

                    var svg = `<?xml version="1.0" encoding="utf-8"?>
                    <!-- Generator: Xerxes Config to SVG Generator -->
                    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
                    <svg 
                    version="1.1" id="TyreStream_Vehicle_Configuration" 
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    width="100%" height="100%" 
                    viewBox="0 0 250 ${axleDrawHeight + axleHeight}" >
                        <style>
                            svg .steering { transform-box: fill-box; transform-origin: center; transform: rotate(-10deg); }
                            svg .bg-primary     { fill : #007bff }
                            svg .bg-warning     { fill : #ffc107 }
                            svg .bg-info        { fill : #0dcaf0 }
                            svg .bg-success     { fill : #198754 }
                            svg .bg-secondary   { fill : #6c757d }
                            svg .bg-light       { fill : #f8f9fa }
                            svg .bg-danger      { fill : #dc3545 }
                            svg .text-white     { fill : #ffffff }
                            svg text { pointer-events: none; }
                        </style>
                        <g transform="translate(${(250 - axleWidth)/2},10)">
                            ${svgcontent}
                        </g>
                    </svg>`



                return svg

            } else {
                return null
            }
        } catch (e) {
            console.error(e)
            return null
        }
    }

    return ( 
        <>
            { configuration ? <>
                {onPositionSelect ? <p>
                    <small size="sm" style={{cursor: "pointer"}} className="text-primary" onClick={(e) => selectAllPositions(e)}>{t('Select all')}</small>&nbsp;
                    <small size="sm" style={{cursor: "pointer"}} className="text-secondary" onClick={(e) => unselectAllPositions(e)}>{t('Clear selection')}</small> 
                </p> : ''}
                <div className="card" dangerouslySetInnerHTML={{__html: generateSvg()}}></div> 
            </>
            : ''}
        </>
    )
}

export default withNamespaces()(VehicleDrawing)
