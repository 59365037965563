
  const initialState =  {
    name: 'Loading...'
  };

export default (siteConfig = initialState , action) => {
    switch (action.type) {
        case 'SITECONFIG_SET':
          return action.payload
        case 'SITECONFIG_ADD_ELEMENTS':
          {
            const copiedConfig = {...siteConfig}
            Object.keys(action.payload).forEach(key => {
              copiedConfig[key] = action.payload[key]
            })
            return copiedConfig;
          }
        default:
            return siteConfig;
    }
}

