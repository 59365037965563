import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { faPlus} from '@fortawesome/free-solid-svg-icons'
import { withNamespaces } from 'react-i18next';
import NewVehicleForm from './NewVehicleForm';
function NewVehicleModal({fleetAdminMode,t}) {

    const [show,setShow] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
 
    return (
        <>
        <Button onClick={() => {handleShow()}} className="btn btn-success"><FontAwesomeIcon icon={faPlus}/> {t('New vehicle')}</Button>
        <Modal show={show} onHide={handleClose}>
            <NewVehicleForm fleetAdminMode={fleetAdminMode} modalMode closeModal={() => handleClose()}/>
        </Modal>
        </>
    )
}

export default withNamespaces()(NewVehicleModal)