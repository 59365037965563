import React, { useState } from 'react'
import { withNamespaces } from 'react-i18next'
import { Card, Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import DataTable from 'react-data-table-component'
import { injectInvoicingDocs, unmarkTyreAsInvoiced } from '../../../api/hoteladmin'
import SetInvoiceDateModal from './setInvoiceDateModal'

function SelectedCustomerTable({t,customer, onClearCustomer,configuredDays,onComplete}) {
    
    const tableStyles = {
        head : {
            style: {
                fontSize: '16px', // override the row height
            }
        },
        cells : {
            style: {
                fontSize: '16px', // override the row height
                cursor: 'pointer'
            }
        }
    }

    const [tyres,setTyres] = useState([])

    async function handleUnmark(tyreId) {
        try {
            await unmarkTyreAsInvoiced({tyreIds : [tyreId]})
            //update tyre state to remove invoice date
            const copiedTyres = [...tyres]
            const tyreToUpdate = copiedTyres.filter(tyre => tyre.db_id[0] === tyreId)[0]
            if (tyreToUpdate) { tyreToUpdate.invoicedAt = undefined}
            setTyres(copiedTyres)
            window.notify('success',t('Last invoice date removed'))
        } catch (e) {
            window.handleError(e)
        }
    }

    //mark fields that are calculated upon as bold
    const stockDateCondStyle = {
        when: row => {
            const invoiceDate = row.invoicedAt ? moment(row.invoicedAt) : undefined
            const stockDate = row.status_date ? moment(row.status_date[0],'YYYYMMDD') : undefined
            if (invoiceDate && stockDate) {
                if (stockDate.isAfter(invoiceDate)) {
                    console.log('true')
                    return true
                }
            } else {
                return true
            }
        },
        style: {
            fontWeight: 'bold',
        }
    }

    //mark fields that are calculated upon as bold
    const invoiceDateCondStyle = {
        when: row => {
            const invoiceDate = row.invoicedAt ? moment(row.invoicedAt) : undefined
            const stockDate = row.status_date ? moment(row.status_date[0],'YYYYMMDD') : undefined
            if (invoiceDate && stockDate) {
                if (stockDate.isBefore(invoiceDate)) {
                    return true
                }
            }
        },
        style: {
            fontWeight: 'bold',
        }
    }

    const tableColumns = [
        { name: t('Plate'), maxWidth: '150px', selector: row => row.license_plate[0] },
        { name: t('Tyre ID'), maxWidth: '150px', selector: row => row.db_id[0] },
        { name: t('Set'), maxWidth: '150px', selector: row => row.tyre_set ? row.tyre_set[0] : '' },
        { name: t('Description'), selector: row => row.tyre_memo ? row.tyre_memo[0] : row.description[0], },
        { name: t('Stock date'), maxWidth: '175px', selector: row => moment(row.status_date[0],'YYYYMMDD').format('DD/MM/YYYY'), conditionalCellStyles: [ stockDateCondStyle ]},            
        { 
            name: t('Last invoice run'), 
            maxWidth: '175px', 
            selector: row => {
                return row.invoicedAt ? 
                    <>
                        {moment(row.invoicedAt).format('DD/MM/YYYY')}&nbsp;
                        <span className="text-danger pointer" onClick={() => handleUnmark(row.db_id[0])}><FontAwesomeIcon size="sm" icon={fa.faTimes}/></span>
                    </> : ''
            }, 
            conditionalCellStyles: [ invoiceDateCondStyle ]},
        { name: t('Days overdue'), maxWidth: '175px', selector: row => {
                const invoiceDate = row.invoicedAt ? moment(row.invoicedAt) : undefined
                const stockDate = row.status_date ? moment(row.status_date[0],'YYYYMMDD') : undefined
                let dateToUse
                if (invoiceDate && stockDate) {
                    dateToUse = invoiceDate.isBefore(stockDate) ? stockDate : invoiceDate
                } else {
                    dateToUse = stockDate
                }
                
                const days = moment().subtract(configuredDays,'days').diff(dateToUse,'days')
                return t('{{days}} days',{days : days})
            }
        }
    ];

    const [confirmButtonDisabled,setConfirmButtonDisabled] = useState(false)

    async function handleDocInsert() {
        try {
            setConfirmButtonDisabled(true)
            const confirmation = await window.confirmation(t('Insert TyreStream document?'))
            if (confirmation) {
                await injectInvoicingDocs({tyres: selectedTyres, seq_id: customer.seq_id})
                window.notify('success',t('Document created successfully'))
                if (onComplete) {
                    onComplete()
                }
            }           
            
        } catch (e) {
            window.handleError(e)
        }
        setConfirmButtonDisabled(false) 
    } 

    const [selectedTyres,setSelectedTyres]= useState([])

    function onSelectedRowsChange({ selectedRows }) {
        setSelectedTyres(selectedRows);
      }

    function onRowClicked(row,e) {
        const checkbox = e.currentTarget?.children[0]?.firstChild
        checkbox.click()
    }

    function handleTyreUpdate(updatedTyres) {
        const copiedTyres = [...tyres];
        for (let i = 0; i < copiedTyres.length; i++) {
            const tyre = copiedTyres[i];
            const updatedRecord = updatedTyres.filter(uTyre => uTyre.db_id[0] === tyre.db_id[0])[0];
            if (updatedRecord) {
                if (moment(updatedRecord.invoicedAt).isAfter(moment().subtract(configuredDays,'days'))) {
                    console.log('deleting')
                    copiedTyres.splice(i, 1);
                    i--; // decrease i to account for the removed element
                } else {
                    tyre.invoicedAt = updatedRecord.invoicedAt;
                }
            }
        }
        setTyres(copiedTyres);

        //hack unselect by doubleclicking on select all items to unselect 
        const checkbox = document.querySelector('input[name="select-all-rows"]');
        checkbox.click()
        checkbox.click()
    }

    React.useEffect(() => {
        let tyres = []
    
        customer.vehicles.forEach(vehicle => {
            vehicle.tyres[0].forEach(tyre => {
                tyre["license_plate"] = vehicle.license_plate
                tyre["id"] = tyre.db_id[0]
                tyres.push(tyre)
            })
        })
    
        //sort tyres by db_id
        tyres.sort((a,b) => a.db_id[0] < b.db_id[0] ? -1 : 1 )

        setTyres(tyres)
    },[])

  return (
    <Card className="mt-2">
        <Card.Header>
            <FontAwesomeIcon className="pointer" onClick={() => onClearCustomer()} icon={fa.faChevronLeft}/> <FontAwesomeIcon icon={fa.faUser}/> {customer.companyname}
        </Card.Header>
        <Card.Body>
            <Row>
                <Col className="text-left"> 
                    <b className="mt-2 d-inline-block">{t('{{count}} tyres selected', {count : selectedTyres.length})}</b>
                </Col>
                <Col className="text-end">
                    <SetInvoiceDateModal tyres={selectedTyres} disabled={selectedTyres.length === 0} markUpdatedTyres={handleTyreUpdate}/>
                    &nbsp;
                    <Button onClick={() => handleDocInsert()} className="btn-success d-inline-block" disabled={confirmButtonDisabled || selectedTyres.length === 0}>
                        <FontAwesomeIcon icon={fa.faFile}/> {t('Create TyreStream Document')} 
                    </Button>
                </Col>
                <hr className="mt-3"/>
            </Row> 
            <DataTable
                responsive={true}
                customStyles={tableStyles}
                columns={tableColumns}
                data={tyres}
                selectableRows
                onRowClicked={onRowClicked}

                onSelectedRowsChange={onSelectedRowsChange}
                noDataComponent={<p className="pt-3 pb-2">{t('No overdue tyres to display.')}</p>}
                highlightOnHover={true}
                pagination={true}
                striped={true}
            />
        </Card.Body>
    </Card>
  )
}

export default withNamespaces()(SelectedCustomerTable)