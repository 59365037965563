import React, {useState, useEffect} from 'react'
import { Row, Col, Form, Table } from 'react-bootstrap'
import { getTranslations } from '../../api/superuser'
import TranslationRow from './translationRow'

function Translations() {

    const [translations, setTranslations] = useState([])
    const [filteredTranslations, setFilteredTranslations] = useState([])
    const [filterLang, setFilterLang] = useState(undefined)
    const [filterString, setFilterString] = useState(undefined)
    const [showUntranslated, setShowUntranslated] = useState(false)
    async function fetchTranslations() {
        try {
            const fetchedTranslations = (await getTranslations()).data
            setTranslations(fetchedTranslations) 
            setFilteredTranslations(fetchedTranslations)
        } catch (e) {
            window.handleError(e)
        }
    }

    useEffect(() => {
        fetchTranslations()
    }, [])

    const tableStyles = {
        textAlign: 'center'
    }

    useEffect(() => {
        let translationsToFilter = translations
        if (filterLang && filterLang !== 'undefined') {
            translationsToFilter = translationsToFilter.filter(record => record.language === filterLang)
        }

        if (filterString) {
            translationsToFilter = translationsToFilter.filter(record => record.key.includes(filterString) || record.value.includes(filterString))
        }

        if (showUntranslated) {
            translationsToFilter = translationsToFilter.filter(record => record.value.includes('[') && record.value.includes(']'))
        }

        setFilteredTranslations(translationsToFilter)
    }, [translations,filterLang,filterString,showUntranslated])

    function removeRow(id) {
        let copiedTranslations = [...translations]
        const indexToDelete = copiedTranslations.findIndex(translation => translation._id === id)
        copiedTranslations.splice(indexToDelete,1)
        setTranslations(copiedTranslations)
    }

    return (
        <div>                                 
            <h1>Manage dictionary</h1>
            <Row className="gy-2">
                <Col sm={12} md={3}>
                    <Form.Select  onChange={e => setFilterLang(e.target.value)} value={filterLang}>
                        <option value={''}>Language filter</option>
                        <option value="eng">English</option>
                        <option value="dut">Dutch</option>
                        <option value="fre">French</option>
                        <option value="ger">German</option>
                    </Form.Select>
                </Col>
                <Col sm={12} md={3}>
                    <Form.Select disabled>
                        <option value="eng">Namespace</option>
                    </Form.Select>
                </Col>
                <Col sm={12} md={3}>
                    <Form.Control onChange={e => setFilterString(e.target.value)} value={filterString} placeholder="Key or string"/>
                </Col>
                <Col sm={12} md={3} className="pt-lg-2 pt-sm-0 text-center">
                    <label htmlFor="show-untranslated"><input checked={showUntranslated} onChange={e => setShowUntranslated(e.target.checked)} id="show-untranslated" type="checkbox"/> Show untranslated </label>
                </Col>
            </Row>
            
            <Table style={tableStyles} className="mt-2" striped hover>
                <thead>
                    <tr>
                        <th>Language</th>
                        <th>Namespace</th>
                        <th>Key</th>
                        <th>Value</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {filteredTranslations?.length > 0 ? 
                        filteredTranslations.map(translation => <TranslationRow key={translation._id} onDelete={(id) => removeRow(id)} translation={translation}/>) : 
                    <tr><td colSpan="100%">No translations loaded.</td></tr>}
                </tbody>
            </Table>
        </div>
    )
}

export default Translations