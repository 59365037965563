const initialState =  {
    loaded : false,
    operations : []
}

export default (operationsState = initialState , action) => {
    switch (action.type) {
        case 'OPERATIONS_LOAD':
            return {
                ...operationsState,
                loaded : true,
                operations : action.payload
            };
        case 'OPERATIONS_ADD':
            return {
                ...operationsState,
                operations : [...operationsState.operations, action.payload]
            };
        case 'OPERATIONS_CLEAR':
            return initialState;
        case 'OPERATIONS_UPDATE':
            if (operationsState.operations.length > 0) {
                const copiedOperations = [...operationsState.operations]
                copiedOperations.forEach((operation,i) => {                    
                    if (operation._id === action.payload._id) {
                        copiedOperations[i] = action.payload
                    }
                });
                return {
                    ...operationsState,
                    operations : copiedOperations
                }
            }
            break;
        case 'OPERATIONS_REMOVE':
            if (operationsState.operations.length > 0) {
                const copiedOperations = [...operationsState.operations]
                copiedOperations.forEach((operation,i) => {                    
                    if (operation._id === action.payload._id) {
                        copiedOperations.splice(i, 1);
                    }
                });
                return {
                    ...operationsState,
                    operations : copiedOperations
                }
            }
            break;
        default:
            return operationsState;
    }
}