import React, { useEffect, useState } from 'react'
import { Col, Row, Card, Form, Button, Tabs, Tab, Badge } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { withNamespaces } from 'react-i18next';
import { loadVehicle } from '../../api/fleet'
import VehicleDrawing from '../common/vehicleDrawing'
import VehicleTyreTable from '../common/vehicleTyreTable';
import VehicleHistoryTable from '../common/vehicleHistoryTable';
import { useSelector, useDispatch } from 'react-redux';
import { updateVehicle, updateVehicleState, removeVehicle } from '../../actions/vehicles';
import ContentLoader from 'react-content-loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons'
import VehicleEditForm from './vehicleEditForm';
import './style.css'

function Vehicle({t}) {
    const location = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const flt_id = location.state.id
    const hotelConfig = useSelector((store) => store.Siteconfig)
    const fleetStore = useSelector((store) => store.Fleet)
    const vehicleConfigs = useSelector((store) => store.Siteconfig).vehicleConfigs
    const operationStore = useSelector((store) => store.Operations)
    const pendingOperations = operationStore?.operations?.filter(operation => operation.flt_id == flt_id && (operation.status === 'submitted' || operation.status === 'pending'))
    const tyresWithOperations = [].concat.apply([],pendingOperations.map(po => po.tyres))
    const [vehicle,setVehicle] = useState(undefined)

    //load vehicle based on store
    useEffect(() => {
        setVehicle(fleetStore.vehicles.filter(vehicle => vehicle.flt_id[0] == location.state.id)[0])
    }, [fleetStore.vehicles.filter(vehicle => vehicle.flt_id[0] == location.state.id)[0]]) 

    //this will fetch the most actual data from TyreStream (lazy load principle)
    async function loadPage() {
        const vehicle = (await loadVehicle(flt_id)).data
        vehicle["flt_id"] = [flt_id]
        setVehicle(vehicle)
        dispatch(updateVehicleState(vehicle))
    }

    function goToWizard() {
        navigate('/wizard/step2', {state : { flt_id : flt_id}})
    }

    const ConfigLoader = function() { return <ContentLoader speed={2} height="400" width="100%" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
        <rect x="0" y="0" rx="5" ry="5" width="300" height="40" /> 
        <rect x="0" y="60" rx="0" ry="0" width="300" height="900" />
        <rect x="320" y="0" rx="5" ry="5" width="100%" height="40" /> 
        <rect x="320" y="60" rx="5" ry="5" width="100%" height="10" /> 
        <rect x="320" y="90" rx="5" ry="5" width="100%" height="10" /> 
        <rect x="320" y="120" rx="5" ry="5" width="100%" height="10" /> 
        <rect x="320" y="150" rx="5" ry="5" width="100%" height="10" /> 
        <rect x="320" y="180" rx="5" ry="5" width="100%" height="10" /> 
        <rect x="320" y="210" rx="5" ry="5" width="100%" height="10" /> 
        <rect x="320" y="240" rx="5" ry="5" width="100%" height="10" /> 
    </ContentLoader>
    }

    useEffect(() => {
        loadPage()
    }, [])

    async function handleRemove() {
        setLoading(true)
        const inactivateSuccess = await dispatch(removeVehicle(flt_id))
        setLoading(false)
        if (inactivateSuccess) {
            navigate('/fleet')
        }
    }

    const [updatedConfig, setUpdatedConfig] = useState(undefined)

    function handleConfigEdit(configDescription) {
        const configObject = vehicleConfigs.filter(config => config.description == configDescription)[0]
        if (configObject) {
            setUpdatedConfig(configObject.configuration)
        } else {
            setUpdatedConfig(undefined)
        }
    }

    async function updateConfig() {
        try {
            const copiedVehicle = {...vehicle}
            copiedVehicle["configuration"] = updatedConfig
            setLoading(true)
            dispatch(updateVehicle(copiedVehicle))
            setLoading(false)
            setVehicle(copiedVehicle)
        } catch (e) {
            window.handleError(e)
        }
    }

    const [loading, setLoading] = useState(false)

    const [historyCount,setHistoryCount] = useState(undefined)
    let tyreCount = 0

    if (vehicle && vehicle.tyres && vehicle.tyres[0] && vehicle.tyres[0].tyre && vehicle.tyres[0].tyre.length > 0) {
        tyreCount = vehicle.tyres[0].tyre.filter(tyre => tyre.tyre_status[0] !== 'waste').length
    }
    return (
        flt_id ? <div>
                { vehicle ?
                <>
                        <Row>
                            <h3>
                                {t('Vehicle details')}
                                <span className="float-end">
                                    { ['hotelonly','both'].includes(hotelConfig.mode) ? <>
                                    <Button onClick={() => {goToWizard()}} variant="primary"><FontAwesomeIcon icon={fa.faPlus}/> {t('Operation')}</Button>&nbsp;
                                    </> : ''}                                    
                                    <Button disabled={loading} onClick={() => {handleRemove()}} variant="danger"><FontAwesomeIcon icon={fa.faTrash}/> {t('Remove')}</Button>
                                </span>
                            </h3>
                        </Row>
                        <VehicleEditForm col={6} loading={loading} setLoading={setLoading} vehicleData={vehicle}/>
                    { vehicle.configuration[0] || vehicle.tyres[0] ? 
                    <>
                    <Card className="mt-3">
                        <Card.Body>
                            
                            <Row>
                                <Col md="3">
                                    <h5>{t('Vehicle configuration')}</h5>
                                    <VehicleDrawing configuration={vehicle.configuration[0]}/>

                                </Col>
                                <Col md="9" className="table-responsive">
                                { hotelConfig.mode === 'fleetonly' ?
                                    <Tabs defaultActiveKey="tyres" id="uncontrolled-tab-example" className="mb-3 mt-2">
                                        <Tab eventKey="tyres" title={<>{t("Tyres")} <Badge bg="primary">{tyreCount}</Badge></>} className="pt-2">
                                            <VehicleTyreTable pendingTyres={tyresWithOperations} vehicle={vehicle}/>
                                        </Tab>
                                         <Tab eventKey="documents" title={<>{t("History")} <Badge bg="primary">{historyCount !== undefined ? historyCount : <FontAwesomeIcon className="spinner" icon={fa.faSpinner}/>}</Badge></>}>
                                            <Card className="border-top-0">
                                                <Card.Body>
                                                    <VehicleHistoryTable count={count => setHistoryCount(count)} flt_id={flt_id}/>
                                                </Card.Body>
                                            </Card>
                                        </Tab>
                                    </Tabs> : <VehicleTyreTable pendingTyres={tyresWithOperations} vehicle={vehicle}/> }
                                    
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    </>: hotelConfig.mode !== 'fleetonly' ?       
                        <Row>
                            <Col md={6}>
                                <Card className="mt-2">
                                    <Card.Body>
                                        <Row>
                                            <Col md={8}>   <b>{t('No vehicle configuration is set')}</b><br/>
                                                <Form.Select onChange={(e) => handleConfigEdit(e.target.value)}>
                                                    <option value="">{t('Select option...')}</option>
                                                    { vehicleConfigs?.map(config => 
                                                        <option key={config.description} value={config.description}>
                                                            {config.source === 'local' ? t(config.description) : config.description}
                                                        </option>
                                                    )}
                                                </Form.Select>
                                                <small>{t('Attention: setting a configuration is an irreversible operation')}</small>
                                                <br/><Button onClick={() => updateConfig()} disabled={loading} className=""><FontAwesomeIcon icon={fa.faCog} /> {t('Set configuration')}</Button><br/>
                                            </Col>
                                            <Col md={4} >
                                                <VehicleDrawing configuration={updatedConfig ? updatedConfig[0] : undefined}/>
                                            </Col>
                                        </Row>
                                     
                            
                                    </Card.Body>
                                </Card> 
                            </Col>
                        </Row> : ''
                    }
                   
                    
                </> : <ConfigLoader/> }
                
            </div>
        : <h5>{t('Error - no vehicle id retrieved from state')}</h5>
    )
}

export default withNamespaces()(Vehicle)
