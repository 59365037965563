import React, { useRef, forwardRef } from 'react'
import { useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Card, Button } from 'react-bootstrap'
import * as fa from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useReactToPrint } from 'react-to-print';
import OperationTypeLabel from '../../../common/operationTypeLabel';
import TyreTable from '../../../tyres/tyreTable';
import moment from 'moment';
import './printStyles.css';

function PrintOperationsButton({t,type, operations, className, printStockLocations, printRecallDate, printTransport, printServices}) {
  
  const userState = useSelector((store) => store.User)
  const hotelConfig = useSelector((store) => store.Siteconfig) 

  if (printTransport) {
    operations.forEach(operation => {
      const operationObject = hotelConfig.transportMethods?.filter(transportMethod => transportMethod._id === operation.transport)[0]
      operation.transport_string = operationObject?.[`description_${userState.lang_iso}`]
    })
  }

  if (printServices) {
    operations.forEach(operation => {
      operation.service_strings = []
      operation.services?.forEach(service => {
        const serviceObject = hotelConfig.services?.filter(hotelService => hotelService._id === service)[0]
        operation.service_strings.push(serviceObject?.[`description_${userState.lang_iso}`])
      })
    })
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    copyStyles : true ,
    content: () => componentRef.current,
  });

  const style = {
    headCells : {
      style: {
        height: 10,
    },
    },
    head : {
      style : {
        height: 20
      }
    },
    rows : {
        style: {
            fontSize: '11px', // override the row height
            minHeight: 25
        }
    },
}

let title 
if (type === '') {
  title = 'Picking list'
} if (type === 'storage') {
  title = 'Pickup list'
} else if (type === 'waste') {
  title = 'Waste list'
}

  const ComponentToPrint = forwardRef((props,ref) => {
    return (
      <div {...props} className="p-3" style={{ display: 'block', overflowY : 'hidden'}} ref={ref}>
        <h3>{t(title)} {moment().format('DD/MM/YYYY')}</h3>
        {operations.map(operation => 
          <Card key={operation._id} className="mt-2">
            <Card.Header style={{verticalAlign : 'middle'}}>
              <b>{operation.user.companyname}</b>
              <span style={{fontSize: '11px'}}>
              <span> <FontAwesomeIcon icon={fa.faCar}/> {operation.vehicle?.license_plate}</span>
              {printRecallDate ? <> <FontAwesomeIcon icon={fa.faCalendarAlt}/> {moment(operation.deliveryDate).format('DD/MM/YYYY')}</> : ''}
              {printTransport ? <> <FontAwesomeIcon icon={fa.faTruck}/> {operation.transport_string}</> : ''}
              {printServices ? <> <FontAwesomeIcon icon={fa.faWrench}/> {operation.service_strings.map((string,i) => <React.Fragment key={i}>{string}, </React.Fragment>)}</> : ''}
              </span>

              <OperationTypeLabel className="float-end d-block" type={operation.type}/></Card.Header>
              
            <Card.Body>
              <TyreTable showDepth={hotelConfig.allowProfileDepth} hideBorder hideStatus showStockRack={printStockLocations}hideStatusDate className="bg-light mt-2" customStyle={style} tyres={operation.tyres}/>
            </Card.Body>
          </Card>
        )}
      </div>
    );
  });

  ComponentToPrint.displayName = 'PrintComponent'

  return (
    <>
    <Button variant="outline-secondary" onClick={() => handlePrint()} className={className}><FontAwesomeIcon icon={fa.faPrint}/> {t('Print')}</Button>
    <div style={{display : "none"}}> {/*change to block for debugging*/}
      <ComponentToPrint ref={componentRef} />
    </div>
    </>
  )
}

export default withNamespaces()(PrintOperationsButton)