import React from 'react'
import { Line } from 'react-chartjs-2';
import moment from 'moment'
import { withNamespaces } from 'react-i18next'

import 'chart.js/auto';
import 'chartjs-adapter-moment';

function HomepageLineChart({t, finishedOperations}) {
    const chartOptions = {
        layout: {
            padding: 25
        },
        type : "line",
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          }
        },
        scales: {
            x: {
                
                
              },
            y: {
                ticks : {
                    beginAtZero : true
                },
                min: 0,
                suggestedMax: 10
            }
        }
      };
      


    /**
     * @param {date|moment} start The start date
     * @param {date|moment} end The end date
     * @param {string} type The range type. eg: 'days', 'hours' etc
    */
    function getRange(startDate, endDate, type) {
        let fromDate = moment(startDate)
        let toDate = moment(endDate)
        let diff = toDate.diff(fromDate, type)
        let range = []
        for (let i = 0; i < diff; i++) {
          range.push(moment(startDate).add(i, type))
        }
        return range
    }

    const firstDate = moment().subtract(31,'days')
    const lastDate = moment()
    const dateRange = getRange(firstDate,lastDate,'days')

    const labels = dateRange.map(momentObj => {
        return {
            moment : moment(momentObj), 
            string : moment(momentObj).format('DD/MM')
        }
    })

    let dataset = []

    /**
     * @param {date} timestamp date of handled operation
     * @param {string} type type of operation
     * pushes operation into correct dataset object
    */
    function pushDataset(timestamp,type) {
        const datasetRecord = dataset.filter(record => record.label === moment(timestamp).format('DD/MM') && record.type === type)[0]
        if (datasetRecord) {
            datasetRecord.count++
        } else {
            dataset.push({
                label: moment(timestamp).format('DD/MM'),
                moment: moment(timestamp),
                type: type,
                count : 1
            })
        }
    }

    finishedOperations.forEach(op => {
        pushDataset(op.handledAt,op.type)
    })

    //populate zeroes
    labels.forEach((label) => {
        ['to_stock','to_customer','to_waste'].forEach(op_type => {
            const datasetRecord = dataset.filter(record => record.label === label.string && record.type === op_type)[0] 
            if (!datasetRecord) { 
                dataset.push({label : label.string, moment: label.moment, type: op_type, count : 0})
            }
        })
    })

    dataset = dataset.sort((a,b) => {
        
        if (moment(a.moment).isBefore(moment(b.moment))) {
            return -1
        } else {
            return 1
        }
    })

    const graphLabels = labels.map(label => label.string)
    const chartData = {
        graphLabels,
        datasets: [
            {
                label: ' ' + t('Storages'),
                data: dataset.filter(record => 
                    record.type === 'to_stock'
                ).map(record => { 
                    return {x : record.label,y: record.count}
                }),
                borderColor: 'rgb(255, 193, 7)',
                backgroundColor : 'rgb(255, 193, 7)'
            },
            {
                label: ' ' + t('Recalls'),
                data: dataset.filter(record => 
                    record.type === 'to_customer'
                ).map(record => { 
                    return {x : record.label,y: record.count}
                }),
                borderColor: '#28a745',
                backgroundColor: '#28a745'
            },
            {
                label: ' ' + t('Waste'),
                data: dataset.filter(record => 
                    record.type === 'to_waste'
                ).map(record => { 
                    return {x : record.label,y: record.count}
                }),
                borderColor: '#dc3545',
                backgroundColor : '#dc3545'
            },
        ],
    };

  return (
    <>
        <h5>{t('Finished operations in the month')}</h5>
        <Line height="142" options={chartOptions} data={chartData} />
    </>
  )
}

export default withNamespaces()(HomepageLineChart)