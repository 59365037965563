import React, { useState, useEffect } from 'react'
import { Tabs, Tab, Badge, Row }  from 'react-bootstrap'
import { withNamespaces } from 'react-i18next';
import {  getAdminOperations } from '../../../api/hoteladmin'
import ContentLoader from 'react-content-loader';
import "react-datepicker/dist/react-datepicker.css";
import "./tabs/custom-tabs.css";
import SubmittedOperationsView from './tabs/submittedOperations'
import CompletedOperationsView from './tabs/completedOperations'

function OperationsPage({t}) {

    const [operationsLoaded, setOperationsLoaded] = useState(false)
    const loaderCell = (width) => {  

      return <ContentLoader
          className={`mt-1 col-sm-${width}`} 
          speed={2}
          height={25}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb">
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
      </ContentLoader>
  }

  const RowLoader = function() {
  return <Row className="mt-1">
      {loaderCell(3)}
      {loaderCell(1)}
      {loaderCell(1)}
      {loaderCell(2)}
      {loaderCell(2)}
      {loaderCell(2)}
      {loaderCell(1)}
  </Row>

  }

  const OperationPlaceholder = function() {
      let rowPlaceHolders = []
      for (let i = 0; i < 8; i++) {
          rowPlaceHolders.push(RowLoader(i))
      }

      return <>
         {rowPlaceHolders.map((x,i) => <div key={i}>{x}</div>)}
      </>
  }
    
    useEffect(async () => {
      try {
        //fetch only submitted ops for faster load time
        console.log('Getting ops')
        const submittedOps = (await getAdminOperations('submitted')).data
        setSubmittedOps(submittedOps)
        setOperationsLoaded(true)
      } catch (e) {
        window.handleError(e)
      }

    },[])

    const [submittedOps, setSubmittedOps]= useState([])
    
    const theadStyle = {height: '51px', verticalAlign : 'middle'}
    const tbodyStyle = {borderTop : '1px solid rgb(222, 226, 230)'}

    async function operationUpdated(id) {
      try {
        let copiedOps = [...submittedOps]
        const idArray = copiedOps.map(op => op._id)
        const indexToDelete = idArray.indexOf(id)
        
        copiedOps.splice(indexToDelete,1)
        setSubmittedOps(copiedOps)
      } catch (e) {
        window.handleError(e)
      }

    }

    const [completedOpsCount,setCompletedOpsCount] = useState(0)

    return (
       operationsLoaded ? 
       <Tabs className="mb-0" defaultActiveKey="submitted" id="operation-tabs">
        <Tab className="pt-2 bg-white p-2 border" eventKey="submitted" title={<>{t('Submitted operations')} <Badge>{submittedOps.length}</Badge></>}>
            { submittedOps.length > 0 ? <>
              <SubmittedOperationsView submittedOps={submittedOps} operationUpdated={operationUpdated} theadStyle={theadStyle} tbodyStyle={tbodyStyle}/>
            </> :
            <p className="text-center opacity-50 mt-2">{t('No submitted operations')}</p> }
        </Tab>        
        <Tab className="pt-2 bg-white p-2" eventKey="completed" title={<>{t('Completed')} {completedOpsCount ? <Badge>{completedOpsCount}</Badge> : ''}</>}>
          <CompletedOperationsView theadStyle={theadStyle} operationCount={setCompletedOpsCount} tbodyStyle={tbodyStyle}/>
        </Tab>
      </Tabs>

       : OperationPlaceholder()
    );
}

export default withNamespaces()(OperationsPage)