import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Row, Col, Card, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import VehicleDrawing from '../common/vehicleDrawing';
import VehicleTyreTable from '../common/vehicleTyreTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons'
import AddTyreModal from '../tyres/addTyreModal';
import VehicleIcon from '../common/vehicleIcon'
import { newOperation } from '../../actions/operations'
import { loadFleetAndTyres } from '../../actions/vehicles'

function PickService({t}) {

    const dispatch = useDispatch()
    useEffect(() => {
      if (!fleetState || !fleetState.loaded) {
        dispatch(loadFleetAndTyres())
      }
    }, [])

    const location = useLocation();
    const navigate = useNavigate()
    const flt_id = location?.state?.flt_id
    const fleetState = useSelector((store) => store.Fleet)

    const vehicle = fleetState.loaded ? fleetState.vehicles.filter(vehicle => vehicle.flt_id[0] === flt_id)[0] : undefined
    function goToVehicle(id) {
        navigate('/fleet/vehicle', {state: { id: id}})
    }
    //display only useful tyres
    const tyresInStock = vehicle?.tyres[0].tyre?.filter(tyre => tyre.tyre_status[0] == 'in_stock')
    const tyresAtCustomer = vehicle?.tyres[0].tyre?.filter(tyre => tyre.tyre_status[0] == 'at_customer')
    const [pickedService, setPickedService] = useState(undefined)

    const [pickedTyres, setPickedTyres] = useState([])

    async function confirmNewOperation() {
        const insertedOperation = await dispatch(newOperation({
            vehicle : vehicle,
            flt_id : vehicle.flt_id[0],
            tyres : pickedTyres,
            operationType : pickedService
        }, true ))
        navigate('/wizard/step3', {state: { operationId : insertedOperation._id }})
    }

        //function to select tyres after addition
        function handleTyreSelect(tyresToSelect) {
        
            //unselect all checkboxes
            const checkedCheckboxes = document.querySelectorAll('.rdt_TableCell input[type=checkbox]:checked')
            checkedCheckboxes.forEach(checkbox => {
                    checkbox.click()
            })
            setPickedTyres(tyresToSelect) 
    
            setTimeout(() => {
                tyresToSelect.forEach(tyre => {
                    document.getElementsByName(`select-row-${tyre.db_id[0]}`)[0].click();                           
                }) 
                
            }, 250)
         
        }

  return (<>
        <Row className="justify-content-center">
            <h5 className="text-center">{t('Step 2')} <Button size="sm" className="mb-1 float-right" variant="outline-primary" onClick={() => { navigate(-1) }}><FontAwesomeIcon icon={fa.faChevronLeft}/> {t('Go back')}</Button></h5>
            <Col className="text-center" lg={6} md={8} sm={12}>
            
                <small className="mb-1 d-block">{t('Pick a service')}</small>
                <Row className="g-2">
                    <Col md={4} sm={12}>
                        <Button 
                            disabled={vehicle ? false : true}
                            onClick={() => setPickedService('to_stock')} 
                            className={`w-100 ${pickedService !== undefined && pickedService !== 'to_stock' ? 'opacity-50' : ''}`} variant={pickedService !== undefined && pickedService !== 'to_stock' ? 'secondary' : 'warning'}>
                                <FontAwesomeIcon icon={fa.faWarehouse}/> {t('Tyres to stock')}
                        </Button>
                    </Col>
                    <Col md={4} sm={12}>
                        <Button 
                            disabled={vehicle ? false : true}
                            onClick={() => setPickedService('to_customer')} 
                            className={tyresInStock?.length > 0 ? `w-100 ${pickedService !== undefined && pickedService !== 'to_customer' ? 'opacity-50' : ''}` : 'w-100 disabled'} 
                            variant={pickedService !== undefined && pickedService !== 'to_customer' ? 'secondary' : 'success'}>
                            <FontAwesomeIcon icon={fa.faUser}/> {t('Tyres to customer')}
                        </Button>
                    </Col>
                    <Col md={4} sm={12}>
                        <Button 
                            disabled={vehicle ? false : true}
                            onClick={() => setPickedService('to_waste')} 
                            className={tyresInStock?.length > 0 ? `w-100 ${pickedService !== undefined && pickedService !== 'to_waste' ? 'opacity-50' : ''}` : 'w-100 disabled'} 
                            variant={pickedService !== undefined && pickedService !== 'to_waste' ? 'secondary' : 'danger'}>
                            <FontAwesomeIcon icon={fa.faTrash}/> {t('Tyres to waste')}
                        </Button>
                    </Col>
                </Row>
            </Col> 
        </Row>
        <Card className="mt-3">
            { vehicle ? <Card.Body as={Row}>
                <Col md={12}><h6>{t('Picked vehicle')}</h6></Col>
                <Col md={3} sm={12}>
                    <small className="opacity-50">{t('License plate')}</small><br/>
                    <span className="text-primary pointer" onClick={() => goToVehicle(vehicle.flt_id[0])}><VehicleIcon type={vehicle.type[0]}/> {vehicle.license_plate[0]}</span>
                </Col>
                <Col md={3} sm={12}>
                    <small className="opacity-50">{t('Brand')}</small><br/>
                    {vehicle.brand[0]}
                </Col>
                <Col md={3} sm={12}>
                    <small className="opacity-50">{t('Description')}</small><br/>
                    {vehicle.description[0]}
                </Col>
                <Col md={3} sm={12}>
                    <small className="opacity-50">{t('Driver')}</small><br/>
                    {vehicle.driver_name ? vehicle.driver_name[0] : ''}
                </Col>
            </Card.Body> : 
            <Card.Body as={Row}><p className="text-center opacity-50 mb-0">{t('Loading vehicle...')}</p></Card.Body> }
        </Card>

        {pickedService ? vehicle.configuration[0] ? <Row className="mt-3">
            <Col md={12} className=""><b>{t('Pick tyres to proceed')}</b></Col>
            <Col lg={3} md={4} sm={12}className="mt-2">
                <VehicleDrawing configuration={vehicle.configuration[0]}/>
                
                <AddTyreModal selectTyres={(tyres) => handleTyreSelect(tyres)} className="btn-success d-inline-block mt-2" vehicle={vehicle}/>&nbsp;
                <Button disabled={!pickedTyres?.length > 0} onClick={() => confirmNewOperation()} className="mt-2 ml-2">{t('Proceed')} <FontAwesomeIcon icon={fa.faChevronRight}/></Button>
            </Col>
            <Col lg={9} md={8} sm={12} className="text-left">

                { pickedService === 'to_stock' ? 
                    tyresAtCustomer?.length > 0 ? 
                        <VehicleTyreTable wizardMode={true} status="at_customer" vehicle={vehicle} tyreSelection={(tyres) => {setPickedTyres(tyres)}} tyres={tyresAtCustomer}/> 
                    : <p className="text-center opacity-50"><i>{t('No tyres registered')}</i></p>
                : ''}

                { ['to_waste','to_customer'].includes(pickedService) ?  
                    <VehicleTyreTable wizardMode={true} status="in_stock" vehicle={vehicle} tyreSelection={(tyres) => {setPickedTyres(tyres)}} tyres={tyresInStock}/>
                : '' }
                
            </Col>
            <Col md={9}>
                
            </Col>
        </Row> : <p className="text-center mt-3 mb-2 text-danger"><b>{t('Vehicle configuration is missing, please contact support')}.</b></p> : '' }

    </>
  )
}

export default withNamespaces()(PickService)